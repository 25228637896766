import React from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { getSurveyRecWithFilter } from "../api";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import ExportIcon from "../../assets/images/export.png";

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

class SurveyRecords extends React.Component {
  constructor(props) {
    super(props);
    this.dataTable = React.createRef();
    this.state = {
      surveryRecord: [],
      fromDate: "",
      toDate: "",
      fromVal: "",
      toVal: "",
    };
  }

  fetchData = () => {
    const { fromDate, toDate } = this.state;
    let data = {
      fromDate: fromDate,
      toDate: toDate,
      projectId: '',
      regionId: ''
    };
    getSurveyRecWithFilter(data, headers).then((r) => {
      let data = r?.data?.data;
      this.setState({ surveryRecord: data });
    });
  };

  componentDidMount = async () => {
    this.fetchData();
  };

  dateTimeTemplate = (record, type) => {
    let { collected_date, created_date } = record;
    let cDate = created_date?.split("T")[0];
    let date = collected_date?.split("T")[0];
    return <p>{type === "created_date" ? cDate : date}</p>;
  };

  handleTextChange = (e, type) => {
    if (type === "fromDate") {
      let day = new Date(e.target.value).getDate();
      let month = new Date(e.target.value).getMonth() + 1;
      let year = new Date(e.target.value).getFullYear();
      let finalDate = month + "-" + day + "-" + year;
      this.setState({ fromDate: finalDate, fromVal: e.target.value });
    } else if (type === "toDate") {
      let day = new Date(e.target.value).getDate();
      let month = new Date(e.target.value).getMonth() + 1;
      let year = new Date(e.target.value).getFullYear();
      let finalDate = month + "-" + day + "-" + year;
      this.setState({ toDate: finalDate, toVal: e.target.value });
    }
  };

  filterRecord = () => {
    this.fetchData();
  };

  clearRecord = () => {
    this.setState({ fromVal: "", toVal: "", fromDate: "", toDate: "" });
  };

  exportCSV = () => {
    this.dataTable.current.exportCSV();
  };

  render() {
    return (
      <div className="survey-record">
        <h3>Survey Records</h3>
        <div className="filters">
          <div className="fromDate">
            <p>From Date</p>
            <Calendar
              value={this.state.fromVal}
              onChange={(e) => this.handleTextChange(e, "fromDate")}
              maxDate={new Date(this.state.toVal) || null}
              showIcon
            />
          </div>
          <div className="toDate">
            <p>To Date</p>
            <Calendar
              value={this.state.toVal}
              minDate={new Date(this.state.fromVal) || null}
              onChange={(e) => this.handleTextChange(e, "toDate")}
              showIcon
            />
          </div>
          <div className="button">
            <Button
              icon="pi pi-check"
              onClick={() => {
                this.filterRecord();
              }}
              autoFocus
              raised
            >
              Submit
            </Button>
            <Button
              icon="pi pi-check"
              onClick={() => {
                this.clearRecord();
              }}
              autoFocus
              raised
            >
              Clear
            </Button>
          </div>
        </div>
        <Button onClick={this.exportCSV} className="exportBtn">
          <img src={ExportIcon} className="downloadIcon" />
        </Button>
        <div className="card">
          <DataTable
            value={this.state.surveryRecord}
            className="mt-4 project-treetable"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            exportFilename="survey data"
            ref={this.dataTable}
          >
            <Column
              sortable
              field="project_code"
              header="Project"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="in_area_code"
              header="Area"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="tentative_user_id"
              header="User"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="question"
              header="Question"
              style={{ width: "20%" }}
            ></Column>
            <Column
              sortable
              field="rating"
              header="Rating"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="collected_time"
              header="Collection time"
              style={{ width: "15%" }}
            ></Column>
            <Column
              sortable
              header="Collection date"
              body={(rec) => this.dateTimeTemplate(rec, "date")}
              style={{ width: "15%" }}
            ></Column>
            <Column
              sortable
              header="Created date"
              body={(rec) => this.dateTimeTemplate(rec, "created_date")}
              style={{ width: "15%" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyRecords);
