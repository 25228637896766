import React from "react";
import { connect } from "react-redux";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import { Delete, Edit, Info, Close } from "@mui/icons-material";
import { deletePT, getPT, saveEditPT } from "../api";
import { apiError } from "../../redux/actions/loginActions";
import Table from "../../components/Common/Table";
import "./masters.scss";

class ProjectType extends React.Component {
  constructor(props) {
    document.title = "Project Type";
    super(props);
    this.state = {
      showAddModal: false,
      rowData: [],
      columnData: [],
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      projType: "",
      editedProjectType: {},
      showAlert: false,
      alertMsg: "",
    };
  }

  componentDidMount() {
    const columns = [
      { field: "id", headerName: "UID", width: 90, hide: true },
      {
        field: "projecttype",
        headerName: "PROJECT TYPE",
        width: 700,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 150,

        renderCell: (params) => {
          const handleEditClick = (e) => {
            this.setState(
              { showEditModal: true, editedProjectType: params.row },
              () => this.setEditedFields()
            );
          };
          const handleDeleteClick = (e) => {
            this.setState({ showDeleteModal: true, removeId: params.row.id });
          };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={handleEditClick}
                  color="success"
                  className="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={handleDeleteClick}
                  className="delete"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];
    this.getRowData();
    this.setState({ columnData: columns });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 10000);
  }

  setEditedFields = () => {
    const { editedProjectType } = this.state;
    this.setState({
      projType: editedProjectType.projType,
    });
  };

  getRowData = () => {
    let rowData = [];
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    getPT({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        rowData = data?.length
          ? data.map((x) => {
              return {
                id: x.project_type_id,
                projecttype: x.project_type,
              };
            })
          : [];
        this.setState({ rowData });
      })
      .catch((e) => apiError(e));
  };

  addPT = () => {
    this.setState({
      showAddModal: true,
    });
  };

  handleTextChange = (e) => {
    this.setState({ projType: e.target.value });
  };

  handleClose = (type) => {
    if (type === "delete") this.setState({ showDeleteModal: false });
    else if (type === "edit")
      this.setState({
        showEditModal: false,
        projType: "",
        editedProjectType: {},
      });
    else if (type === "add")
      this.setState({
        showAddModal: false,
        projType: "",
        editedProjectType: {},
      });
  };
  handleSubmit = (type) => {
    const { projType, editedProjectType } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    let payload, apiType;
    if (type === "add") {
      apiType = "save";
      payload = {
        project_type: projType,
      };
    } else if (type === "edit") {
      apiType = "edit";
      payload = {
        project_type_id: editedProjectType.id,
        project_type: projType,
        status: "active",
      };
    }
    saveEditPT(apiType, payload, headers)
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose(type);
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "success",
        });
      })
      .catch((e) => apiError(e));
  };

  deletePT = () => {
    const { removeId } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    deletePT(
      {
        project_type_id: removeId,
      },
      headers
    )
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose("delete");
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "error",
        });
      })
      .catch((e) => apiError(e));
  };

  render() {
    const {
      rowData,
      columnData,
      projType,
      showAddModal,
      showDeleteModal,
      showEditModal,
      editedProjectType,
      alertMsg,
      showAlert,
      severity,
    } = this.state;
    return (
      <div className="projects projType">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
          >
            {alertMsg}
          </Alert>
        ) : null}
        <div className="topSection">
          <h3>Project Type</h3>
          <div className="rightSide">
            <Button variant="contained" className="addBtn" onClick={this.addPT}>
              ADD
            </Button>
          </div>
        </div>
        <Table columns={columnData} rows={rowData} />

        {/* Add Modal */}
        {showAddModal ? (
          <Modal
            open={showAddModal}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-master"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project Type
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Type Project Type here..."
                  value={projType}
                  onChange={(e) => this.handleTextChange(e)}
                />
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("add")}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Edit Modal */}
        {showEditModal ? (
          <Modal
            open={showEditModal}
            onClose={() => this.handleClose("edit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-master"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("edit")}
              />
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project Type
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Type Project Type here..."
                  value={projType}
                  onChange={(e) => this.handleTextChange(e)}
                />
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("edit")}
                >
                  UPDATE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Delete Modal */}
        {showDeleteModal ? (
          <Modal
            open={showDeleteModal}
            onClose={() => this.handleClose("delete")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="block-modal-master"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("delete")}
              />
              <Info className="infoIcon" />
              <h4>Are you Sure</h4>
              <p>Wants to Delete ?</p>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  className=""
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className=""
                  onClick={this.deletePT}
                >
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(ProjectType);
