import { createProject, editProject, getLocation, getManager, getPC, getPT, getProjects, getRegion, getSupervisor } from "../../pages/api";
import { CREATE_PROJECT, EDIT_PROJECT, GET_LOCATION, GET_MANAGER, GET_PROJECTS, GET_PROJECT_CODE, GET_PROJECT_TYPE, GET_REGION, GET_SUPERVISOR, CREATE_PROJECT_ALERT } from "../actionTypes/masterData";

const auth = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";

const setResponseError = (response, errorMsg) => {
    response.data = {
        data: {
            error: true,
            errorMsg
        }
    }
};

const loadProjectCode = () => async (dispatch) => {
    let response = {};
    try {
        response = await getPC(null, { auth });
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: GET_PROJECT_CODE, payload: response.data.data });
};

const loadSupervisor = () => async (dispatch) => {
    console.log('Supervisor called')
    let response = {};
    try {
        response = await getSupervisor(null, { auth });
        console.log('Supervisor response ????', response)
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: GET_SUPERVISOR, payload: response.data.data });
};

const loadRegion = () => async (dispatch) => {
    let response = {};
    try {
        response = await getRegion(null, { auth });
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: GET_REGION, payload: response.data.data });
};

const loadLocation = () => async (dispatch) => {
    let response = {};
    try {
        response = await getLocation(null, { auth });
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: GET_LOCATION, payload: response.data.data });
};

const loadProjectType = () => async (dispatch) => {
    let response = {};
    try {
        response = await getPT(null, { auth });
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: GET_PROJECT_TYPE, payload: response.data.data });
};

const loadManager = () => async (dispatch) => {
    let response = {};
    try {
        response = await getManager(null, { auth });
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: GET_MANAGER, payload: response.data.data });
};

const getAllProjects = (data) => async (dispatch) => {
    let response = {};
    try {
        response = await getProjects(null, { auth });
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: GET_PROJECTS, payload: response.data.data });
};

const createNewProject = (data) => async (dispatch) => {
    let response = {};
    try {
        response = await createProject(data, { auth });
        if(response?.data?.error){
            dispatch({
                type: CREATE_PROJECT_ALERT, payload: response.data
            })
        }
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: CREATE_PROJECT, payload: response.data.data });
};

const editNewProject = (data) => async (dispatch) => {
    let response = {};
    try {
        response = await editProject(data, { auth });
        if(response?.data?.error){
            dispatch({
                type: CREATE_PROJECT_ALERT, payload: response.data
            })
        }
    } catch (err) {
        setResponseError(response, err.message);
    }
    dispatch({ type: EDIT_PROJECT, payload: response.data.data });
};

export {
    loadProjectCode,
    loadRegion,
    loadLocation,
    loadProjectType,
    loadSupervisor,
    loadManager,
    getAllProjects,
    createNewProject,
    editNewProject
}
