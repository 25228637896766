import React from "react";
import { connect } from "react-redux";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Modal,
  Alert,
} from "@mui/material";
import { Edit, Close } from "@mui/icons-material";
import { getSQ, getSQArea, getSQProjects, saveEditSQ } from "../api";
import Table from "../../components/Common/Table";
import "./survey.scss";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { QRCode } from "react-qr-svg";

class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVal: 10,
      searchVal: "",
      showAddModal: false,
      rowData: [],
      columnData: [],
      surveyDetails: {},
      editedSurveyDetails: {},
      projCodeDropdowns: [],
      areaDropdowns: [],
      showAlert: false,
      showModalAlert: false,
      alertMsg: "",
      modalAlertMsg: "",
      severity: "",
      setQrvisible: false,
      sqData: [],
      setQrvisibleData: false,
      QRData: {},
    };
  }

  styles = {
    root: {
      fontFamily: "sans-serif",
    },
    h1: {
      textAlign: "center",
    },
    qrcode: {
      textAlign: "center",
    },
  };

  componentDidMount() {
    document.title = 'Survey'
    const columns = [
      { field: "id", headerName: "UID", width: 90, hide: true },
      // {
      //   field: "projectId",
      //   headerName: "PROJECT ID",
      //   width: 150,
      // },
      {
        field: "projectCode",
        headerName: "PROJECT CODE",
        width: 150,
        renderCell: (params) => {
          console.log("AGASDF", params)
          const link = `/projects/projectDetails/${params?.row?.projectId}`;
          return (
            <Link className="projCodeLink" to={link} target="_blank">
              {params.row.projectCode}
            </Link>
          );
        },
      },
      {
        field: "projectName",
        headerName: "PROJECT NAME",
        width: 150,
      },
      // {
      //   field: "areaId",
      //   headerName: "AREA ID",
      //   width: 150,
      // },
      {
        field: "areaCode",
        headerName: "AREA CODE",
        width: 150,
      },
      {
        field: "question",
        headerName: "QUESTIONS",
        width: 300,
      },
      {
        field: "question_ar",
        headerName: "ARABIC QUESTIONS",
        width: 200,
      },
      {
        field: "enabled",
        headerName: "ENABLED",
        width: 100,
      },
      {
        field: "from",
        headerName: "FROM",
        width: 150,
      },
      {
        field: "to",
        headerName: "TO",
        width: 150,
      },
      {
        field: "fromDate",
        headerName: "FROM DATE",
        width: 150,
      },
      {
        field: "toDate",
        headerName: "TO DATE",
        width: 150,
      },
      {
        field: "livestatus",
        headerName: "LIVE",
        width: 150,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 200,
        renderCell: (params) => {
          const handleEditClick = (e) => {
            this.setState(
              {
                showEditModal: true,
                editedSurveyDetails: params.row,
              },
              () => this.setEditedFields()
            );
          };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={handleEditClick}
                  color="success"
                  className="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];
    this.getRowData();
    this.getProjCodeData();
    this.setState({ columnData: columns });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ showAlert: false, showModalAlert: false });
    }, 10000);
  }

  getProjCodeData = () => {
    let projCodeDropdowns = [];
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    getSQProjects({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        projCodeDropdowns = data?.length
          ? data.map((x) => {
              return {
                project_id: x.project_id,
                project_code: x.project_code,
                project_name: x.project_name
              };
            })
          : [];
        this.setState({ projCodeDropdowns });
      })
      .catch((e) => console.log(e));
  };

  setEditedFields = () => {
    const { editedSurveyDetails } = this.state;
    // editedSurveyDetails.projectCode && editedSurveyDetails.projectId ? this.fetchAreaDetails() : null
    let data = {
      question: editedSurveyDetails.question,
      question_ar: editedSurveyDetails.question_ar,
      from: editedSurveyDetails.from,
      to: editedSurveyDetails.to,
      fromDate: editedSurveyDetails.fromDate,
      toDate: editedSurveyDetails.toDate,
      projectId: editedSurveyDetails.projectId
        ? editedSurveyDetails.projectId
        : "1",
      projectCode: editedSurveyDetails.projectCode
        ? editedSurveyDetails.projectCode
        : "",
      projectName: editedSurveyDetails.projectName
        ? editedSurveyDetails.projectName
        : "",
      areaId: editedSurveyDetails.areaId ? editedSurveyDetails.areaId : "1",
      areaCode: editedSurveyDetails.areaCode
        ? editedSurveyDetails.areaCode
        : "",
      enabled: editedSurveyDetails.enabled,
    };
    this.setState({
      surveyDetails: data,
    });
  };

  //   getDate(x) {
  //     let a = new Date(x);
  //     a = `${a.getDate()}-${a.getMonth() + 1}-${a.getFullYear()}`;
  //     return a;
  //   }

  //   getReverseDate(x) {
  //     let a = new Date(x);
  //     a = `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
  //     return a;
  //   }
  formatData = (data) => {
    let finalData = data.reduce((acc, current) => {
      let find = acc.findIndex((re) => {
        return re.in_area_code === current.in_area_code;
      });

      if (find < 0) {
        acc.push({
          in_area_code: current.in_area_code,
          project_code: current.project_code,
          project_name: current.project_name,
          project_id: current.project_id,
          in_area_id: current.in_area_id,
        });
      }

      return acc;
    }, []);

    return finalData;
  };

  getRowData = () => {
    let rowData = [];
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    getSQ({}, headers)
      .then(async (res) => {
        const data = res?.data?.data;
        let sqData = [];

        if (data && data.length) {
          sqData = await this.formatData(data);
        }

        rowData = data?.length
          ? data.map((x) => {
              return {
                id: x.survey_question_id,
                question: x.question,
                question_ar: x.question_ar,
                from: x.start_time,
                to: x.end_time,
                fromDate: x.start_date?.split("T")[0], //this.getDate(x.start_date),
                toDate: x.end_date?.split("T")[0], //this.getDate(x.end_date)
                enabled: x.enabled_status,
                projectId: x.project_id,
                projectCode: x.project_code,
                projectName: x.project_name,
                areaId: x.in_area_id,
                areaCode: x.in_area_code,
                livestatus: x.livestatus,
              };
            })
          : [];
        this.setState({ rowData, sqData });
      })
      .catch((e) => console.log(e));
  };

  handleDropdownChange = (e, type) => {
    let data = { ...this.state.surveyDetails };
    if (type === "projectCode") {
      data["projectId"] = e.target.value;
      this.state.projCodeDropdowns.map((x) => {
        if (x.project_id === e.target.value) {
          data["projectCode"] = x.project_code;
          data["projectName"] = x.project_name;
        }
      });
      this.setState({ surveyDetails: data }, () => this.fetchAreaDetails());
    } else if (type === "areaCode") {
      data["areaId"] = e.target.value;
      this.state.areaDropdowns.map((x) => {
        if (x.area_id === e.target.value) {
          data["areaCode"] = x.area_code;
        }
      });
      this.setState({ surveyDetails: data });
    } else if (type === "enabled") {
      data["enabled"] = e.target.value;
      this.setState({ surveyDetails: data });
    } else if (type === "showBy")
      this.setState({
        showVal: e.target.value,
      });
  };

  fetchAreaDetails = () => {
    const { surveyDetails } = this.state;
    let areaDropdowns = [];
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    const projId = surveyDetails && surveyDetails.projectId;
    getSQArea(projId, headers)
      .then((res) => {
        const data = res?.data?.data;
        const error = res?.data?.error;
        if (!error) {
          areaDropdowns = data?.length
            ? data.map((x) => {
                return {
                  area_id: x.in_area_id,
                  area_code: x.in_area_code,
                };
              })
            : [];
          this.setState({ areaDropdowns });
        } else if (error) {
          this.setState({
            showModalAlert: true,
            modalAlertMsg: res?.data?.message,
            severity: "warning",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  filterSearchData = (searchVal) => {
    if (searchVal) {
      const searchRegex = new RegExp(`.*${searchVal}.*`, "ig");
      const filteredRows = this.state.rowData.filter((o) => {
        return Object.keys(o).some((k) => {
          return searchRegex.test(o[k]?.toString());
        });
      });
      this.setState({
        filteredRows,
      });
    } else {
      this.setState({
        filteredRows: this.state.rowData,
      });
    }
  };

  handleSearch = (e) => {
    this.setState(
      {
        searchVal: e.target.value,
      },
      () => this.filterSearchData(this.state.searchVal)
    );
  };

  addProject = () => {
    this.setState({
      showAddModal: true,
    });
  };

  handleClose = (type) => {
    if (type === "edit")
      this.setState({
        showEditModal: false,
        surveyDetails: {},
        editedSurveyDetails: {},
      });
    else if (type === "add")
      this.setState({
        showAddModal: false,
        surveyDetails: {},
        editedSurveyDetails: {},
      });
  };

  handleTextChange = (e, type) => {
    let data = { ...this.state.surveyDetails };
    if (type === "question") data["question"] = e.target.value;
    else if (type === "from") data["from"] = e.target.value;
    else if (type === "to") data["to"] = e.target.value;
    else if (type === "fromDate") data["fromDate"] = e.target.value;
    else if (type === "toDate") data["toDate"] = e.target.value;
    else if (type === "question_ar") data["question_ar"] = e.target.value;
    this.setState({ surveyDetails: data });
  };

  handleSubmit = (type) => {
    const { surveyDetails, editedSurveyDetails, areaDropdowns } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    let payload, apiType;
    if (type === "add") {
      apiType = "create";
      payload = {
        question: surveyDetails["question"],
        question_ar: surveyDetails["question_ar"],
        project_id: surveyDetails["projectId"],
        project_code: surveyDetails["projectCode"],
        project_name: surveyDetails["projectName"],
        in_area_code: surveyDetails["areaCode"],
        in_area_id: surveyDetails["areaId"],
        start_date: surveyDetails["fromDate"],
        start_time: surveyDetails["from"],
        end_date: surveyDetails["toDate"],
        end_time: surveyDetails["to"],
      };
    } else if (type === "edit") {
      if (surveyDetails.areaId && !surveyDetails.areaCode) {
        let data = { ...surveyDetails };
        areaDropdowns.map((x) => {
          if (x.area_id === surveyDetails.areaId) {
            data["areaCode"] = x.area_code;
          }
        });
        this.setState({ surveyDetails: data });
      }
      apiType = "edit";
      payload = {
        survey_question_id: editedSurveyDetails.id,
        question: surveyDetails["question"],
        question_ar: surveyDetails["question_ar"],
        project_id: surveyDetails["projectId"],
        project_code: surveyDetails["projectCode"],
        project_name: surveyDetails["projectName"],
        in_area_code: surveyDetails["areaCode"],
        in_area_id: surveyDetails["areaId"],
        start_date: surveyDetails["fromDate"],
        start_time: surveyDetails["from"],
        end_date: surveyDetails["toDate"],
        end_time: surveyDetails["to"],
        enabled_status: surveyDetails["enabled"],
      };
    }
    saveEditSQ(apiType, payload, headers)
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose(type);
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "success",
        });
      })
      .catch((e) => console.log(e));
  };

  setQrvisible = (value) => {
    this.setState({
      setQrvisible: value,
    });
  };

  setQrvisibleData = (value, ele) => {
    let QRCode = ele ? ele : {};
    this.setState({
      setQrvisibleData: value,
      QRData: QRCode || {},
    });
  };

  lockTemplate = (rowData) => {
    return (
      <Button
        label="Show"
        icon="pi pi-external-link"
        onClick={() => this.setQrvisibleData(true, rowData)}
      >
        QR
      </Button>
    );
  };

  footerContent = (
    <div>
      <Button
        icon="pi pi-check"
        onClick={() => {
          window.print();
        }}
        autoFocus
      >
        Print
      </Button>
    </div>
  );

  render() {
    const {
      showVal,
      searchVal,
      rowData,
      columnData,
      surveyDetails,
      projCodeDropdowns,
      areaDropdowns,
      showAddModal,
      showEditModal,
      alertMsg,
      showAlert,
      severity,
      modalAlertMsg,
      showModalAlert,
      filteredRows,
      QRData,
    } = this.state;
    return (
      <div className="survey">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
          >
            {alertMsg}
          </Alert>
        ) : null}
        <div className="sections">
          <h3>Survey Questions</h3>
          <div className="topSection">
            <div className="leftside">
              <div className="dropdowns">
                <InputLabel id="demo-simple-select-label">Show by</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={showVal}
                  onChange={(e) => this.handleDropdownChange(e, "showBy")}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </div>
              <div className="search">
                <InputLabel id="demo-simple-select-label">Search</InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Project Code"
                  value={searchVal}
                  onChange={this.handleSearch}
                />
              </div>
              <div className="rightSide">
                <Button
                  variant="contained"
                  className="addBtn"
                  onClick={this.addProject}
                >
                  ADD
                </Button>
                <Button
                  variant="contained"
                  className="qrBtn"
                  onClick={() => this.setQrvisible(true)}
                >
                  Table QR
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columnData}
          rows={searchVal ? filteredRows : rowData}
          pageSize={showVal}
        />

        {/* Add Modal */}
        {showAddModal ? (
          <Modal
            open={showAddModal}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-survey"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              {showModalAlert ? (
                <Alert
                  variant="outlined"
                  severity={severity}
                  onClose={() => this.setState({ showModalAlert: false })}
                >
                  {modalAlertMsg}
                </Alert>
              ) : null}
              <div className="proj-text-1">
                {/* <div className="projectCode">
                  <InputLabel id="demo-simple-select-label">
                    Project Code
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select here ....."
                    value={surveyDetails["projectId"]}
                    onChange={(e) =>
                      this.handleDropdownChange(e, "projectCode")
                    }
                  >
                    {projCodeDropdowns?.length
                      ? projCodeDropdowns.map((x) => (
                          <MenuItem value={x.project_id}>
                            {x.project_code}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </div> */}
                <div className="projectCode">
                  <InputLabel id="demo-simple-select-label">
                    Project Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select here ....."
                    value={surveyDetails["projectName"]}
                    onChange={(e) =>
                      this.handleDropdownChange(e, "projectCode")
                    }
                  >
                    {projCodeDropdowns?.length
                      ? projCodeDropdowns.map((x) => (
                          <MenuItem value={x.project_id}>
                            {x.project_name}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </div>
                <div className="projectName">
                  <InputLabel id="demo-simple-select-label">
                    Project Code
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    placeholder="Type here ....."
                    disabled={true}
                    value={surveyDetails["projectCode"]}
                  />
                </div>
                <div className="areaCode">
                  <InputLabel id="demo-simple-select-label">Area</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select here ....."
                    value={surveyDetails["areaId"]}
                    onChange={(e) => this.handleDropdownChange(e, "areaCode")}
                  >
                    {areaDropdowns?.length
                      ? areaDropdowns.map((x) => (
                          <MenuItem value={x.area_id}>{x.area_code}</MenuItem>
                        ))
                      : null}
                  </Select>
                </div>
                <div className="from">
                  <InputLabel id="demo-simple-select-label">From</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="time"
                    placeholder="Type here ....."
                    value={surveyDetails["from"]}
                    onChange={(e) => this.handleTextChange(e, "from")}
                  />
                </div>
                <div className="to">
                  <InputLabel id="demo-simple-select-label">To</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="time"
                    placeholder="Type here ....."
                    value={surveyDetails["to"]}
                    onChange={(e) => this.handleTextChange(e, "to")}
                  />
                </div>
              </div>
              <div className="proj-text-2">
                <div className="fromDate">
                  <InputLabel id="demo-simple-select-label">
                    From Date
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="date"
                    placeholder="Type here ....."
                    value={surveyDetails["fromDate"]}
                    onChange={(e) => this.handleTextChange(e, "fromDate")}
                  />
                </div>
                <div className="toDate">
                  <InputLabel id="demo-simple-select-label">To Date</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="date"
                    placeholder="Type here ....."
                    inputProps={{ min: surveyDetails["fromDate"] }}
                    value={surveyDetails["toDate"]}
                    onChange={(e) => this.handleTextChange(e, "toDate")}
                  />
                </div>
                <div className="question">
                  <InputLabel id="demo-simple-select-label">
                    Question
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    multiline
                    placeholder="Type here ....."
                    value={surveyDetails["question"]}
                    onChange={(e) => this.handleTextChange(e, "question")}
                  />
                </div>
                <div className="description">
                  <InputLabel id="demo-simple-select-label">
                    Arabic Question
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    multiline
                    placeholder="Type here ....."
                    value={surveyDetails["question_ar"]}
                    onChange={(e) => this.handleTextChange(e, "question_ar")}
                  />
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("add")}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Edit Modal */}
        {showEditModal ? (
          <Modal
            open={showEditModal}
            onClose={() => this.handleClose("edit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-survey edit"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("edit")}
              />
              {showModalAlert ? (
                <Alert
                  variant="outlined"
                  severity={severity}
                  onClose={() => this.setState({ showModalAlert: false })}
                >
                  {modalAlertMsg}
                </Alert>
              ) : null}
              <div className="proj-text-1">
                <div className="projectCode">
                  <InputLabel id="demo-simple-select-label">
                    Project Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select here ....."
                    value={surveyDetails["projectId"]}
                    onChange={(e) =>
                      this.handleDropdownChange(e, "projectCode")
                    }
                  >
                    {projCodeDropdowns?.length
                      ? projCodeDropdowns.map((x) => (
                          <MenuItem value={x.project_id}>
                            {x.project_name}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </div>
                <div className="projectName">
                  <InputLabel id="demo-simple-select-label">
                    Project Code
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    placeholder="Type here ....."
                    disabled={true}
                    value={surveyDetails["projectCode"]}
                  />
                </div>
                <div className="areaCode">
                  <InputLabel id="demo-simple-select-label">Area</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select here ....."
                    value={surveyDetails["areaId"]}
                    onChange={(e) => this.handleDropdownChange(e, "areaCode")}
                  >
                    {areaDropdowns?.length
                      ? areaDropdowns.map((x) => (
                          <MenuItem value={x.area_id}>{x.area_code}</MenuItem>
                        ))
                      : null}
                  </Select>
                </div>
                <div className="from">
                  <InputLabel id="demo-simple-select-label">From</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="time"
                    placeholder="Type here ....."
                    value={surveyDetails["from"]}
                    onChange={(e) => this.handleTextChange(e, "from")}
                  />
                </div>
                <div className="to">
                  <InputLabel id="demo-simple-select-label">To</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="time"
                    placeholder="Type here ....."
                    value={surveyDetails["to"]}
                    onChange={(e) => this.handleTextChange(e, "to")}
                  />
                </div>
              </div>
              <div className="proj-text-2">
                <div className="enabled">
                  <InputLabel id="demo-simple-select-label">Enabled</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select here ....."
                    value={surveyDetails["enabled"]}
                    onChange={(e) => this.handleDropdownChange(e, "enabled")}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </div>

                <div className="fromDate">
                  <InputLabel id="demo-simple-select-label">
                    From Date
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="date"
                    placeholder="Type here ....."
                    value={surveyDetails["fromDate"]}
                    onChange={(e) => this.handleTextChange(e, "fromDate")}
                  />
                </div>
                <div className="toDate">
                  <InputLabel id="demo-simple-select-label">To Date</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="date"
                    placeholder="Type here ....."
                    inputProps={{ min: surveyDetails["fromDate"] }}
                    value={surveyDetails["toDate"]}
                    onChange={(e) => this.handleTextChange(e, "toDate")}
                  />
                </div>

                <div className="question">
                  <InputLabel id="demo-simple-select-label">
                    Question
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    multiline
                    value={surveyDetails["question"]}
                    onChange={(e) => this.handleTextChange(e, "question")}
                  />
                </div>
                <div className="description">
                  <InputLabel id="demo-simple-select-label">
                    Arabic Question
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    multiline
                    value={surveyDetails["question_ar"]}
                    onChange={(e) => this.handleTextChange(e, "question_ar")}
                  />
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("edit")}
                >
                  UPDATE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        <Dialog
          header="Survey QR"
          visible={this.state.setQrvisible}
          style={{ width: "50vw" }}
          onHide={() => this.setQrvisible(false)}
        >
          <DataTable
            value={this.state.sqData}
            scrollable
            scrollHeight="400px"
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column field="project_code" header="Project"></Column>
            <Column field="project_name" header="Project Name"></Column>
            <Column field="in_area_code" header="Area"></Column>
            <Column
              body={(ele) => this.lockTemplate(ele)}
              header="Action"
              headerClassName="w-10rem"
            />
          </DataTable>
        </Dialog>

        <Dialog
          id="printable-section"
          header={`Survey QR - ${this.state.QRData.project_code} - ${this.state.QRData.in_area_code}`}
          visible={this.state.setQrvisibleData}
          style={{ width: "50vw" }}
          onHide={() => this.setQrvisibleData(false)}
          footer={this.footerContent}
        >
          <div style={this.styles.root} >
            <div style={this.styles.qrcode}>
              <QRCode
                level="Q"
                style={{ width: 256 }}
                value={`https://agsisraf.com/survey_questions_list/${QRData.project_id}/${QRData.in_area_id}`}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Survey);
