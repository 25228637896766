module.exports = {
    getTreeNodesData() {
        return [
            {
                key: '0',
                label: 'Documents',
                data: 'Documents Folder',
                icon: 'pi pi-fw pi-inbox',
                children: [
                    {
                        key: '0-0',
                        label: 'Work',
                        data: 'Work Folder',
                        icon: 'pi pi-fw pi-cog',
                        children: [
                            { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
                            { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
                        ]
                    },
                    {
                        key: '0-1',
                        label: 'Home',
                        data: 'Home Folder',
                        icon: 'pi pi-fw pi-home',
                        children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
                    }
                ]
            },
            {
                key: '1',
                label: 'Events',
                data: 'Events Folder',
                icon: 'pi pi-fw pi-calendar',
                children: [
                    { key: '1-0', label: 'Meeting', icon: 'pi pi-fw pi-calendar-plus', data: 'Meeting' },
                    { key: '1-1', label: 'Product Launch', icon: 'pi pi-fw pi-calendar-plus', data: 'Product Launch' },
                    { key: '1-2', label: 'Report Review', icon: 'pi pi-fw pi-calendar-plus', data: 'Report Review' }
                ]
            },
            {
                key: '2',
                label: 'Movies',
                data: 'Movies Folder',
                icon: 'pi pi-fw pi-star-fill',
                children: [
                    {
                        key: '2-0',
                        icon: 'pi pi-fw pi-star-fill',
                        label: 'Al Pacino',
                        data: 'Pacino Movies',
                        children: [
                            { key: '2-0-0', label: 'Scarface', icon: 'pi pi-fw pi-video', data: 'Scarface Movie' },
                            { key: '2-0-1', label: 'Serpico', icon: 'pi pi-fw pi-video', data: 'Serpico Movie' }
                        ]
                    },
                    {
                        key: '2-1',
                        label: 'Robert De Niro',
                        icon: 'pi pi-fw pi-star-fill',
                        data: 'De Niro Movies',
                        children: [
                            { key: '2-1-0', label: 'Goodfellas', icon: 'pi pi-fw pi-video', data: 'Goodfellas Movie' },
                            { key: '2-1-1', label: 'Untouchables', icon: 'pi pi-fw pi-video', data: 'Untouchables Movie' }
                        ]
                    }
                ]
            }
        ];
    },

    addArea(data, parent = '1') {
        return Promise.all(data?.map((res)=>{
            return {
                key: `${res.in_area_id}`,
                data: res,
                children: [],
                parent
            }
        }))
    },

    async addBinToArea(data, source) {

        let insertBin = (data, nextKey, parent) => {
            return Promise.all(data?.map((res)=>{
                return {
                    key: `${nextKey}-${res.bin_id}`,
                    data: res,
                    children: [],
                    parent
                }
            }))
        }

        return Promise.all(source?.map(async (res)=>{
            let {data: resData} = res
            let filter = data.filter((record)=>{
                return record.in_area_id === resData.in_area_id
            })
            let binData = await insertBin(filter, resData.in_area_id, '2')
            
            return {
                key: `${resData.in_area_id}`,
                data: resData,
                children: (filter && filter.length) ? binData : [],
                parent: '1'
            }
        }))
    },


    async addTimetoBin(time, source) {
        
        const updateTime = (in_area_id, bin_id, appendData) => {
            return source.map((area) => {
                if(parseInt(area.key) == in_area_id){
                    return {
                        ...area,
                        children: area.children.map( (bin) => {
                            if(bin.data.bin_id == bin_id){
                                return {
                                    ...bin,
                                    children: bin.children?.push({
                                        key: `${in_area_id}-${bin_id}-${appendData.timeslot_id}`,
                                        data: appendData,
                                        children: [],
                                        parent: '3'
                                    })
                                }
                            }
                        })
                    }
                }else{
                   return area 
                }
            });
        }

        return Promise.all(time?.map(async (res)=>{
            let { in_area_id, bin_id } = res
            let newData = await updateTime(in_area_id, bin_id, res)
        }))
    },

    getTreeTableNodesData() {
        return [
            {
                key: '0',
                data: {
                    in_area_code: 'AR_01CT055_01',
                    division: 'Kitchen'
                },
                children: [
                    {
                        key: '0-0',
                        data: {
                            bin_code: 'BIN_01CT055_01',
                            bin_name: 'Meat waste',
                            max_cap: '75',
                            cap_unit: 'kg',
                            waste_type: 'Production waste'
                        },
                        children: [
                            {
                                key: '0-0-0',
                                data: {
                                    meal: 'Lunch',
                                    time_from: '12:00:00',
                                    time_to: '14:00:00'
                                }
                            },
                        ],
                        parent: '2'
                    },
                    {
                        key: '0-1',
                        data: {
                            bin_code: 'BIN_01CT055_02',
                            bin_name: 'Saled waste',
                            max_cap: '70',
                            cap_unit: 'kg',
                            waste_type: 'Production waste'
                        },
                        children: [
                            {
                                key: '0-1-0',
                                data: {
                                    meal: 'Lunch',
                                    time_from: '12:00:00',
                                    time_to: '14:00:00'
                                }
                            },
                            {
                                key: '0-1-0',
                                data: {
                                    meal: 'Dinner',
                                    time_from: '20:00:00',
                                    time_to: '22:00:00'
                                }
                            },
                        ],
                        parent: '2'
                    },
                    {
                        key: '0-2',
                        data: {
                            bin_code: 'BIN_01CT055_03',
                            bin_name: 'waste',
                            max_cap: '75',
                            cap_unit: 'kg',
                            waste_type: 'Store waste'
                        },
                        children: [
                            {
                                key: '0-2-0',
                                data: {
                                    meal: 'Lunch',
                                    time_from: '12:00:00',
                                    time_to: '14:00:00'
                                }
                            },
                        ],
                        parent: '2'
                    }
                ],
                parent: '1'
            },
            {
                key: '1',
                data: {
                    in_area_code: 'AR_01CT055_01',
                    division: 'Surving Area'
                },
                children: [
                    {
                        key: '1-0',
                        data: {
                            bin_code: 'BIN_01CT055_01',
                            bin_name: 'Plate waste 01',
                            max_cap: '75',
                            cap_unit: 'kg',
                            waste_type: 'Plate waste'
                        },
                        children: [
                            {
                                key: '1-0-0',
                                data: {
                                    meal: 'Lunch',
                                    time_from: '12:00:00',
                                    time_to: '14:00:00'
                                }
                            },
                            {
                                key: '1-0-0',
                                data: {
                                    meal: 'Dinner',
                                    time_from: '20:00:00',
                                    time_to: '22:00:00'
                                }
                            },
                        ],
                        parent: '2'
                    }
                ],
                parent: '1'
            },
            {
                key: '2',
                data: {
                    in_area_code: 'KN_01CT055_01',
                    division: 'Kitchen'
                },
                children: [
                    {
                        key: '2-0',
                        data: {
                            bin_code: 'BIN_01CT055_01',
                            bin_name: 'Meat waste',
                            max_cap: '75',
                            cap_unit: 'kg',
                            waste_type: 'Production waste'
                        },
                        children: [
                            {
                                key: '2-0-0',
                                data: {
                                    meal: 'Lunch',
                                    time_from: '12:00:00',
                                    time_to: '14:00:00'
                                }
                            },
                            {
                                key: '2-0-0',
                                data: {
                                    meal: 'Dinner',
                                    time_from: '20:00:00',
                                    time_to: '22:00:00'
                                }
                            },
                            {
                                key: '2-0-0',
                                data: {
                                    meal: 'Snack',
                                    time_from: '16:00:00',
                                    time_to: '17:00:00'
                                }
                            },
                        ],
                        parent: '2'
                    }
                ],
                parent: '1'
            }
        ];
    },

    getTreeTableNodes() {
        return Promise.resolve(this.getTreeTableNodesData());
    },

    getTreeNodes() {
        return Promise.resolve(this.getTreeNodesData());
    }
};
