import React from "react";

class Reports extends React.Component {
  constructor(props) {
    document.title = "Reports";
    super(props);
    this.state = {};
  }
  render() {
    return <div>Reports</div>;
  }
}

export default Reports;
