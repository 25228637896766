import React from "react";
import { CSVLink } from "react-csv";
import { DataGrid } from "@material-ui/data-grid";
import { Box } from "@mui/material";
import ExportIcon from "../../assets/images/export.png";
import "../../App.scss";

function Table(props) {
  const { rows, columns, pageSize } = props;
  const csvData = rows?.map((row) => columns.map((column) => row[column.field])) || [];

  return (
    <div id="table">
      <CSVLink
        data={csvData}
        headers={columns.map((column) => column.headerName)}
        filename={"data.csv"}
        className="exportBtn"
      >
        <img src={ExportIcon} className="downloadIcon" />
      </CSVLink>
      <Box sx={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={rows ? rows : []}
          columns={columns}
          pageSize={pageSize}
          checkboxSelection={false}
          disableRowSelectionOnClick
          initialState={{
            // ...data.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
        />
      </Box>
    </div>
  );
}

export default Table;
