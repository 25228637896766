import React from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import {
  addGarbageData,
  getAreaList,
  getBinList,
  getTSList,
  getWaste,
  uploadImage,
} from "../api";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { getWasteRecord } from "../../redux/actions/projectDetails";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import { Launch } from "@mui/icons-material";
import ExportIcon from "../../assets/images/export.png";
import "./projectdetails.scss";

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

class WasteRecord extends React.Component {
  constructor(props) {
    super(props);
    this.dataTable = React.createRef();
    this.state = {
      wasteRecord: [],
    };
  }

  componentDidMount = async () => {
    const {
      projDetails: { project_code, project_id },
      wasteRecord,
    } = this.props.projDetails;
    this.setState(
      {
        project_code: project_code,
        project_id: project_id,
        wasteRecord,
      },
      () => {
        this.props.getWasteRecord(this.props.project_id);
      }
    );
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.projDetails !== this.props.projDetails) {
      const {
        projDetails: { project_code, project_id },
        wasteRecord,
      } = this.props.projDetails;
      this.setState({
        project_code: project_code,
        project_id: project_id,
        wasteRecord,
      });
    }
  };

  addWasteBtn = () => {
    const units = [
      { name: "kg", code: "kg" },
      { name: "lt", code: "lt" },
    ];
    this.setState({ showAddModal: true, unitsList: units });
    getAreaList(this.state.project_id, headers)
      .then((r) => {
        this.setState({ areasList: r?.data?.data });
      })
      .catch((e) => console.log(e));
    getWaste({}, headers)
      .then((r) => {
        this.setState({ wasteTypesList: r?.data?.data });
      })
      .catch((e) => console.log(e));
  };

  imageBodyTemplate = (record) => {
    let { images } = record;
    const fetchImages = () => {
      let imageUrl = images.map((image) => {
        return image.includes("agsisraf.com/api/")
          ? `https://${image}`
          : image.replace("20.233.41.167:8080/", "https://agsisraf.com/api/");
      });
      this.setState({
        openImageModal: true,
        modalImage: imageUrl ? imageUrl : "",
      });
    };
    return (
      <a className="imageLink" onClick={() => fetchImages()}>
        Open Image
        <div className="launchIcon">
          <Launch fontSize="small" />
        </div>
      </a>
    );
  };

  dateTimeTemplate = (record, type) => {
    let { collection_date, created_date } = record;
    let cDate = created_date?.split("T")[0];
    let date = collection_date?.split("T")[0];
    return <p>{type === "created_date" ? cDate : date}</p>;
  };

  selectArea = (ele) => {
    this.setState(
      {
        area_id: ele.in_area_id,
        area: ele,
      },
      () => {
        getBinList(this.state.area_id, headers)
          .then((r) => {
            this.setState({ binsList: r?.data?.data });
          })
          .catch((e) => console.log(e));
      }
    );
  };

  selectBin = (ele) => {
    this.setState(
      {
        bin_id: ele.bin_id,
        bin: ele,
      },
      () => {
        getTSList(this.state.bin_id, headers)
          .then((r) => {
            this.setState({ tsList: r?.data?.data });
          })
          .catch((e) => console.log(e));
      }
    );
  };

  selectTS = (ele) => {
    this.setState({
      timeslot_id: ele.timeslot_id,
      timeslot: ele,
    });
  };

  selectWaste = (ele) => {
    this.setState({
      waste_id: ele.waste_id,
      wasteType: ele,
    });
  };

  selectUnit = (ele) => {
    this.setState({
      unit: ele,
    });
  };

  uploadWasteData = () => {
    const {
      dataImage1,
      dataImage2,
      area_id,
      area,
      bin_id,
      bin,
      project_id,
      project_code,
      waste_id,
      wasteType,
      weight,
      unit,
      collectionDate,
      collectionTime,
      timeslot_id,
      timeslot,
    } = this.state;
    const { projDetails } = this.props.projDetails;
    let payload = {
      supervisor_id: projDetails.supervisor_id,
      supervisor_userId: projDetails.supervisor_userid,
      project_id: project_id,
      project_code: project_code,
      area_id: area_id,
      area_code: area?.in_area_code,
      bin_id: bin_id,
      bin_code: bin?.bin_name,
      waste_id: waste_id,
      waste_type: wasteType?.waste_name,
      waste: weight,
      waste_unit: unit?.name,
      images: [dataImage1, dataImage2],
      collection_time: collectionTime,
      collection_date: collectionDate,
      manager_id: projDetails.manager_id,
      manager_userId: projDetails.manager_userid,
      timeslot_id: timeslot_id,
      meal: timeslot?.meal,
    };
    if (
      dataImage1 &&
      dataImage2 &&
      area_id &&
      area &&
      bin_id &&
      bin &&
      project_id &&
      project_code &&
      waste_id &&
      wasteType &&
      weight &&
      unit &&
      collectionDate &&
      collectionTime &&
      timeslot_id &&
      timeslot
    ) {
      addGarbageData(payload, headers)
        .then((r) => {
          if (!r?.data?.error)
            this.setState(
              {
                showAlert: true,
                severity: "success",
                alertMsg: r?.data?.message,
                showAddModal: false,
                area: {},
                bin: {},
                timeslot: {},
                wasteType: {},
                weight: "",
                unit: "",
                image1: "",
                image2: "",
                collectionDate: "",
                collectionTime: "",
              },
              () => this.props.getWasteRecord(project_id)
            );
          else this.setState({ innerMessage: r?.data?.message });
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({ innerMessage: "Please enter all Fields!" });
    }
  };

  addFooterContent = (
    <div>
      <Button
        icon="pi pi-check"
        onClick={() => {
          this.uploadWasteData();
        }}
        autoFocus
        severity="danger"
        raised
      >
        Submit
      </Button>
    </div>
  );

  handleTextChange = async (e, type) => {
    if (type === "weight") this.setState({ weight: e.target.value });
    else if (type === "date") {
      let day = new Date(e.target.value).getDate();
      let month = new Date(e.target.value).getMonth() + 1;
      let year = new Date(e.target.value).getFullYear();
      let finalDate = month + "-" + day + "-" + year;
      this.setState({ collectionDate: finalDate });
    } else if (type === "time") {
      let hours = new Date(e.target.value).getHours();
      let minutes = new Date(e.target.value).getMinutes();
      let seconds = new Date(e.target.value).getSeconds();
      let finalTime = hours + ":" + minutes + ":" + seconds;
      this.setState({ collectionTime: finalTime });
    } else if (type === "image1") {
      let image = e.target.files[0];
      let imageValue = e.target.value;
      this.setState({ image1: imageValue });

      const formData = new FormData();
      formData.append("image", image);

      let headers = {
        "Content-Type": "multipart/form-data",
      };
      await uploadImage(formData, headers)
        .then((r) => {
          let dataimage = r?.data?.file ? r.data.file : "";
          this.setState({ dataImage1: dataimage });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ dataImage1: "" });
        });
    } else if (type === "image2") {
      let image = e.target.files[0];
      let imageValue = e.target.value;
      this.setState({ image2: imageValue });

      const formData = new FormData();
      formData.append("image", image);

      let headers = {
        "Content-Type": "multipart/form-data",
      };
      await uploadImage(formData, headers)
        .then((r) => {
          let dataimage = r?.data?.file ? r.data.file : "";
          this.setState({ dataImage2: dataimage });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ dataImage2: "" });
        });
    }
  };

  handleClose = () => {
    this.setState({
      showAddModal: false,
      area: {},
      bin: {},
      timeslot: {},
      wasteType: {},
      weight: "",
      unit: "",
      image1: "",
      image2: "",
      collectionDate: "",
      collectionTime: "",
    });
  };

  exportCSV = () => {
    this.dataTable.current.exportCSV();
  };

  render() {
    return (
      <div className="project-master">
        <div className="wasteRecTab">
          <div className="actionBtn">
            <Button
              variant="contained"
              className=""
              severity="warning"
              size="small"
              onClick={this.addWasteBtn}
            >
              Add Waste Data
            </Button>
            <Button onClick={this.exportCSV} className="exportBtn">
              <img src={ExportIcon} className="downloadIcon" />
            </Button>
          </div>
          <DataTable
            value={this.state.wasteRecord}
            className="mt-4 project-treetable wasteRecTable"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            exportFilename="waste data"
            ref={this.dataTable}
          >
            <Column
              field="project_code"
              header="Project"
              sortable
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="area_code"
              header="Area"
              sortable
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="bin_code"
              header="Bin"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="meal"
              sortable
              header="Meal"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="waste"
              sortable
              header="Weight"
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="waste_type"
              header="Waste type"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              header="Image"
              sortable
              body={this.imageBodyTemplate}
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="collection_time"
              header="Collection time"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="collection_date"
              header="Collection date"
              sortable
              body={(rec) => this.dateTimeTemplate(rec, "date")}
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="created_date"
              header="Created date"
              sortable
              body={(rec) => this.dateTimeTemplate(rec, "created_date")}
              style={{ width: "15%" }}
            ></Column>
          </DataTable>

          {/* Image Modal */}
          {this.state.openImageModal ? (
            <Dialog
              header={"Bin Image"}
              visible={this.state.openImageModal}
              style={{ width: "50vw" }}
              onHide={() => this.setState({ openImageModal: false })}
            >
              <div className="image-dialog-box">
                {this.state.modalImage && this.state.modalImage.length
                  ? this.state.modalImage.map((x) => {
                      return <img src={x} height={500} width={800} />;
                    })
                  : null}
              </div>
            </Dialog>
          ) : null}

          {/* Add Modal */}
          {this.state.showAddModal ? (
            <Dialog
              header={"Add Waste Data"}
              visible={this.state.showAddModal}
              style={{ width: "30vw" }}
              onHide={this.handleClose}
              footer={this.addFooterContent}
            >
              <div className="add-dialog-box">
                {this.state.innerMessage ? (
                  <Message
                    severity="error"
                    text={this.state.innerMessage}
                    style={{
                      marginBottom: 20,
                      width: "100%",
                    }}
                  />
                ) : null}
                <div className="fieldParent">
                  <label
                    htmlFor="project"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Project
                  </label>
                  <InputText
                    id="project"
                    value={this.state.project_code}
                    disabled
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="area"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Area
                  </label>
                  <Dropdown
                    tooltip="Select Area"
                    value={this.state.area}
                    onChange={(e) => this.selectArea(e.value)}
                    options={this.state.areasList}
                    optionLabel="in_area_code"
                    placeholder="Select Area"
                    filter
                    className="w-full md:w-14rem"
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="bin"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Bin
                  </label>
                  <Dropdown
                    tooltip="Select Bin"
                    value={this.state.bin}
                    onChange={(e) => this.selectBin(e.value)}
                    options={this.state.binsList}
                    optionLabel="bin_code"
                    placeholder="Select Bin"
                    filter
                    className="w-full md:w-14rem"
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="timeslot"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Timeslot
                  </label>
                  <Dropdown
                    tooltip="Select Timeslot"
                    value={this.state.timeslot}
                    onChange={(e) => this.selectTS(e.value)}
                    options={this.state.tsList}
                    optionLabel="meal"
                    placeholder="Select Timeslot"
                    filter
                    className="w-full md:w-14rem"
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="waste"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Waste Type
                  </label>
                  <Dropdown
                    tooltip="Select Type of Waste"
                    value={this.state.wasteType}
                    onChange={(e) => this.selectWaste(e.value)}
                    options={this.state.wasteTypesList}
                    optionLabel="waste_name"
                    placeholder="Select Type of Waste"
                    filter
                    className="w-full md:w-14rem"
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="weight"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Weight
                  </label>
                  <InputText
                    id="weight"
                    value={this.state.weight}
                    onChange={(e) => this.handleTextChange(e, "weight")}
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="unit"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Unit
                  </label>
                  <Dropdown
                    tooltip="Select Unit"
                    value={this.state.unit}
                    onChange={(e) => this.selectUnit(e.value)}
                    options={this.state.unitsList}
                    optionLabel="name"
                    placeholder="Select Unit"
                    filter
                    className="w-full md:w-14rem"
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="image"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Image
                  </label>
                  <input
                    accept="image/*"
                    capture="camcorder"
                    style={{
                      width: 400,
                      height: 38,
                      marginBottom: 10,
                    }}
                    id="outlined-basic"
                    value={this.state.image1}
                    onChange={(e) => this.handleTextChange(e, "image1")}
                    type="file"
                  />
                  <input
                    accept="image/*"
                    capture="camcorder"
                    style={{
                      width: 400,
                      height: 38,
                      marginBottom: 10,
                    }}
                    id="outlined-basic"
                    value={this.state.image2}
                    onChange={(e) => this.handleTextChange(e, "image2")}
                    type="file"
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="time"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Collection Time
                  </label>
                  <Calendar
                    value={""}
                    onChange={(e) => this.handleTextChange(e, "time")}
                    timeOnly
                  />
                </div>

                <div className="fieldParent">
                  <label
                    htmlFor="date"
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    Collection Date
                  </label>
                  <Calendar
                    value={""}
                    onChange={(e) => this.handleTextChange(e, "date")}
                    showIcon
                  />
                </div>
              </div>
            </Dialog>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    projDetails: store.projectDetails,
  };
};

const mapDispatchToProps = {
  getWasteRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(WasteRecord);
