import { MENU_LIST, MENU_LIST_ACTIVE, ROLE_PERMISSIONS } from "../constants";

const initialState = {
  menuList: [],
  userPermissions: [],
  menuListActive: 1
};

const rolesPermit = (state = initialState, action) => {
  switch (action.type) {
    case MENU_LIST:
      state = {
        ...state,
        menuList: action.payload,
      };
      break;
    case MENU_LIST_ACTIVE:
      state = {
        ...state,
        menuListActive: action.payload,
      };
      break;
    case ROLE_PERMISSIONS:
      state = {
        ...state,
        userPermissions: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default rolesPermit;
