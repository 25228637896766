import React from "react";
import { connect } from "react-redux";
import { Message } from "primereact/message";
import LOGO from "../../assets/images/LOGO.png";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
} from "@mui/material";
import { submitFeedback } from "../api";
import "./surveyFeedback.scss";
import { Checkbox } from 'primereact/checkbox';
        

const questions = [
  "Kindly rate the Quality of meals provided by Algosaibi services at your facility",
  "Was the temperature of the food served by Algosaibi Service within acceptable levels for hot dishes and refrigerated items ?",
  "Menu variety of food provided",
  "How would you rate the hygiene of our facilities ?",
  "Please share with us your valuable suggestions to make your foodservice experience a more memorable event.",
  "Contact Details",
];

class SurveyFeedback extends React.Component {
  constructor(props) {
    document.title = "Feedback";
    super(props);
    this.state = {
      quesObj: {},
      comment: "",
      terms: false,
      privacy: false
    };
  }

  componentDidMount() {
    const paramsArr = window.location.pathname
      ? window.location.pathname.split("/")
      : [];
    const userId = paramsArr?.length ? paramsArr[2] : "";
    this.setState({ tentative_user_id: userId });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ innerMessage: "", severity: "" });
    }, 10000);
  }

  handleSubmit = () => {
    const { tentative_user_id, quesObj, comment } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    let quesArray = [];
    if (Object.keys(quesObj)) {
      quesArray = Object.keys(quesObj).map((x) => {
        return { question: x, answer: quesObj[x] };
      });
    }
    let payload = {
      tentative_user_id: tentative_user_id,
      questionList: quesArray,
      terms: this.state.terms,
      privachy: this.state.privacy,
      commend: comment,
    };
    submitFeedback(payload, headers)
      .then((r) => {
        let error = r?.data?.error;
        if (!error)
          this.setState({
            innerMessage: "Feedback submitted",
            severity: "success",
            comment: "",
          }, ()=>{
            window.location.href = window.location.href
          });
        else
          this.setState({
            innerMessage: "Couldnt able to submit Feedback, Try again!",
            severity: "error",
          });
      })
      .catch((e) => console.log("Error >>>>>>>>", e));
  };

  handleTextChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleQuesChange = (e, ques) => {
    const { quesObj } = this.state;
    let samp = { ...quesObj };
    samp[ques] = e.target.value;
    this.setState({ quesObj: samp });
  };

  setChecked = (name, val) => {
    this.setState({
      [name]: val
    })
  }

  render() {
    const { innerMessage, severity } = this.state;
    return (
      <div className="feedback">
        <div className="topSection">
          <img src={LOGO} className="logoIcon" />
          <h2>Your Feedback Matters !</h2>
        </div>
        <div className="feedbackSection">
          {innerMessage ? (
            <Message
              severity={severity}
              text={innerMessage}
              style={{ marginBottom: 20, width: "100%" }}
            />
          ) : null}
          <div className="ques1">
            <p style={{ display: "grid" }}>
              1. {questions[0]}
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                فضلا، قيم جودة الوجبات التي قدمت في منشآتك من القصيبي للخدمات
              </span>
            </p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // value={value}
                onChange={(e) => this.handleQuesChange(e, questions[0])}
              >
                <FormControlLabel
                  value="Excellent"
                  control={<Radio />}
                  label="Excellent"
                />
                <FormControlLabel
                  value="Very Good"
                  control={<Radio />}
                  label="Very Good"
                />
                <FormControlLabel
                  value="Satisfied"
                  control={<Radio />}
                  label="Satisfied"
                />
                <FormControlLabel
                  value="To be Improved"
                  control={<Radio />}
                  label="To be Improved"
                />
                <FormControlLabel
                  value="Dissatisfied"
                  control={<Radio />}
                  label="Dissatisfied"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="ques2">
            <p style={{ display: "grid" }}>
              2. {questions[1]}
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                هل كانت درجة حرارة الطعام الذي قدمته القصيبي مناسبة للأطباق
                الساخنة والأصناف المبردة ؟
              </span>
            </p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // value={value}
                onChange={(e) => this.handleQuesChange(e, questions[1])}
              >
                <FormControlLabel
                  value="Adequate"
                  control={<Radio />}
                  label="Adequate"
                />
                <FormControlLabel
                  value="Inadequate"
                  control={<Radio />}
                  label="Inadequate"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="ques3">
            <p style={{ display: "grid" }}>
              2. {questions[2]}
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                تنوع قائمة الطعام المقدمة
              </span>
            </p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // value={value}
                onChange={(e) => this.handleQuesChange(e, questions[2])}
              >
                <FormControlLabel
                  value="Excellent"
                  control={<Radio />}
                  label="Excellent"
                />
                <FormControlLabel
                  value="Very Good"
                  control={<Radio />}
                  label="Very Good"
                />
                <FormControlLabel
                  value="Satisfied"
                  control={<Radio />}
                  label="Satisfied"
                />
                <FormControlLabel
                  value="To be Improved"
                  control={<Radio />}
                  label="To be Improved"
                />
                <FormControlLabel
                  value="Dissatisfied"
                  control={<Radio />}
                  label="Dissatisfied"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="ques4">
            <p style={{ display: "grid" }}>
              3. {questions[3]}
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                كيف تقيم نظافة مرافقنا ؟
              </span>
            </p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // value={value}
                onChange={(e) => this.handleQuesChange(e, questions[3])}
              >
                <FormControlLabel
                  value="Excellent"
                  control={<Radio />}
                  label="Excellent"
                />
                <FormControlLabel
                  value="Very Good"
                  control={<Radio />}
                  label="Very Good"
                />
                <FormControlLabel
                  value="Satisfied"
                  control={<Radio />}
                  label="Satisfied"
                />
                <FormControlLabel
                  value="To be Improved"
                  control={<Radio />}
                  label="To be Improved"
                />
                <FormControlLabel
                  value="Dissatisfied"
                  control={<Radio />}
                  label="Dissatisfied"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="ques5">
            <p style={{ display: "grid" }}>
              4. {questions[4]}
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                يرجى مشاركتنا اقتراحاتك القيمة لجعل تجربتك في تناول
                الطعام تجربة لا تنسى
              </span>
            </p>
            <TextField
              className="text-area"
              placeholder="Please enter your comments"
              multiline
              rows={3}
              maxRows={4}
              onChange={(e) => this.handleQuesChange(e, questions[4])}
            />
          </div>
          <div className="ques6">
            <p style={{ display: "grid" }}>
              5. {questions[5]}
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                تفاصيل الاتصال
              </span>
            </p>
            <TextField
              className="text-area"
              placeholder="Please enter your comments"
              multiline
              rows={5}
              maxRows={10}
              onChange={(e) => this.handleQuesChange(e, questions[5])}
            />
          </div>
          <div className="comments">
            <p style={{ display: "grid" }}>
              6. Comments
              <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                ﺗَﻌﻠِﻴﻖ
              </span>
            </p>
            <TextField
              className="text-area"
              placeholder="Please enter your comments"
              multiline
              rows={5}
              maxRows={10}
              onChange={(e) => this.handleTextChange(e)}
            />
          </div>
          <div key={'privacy'} className="flex align-items-center termsCls">
              <Checkbox name="privacy" className={"radio"} onChange={e => this.setChecked('terms',e.checked)} checked={this.state.terms}/>
              <a href='/terms' className="ml-2">I hereby give my consent that Algosaibi services may use my contact details to contact.</a>
          </div>
          <div key={'terms'} className="flex align-items-center termsCls">
              <Checkbox name="terms" className={"radio"} onChange={e => this.setChecked('privacy', e.checked)} checked={this.state.privacy}/>
              <a href='/privacy' className="ml-2">I consent that Algosaibi services will use information shared by me for internal continuous improvements only.</a>
          </div>
          <Button
            variant="contained"
            className="submitBtn"
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(SurveyFeedback);
