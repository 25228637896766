import React from 'react';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';

const NotificationDropdown = props => {
  return (
    <div className='notification'>
      <Badge badgeContent={4} color="error">
        <NotificationsIcon color="primary" />
      </Badge>
    </div>
  )
}

export default NotificationDropdown