import React from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import { getMainWasteRecords } from "../../redux/actions/projectDetails";
import { DataTable } from "primereact/datatable";
import { Launch } from "@mui/icons-material";
import ExportIcon from "../../assets/images/export.png";
import "../Projects/projectdetails.scss";
import "./waste.scss";

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

class WasteRecords extends React.Component {
  constructor(props) {
    super(props);
    this.dataTable = React.createRef();
    this.state = {
      wasteRecord: [],
    };
  }

  componentDidMount = async () => {
    document.title = "Waste Records";
    await this.props.getMainWasteRecords();
    const { allWasteRecords } = this.props.projDetails;
    this.setState({
      wasteRecord: allWasteRecords,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.projDetails !== this.props.projDetails) {
      const { allWasteRecords } = this.props.projDetails;
      this.setState({
        wasteRecord: allWasteRecords,
      });
    }
  };

  imageBodyTemplate = (record) => {
    let { images } = record;
    const fetchImages = () => {
      let imageUrl = images.map((image) => {
        return image.includes("agsisraf.com/api/")
          ? `https://${image}`
          : image.replace("20.233.41.167:8080/", "https://agsisraf.com/api/");
      });
      this.setState({
        openImageModal: true,
        modalImage: imageUrl ? imageUrl : "",
      });
    };
    return (
      <a className="imageLink" onClick={() => fetchImages()}>
        Open Image
        <div className="launchIcon">
          <Launch fontSize="small" />
        </div>
      </a>
    );
  };

  dateTimeTemplate = (record, type) => {
    let { collection_date, created_date } = record;
    let cDate = created_date?.split("T")[0];
    let date = collection_date?.split("T")[0];
    return <p>{type === "created_date" ? cDate : date}</p>;
  };

  exportCSV = () => {
    this.dataTable.current.exportCSV();
  };

  render() {
    return (
      <div className="project-master wasteRecord">
        <h3>Waste Records</h3>
        <div className="wasteRecTab">
          <Button onClick={this.exportCSV} className="exportBtn">
            <img src={ExportIcon} className="downloadIcon" />
          </Button>
          <DataTable
            value={this.state.wasteRecord}
            className="mt-4 project-treetable"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            exportFilename="waste data"
            ref={this.dataTable}
          >
            <Column
              field="project_code"
              header="Project"
              sortable
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="area_code"
              header="Area"
              sortable
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="bin_code"
              header="Bin"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="meal"
              sortable
              header="Meal"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="waste"
              sortable
              header="Weight"
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="waste_type"
              header="Waste type"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              header="Image"
              sortable
              body={this.imageBodyTemplate}
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="collection_time"
              header="Collection time"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              header="Collection date"
              sortable
              body={(rec) => this.dateTimeTemplate(rec, "date")}
              style={{ width: "15%" }}
            ></Column>
            <Column
              header="Created date"
              sortable
              body={(rec) => this.dateTimeTemplate(rec, "created_date")}
              style={{ width: "15%" }}
            ></Column>
          </DataTable>

          {/* Image Modal */}
          {this.state.openImageModal ? (
            <Dialog
              header={"Bin Image"}
              visible={this.state.openImageModal}
              style={{ width: "50vw" }}
              onHide={() => this.setState({ openImageModal: false })}
            >
              <div className="image-dialog-box">
                {this.state.modalImage && this.state.modalImage.length
                  ? this.state.modalImage.map((x) => {
                      return <img src={x} height={500} width={800} />;
                    })
                  : null}
              </div>
            </Dialog>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    projDetails: store.projectDetails,
  };
};

const mapDispatchToProps = {
  getMainWasteRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(WasteRecords);
