import React from "react";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Pages
import Dashboard from "../pages/Dashboard/index";
import Projects from "../pages/Projects/index";
import Sessions from "../pages/Masters/sessions";
import Division from "../pages/Masters/divisions";
import Region from "../pages/Masters/regions";
import Location from "../pages/Masters/location";
import ProjectType from "../pages/Masters/projectType";
import Waste from "../pages/Masters/waste";
import ServiceArea from "../pages/Masters/serviceArea";
import Survey from "../pages/Survey Questions/survey";
import GenQR from "../pages/Generate QR";
import Notification from "../pages/Notification";
import UserRoles from "../pages/Users/userRoles";
import UserList from "../pages/Users/usersList";
// import DashboardWaste from "../pages/Dashboard/dashboardwaste";
import DashboardWaste from "../pages/Dashboard/dashboard";
import DashboardSurvey from "../pages/Dashboard/dashboardsurvey";
import Reports from "../pages/Reports";
import Supervisor from "../pages/Supervisor";
import PermitMenu from "../pages/Users/permitMenu";
import WasteRecords from "../pages/WasteRecords";
import SurveyRecords from "../pages/Survey Questions/surveyRecords";
import Profile from "../pages/UserProfile/Profile";
import ProjectDetails from '../pages/Projects/ProjectDetails';
import Banner from '../pages/Banner/index';
import SurveyQuestionsListing from "../pages/Survey Questions/surveyQuestionsListing";
import SurveyFeedback from "../pages/Survey Questions/surveyFeedback";
import Terms from "../pages/Terms/terms";
import Privacy from "../pages/Privacy/privacy";

const userRoutes = [
  //Dashboard routes
  { path: "/", component: <DashboardWaste /> },
  { path: "/dashboard/dashboardWaste", component: <DashboardWaste /> },
  { path: "/dashboard/dashboardSurvey", component: <DashboardSurvey /> },

  //Supervisor Routes
  { path: "/supervisor", component: <Supervisor /> },

  //Project Routes
  { path: "/projects", component: <Projects /> },
  { path: "/projects/projectDetails/:id", component: <ProjectDetails /> },

  //User Routes
  // { path: "/admin_user/menu_permission", component: <PermitMenu /> },
  { path: "/admin_user/user_roles", component: <UserRoles /> },
  { path: "/admin_user/users", component: <UserList /> },

  //Masters Routes
  { path: "/master_data/time_slots", component: <Sessions /> },
  { path: "/master_data/division", component: <Division /> },
  { path: "/master_data/region", component: <Region /> },
  { path: "/master_data/location", component: <Location /> },
  { path: "/master_data/project_type", component: <ProjectType /> },
  { path: "/master_data/waste_type", component: <Waste /> },
  { path: "/master_data/service_area", component: <ServiceArea /> },

  //Waste-Records
  { path: "/waste_records", component: <WasteRecords /> },

  //Survey Routes
  { path: "/master_data/survey_questions", component: <Survey /> },
  { path: "/master_data/survey_records", component: <SurveyRecords /> },
  { path: "/master_data/survey_questions_list", component: <SurveyQuestionsListing /> },

  //QR Routes
  { path: "/master_data/project_code", component: <GenQR /> },

  //Notification Routes
  { path: "/notification", component: <Notification /> },

  //Banner Routes
  { path: "/banner", component: <Banner /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/profile", component: <Profile /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

const mobileAppRoutes = [
  { path: "/survey_questions_list/:project_id/:area_id", component: <SurveyQuestionsListing /> },
  { path: "/survey-feedback/:id", component: <SurveyFeedback /> },
  { path: "/terms", component: <Terms/> },
  { path: "/privacy", component: <Privacy/> },
]

export { userRoutes, authRoutes, mobileAppRoutes };
