import React from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Box, Grid } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  getDashboardProject,
  getDashboardWaste,
  getLMDashboardGraph,
  getLMDashboardWaste,
  getRegion,
} from "../api";
import moneywaste from "../../assets/images/money-waste.png";
import weightwaste from "../../assets/images/weight-waste.png";
import production from "../../assets/images/garbage-waste.png";
import foodWaste from "../../assets/images/food-waste.png";
import storewaste from "../../assets/images/store-waste.png";
import "./dashboard.scss";
import { Button } from "primereact/button";
import DoughnutChartDemo from "./pieChart";
import MultiAxisDemo from "./multiAxis";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

        
// Get the current date
var currentDate = new Date();

// Get the month before the current date
var monthBefore = new Date(currentDate);
monthBefore.setMonth(currentDate.getMonth() - 1);

// Function to format date as DD-MM-YYYY
function formatDate(date) {
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 because months are zero-based
  var year = date.getFullYear();

  // Format the result as DD-MM-YYYY
  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
}

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

class FilterDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regionList: [],
      projectList: [],
      regionId: '', 
      projectId: '',
      startDate: monthBefore,
      endDate: currentDate
    };
  }

  componentDidMount() {
    getRegion({}, headers)
    .then((res) => {
      const data = res?.data?.data;
      this.setState({
        regionList: data
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.regionList !== this.props.regionList) {
      
    }
  }

  setRegion = (ele) => {
    this.setState({
      selectedRegion: ele,
      regionId: ele?.region_id,
      regionName: ele?.region,
    }, ()=>{
      getDashboardProject(ele?.region_id, headers)
      .then((r) => {
        this.setState({
          projectList: r.data["data"],
        });
      })
      .catch((e) =>
        console.log("LAST MONTH WASTE COLLECTION ERROR >>>>>>>>", e)
      );
    })
  }

  setSelectedCity = (value) => {
    this.setState(
      {
        selectedProject: value,
        projectId: value?.project_id
      }
    );
  };


  handleDateChange = (ele) => {
    this.setState(
      {
        [ele.target.id]: ele.target.value,
      });
  };

  render() {
    
    const {} = this.state;
    return (
      <div className="filters">
        <div className="sar">
          <label htmlFor="sarValue">Region</label>
          <Dropdown
            value={this.state.selectedRegion}
            onChange={(e) => this.setRegion(e.value)}
            options={this.state.regionList}
            optionLabel="region"
            filter
            placeholder="Select a region"
            className="w-full md:w-14rem"
          />
        </div>

        <div className="sar">
          <label htmlFor="sarValue">Project</label>
          <Dropdown
            value={this.state.selectedProject}
            onChange={(e) => this.setSelectedCity(e.value)}
            options={this.state.projectList}
            optionLabel="project_name"
            filter
            placeholder="Select a Project"
            className="w-full md:w-14rem"
          />
        </div>

        <div className="sar">
          <label htmlFor="sarValue">Start date</label>
          <Calendar
            id="startDate"
            value={this.state.startDate}
            visible={true}
            maxDate={new Date(this.state.endDate) || null}
            onChange={(e) => this.handleDateChange(e)}
          />
        </div>

        <div className="sar">
          <label htmlFor="sarValue">End date</label>
          <Calendar
            id="endDate"
            value={this.state.endDate}
            minDate={new Date(this.state.startDate) || null}
            visible={true}
            onChange={(e) => this.handleDateChange(e)}
          />
        </div>
        <div>
          <Button
            severity="info"
            onClick={() => {
              this.props.getFilterData(this.state.regionId,this.state.projectId,formatDate(this.state.startDate),formatDate(this.state.endDate), this.state.regionName)
            }}
          >
            Apply
          </Button>
        </div>
        <div style={{ marginLeft: 10 }}>
          <Button
            severity="info"
            onClick={()=>{
              window.location.href = window.location.href;
            }}
          >
            Clear
          </Button>
        </div>

        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(FilterDashboard);
