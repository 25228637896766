import React from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { addArea, addBinToArea, addTimetoBin } from "./service/NodeService";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import { QRCode } from "react-qr-svg";
import Papa from 'papaparse';
import {
  addProjectBin,
  addProjectDivision,
  addProjectTS,
  deleteProjectBin,
  deleteProjectDivision,
  deleteProjectTS,
  editProjectBin,
  editProjectDivision,
  editProjectTS,
  getDivision,
  getSession,
  getWaste,
} from "../api";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { apiError } from "../../redux/actions/loginActions";
import { Alert } from "@mui/material";
import { Message } from "primereact/message";
import {
  getProjectDivisionAction,
  getProjectBinAction,
  getProjectTimeAction,
} from "../../redux/actions/projectDetails";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Close, Info } from "@mui/icons-material";
import ExportIcon from "../../assets/images/export.png";

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

class ProjectMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exportData: [],
      nodes: [],
      expandedKeys: null,
      areaVissible: false,
      areaSetDate: {
        project_code: "",
      },
      divisionOpt: [],
      sessionOpt: [],
      showAlert: false,
      alertMsg: "",
      severity: "success",
      innerMessage: false,
      binVisible: false,
      binSetData: {
        bin_code: "",
        bin_name: "",
        bin_desc: "",
        max_cap: "",
        cap_unit: "",
        waste_type: "",
      },
      QRdata: {
        project_id: " ",
        project_code: "",
        area_id: " ",
        area_code: " ",
        bin_id: " ",
        bin_code: " ",
        waste_id: " ",
        waste_type: " ",
        waste_unit: " ",
        latitude: " ",
        longitude: " ",
      },
      timeSlotData: {
        in_area_code: "",
        in_bin_code: "",
      },
      addBinModal: false,
      showAddModal: false,
      showEditModal: false,
      showEditBinModal: false,
      binCode: "",
      project_name: "",
      showDeleteModal: false,
    };
  }

  fetchInitialData = async () => {
    const units = [
      { name: "kg", code: "kg" },
      { name: "lt", code: "lt" },
    ];
    const {
      projDetails: { project_code, project_id, project_name },
      areaDetails,
      binDetails,
      timeslotDetails,
    } = this.props.projDetails;
    let areaValue = [];
    if (areaDetails && areaDetails.length) {
      areaValue = await addArea(areaDetails);
    }
    if (binDetails && binDetails.length) {
      areaValue = await addBinToArea(binDetails, areaValue);
    }
    if (timeslotDetails && timeslotDetails.length) {
      await addTimetoBin(timeslotDetails, areaValue);
    }

    this.setState({
      areaSetDate: {
        ...this.state.areaSetDate,
        project_code: project_code,
        project_id: project_id,
      },
      project_code: project_code,
      project_id: project_id,
      project_name: project_name,
      nodes: areaValue,
      units: units,
    });
  };

  componentDidMount = () => {
    document.title = 'Project'
    const { projDetails } = this.props.projDetails;
    const { area } = projDetails;
    this.setState({ exportData: area });
    this.fetchInitialData();
  };

  componentDidUpdate = async (prevProps) => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 10000);
    if (prevProps.projDetails !== this.props.projDetails) {
      const {
        projDetails: { project_code, project_id, geolocation },
        areaDetails,
        binDetails,
        timeslotDetails,
      } = this.props.projDetails;
      let areaValue = [];
      if (areaDetails && areaDetails.length) {
        areaValue = await addArea(areaDetails);
      }
      if (binDetails && binDetails.length) {
        areaValue = await addBinToArea(binDetails, areaValue);
      }
      if (timeslotDetails && timeslotDetails.length) {
        await addTimetoBin(timeslotDetails, areaValue);
      }
      this.setState({
        areaSetDate: {
          ...this.state.areaSetDate,
          project_code: project_code,
          project_id: project_id,
        },
        project_code: project_code,
        project_id: project_id,
        latitude: geolocation?.y,
        longitude: geolocation?.x,
        nodes: areaValue,
      });
    }
  };

  addAction = async (type, editData) => {
    await getDivision({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        this.setState(
          {
            divisionOpt: data,
          },
          () => {
            if (type === "edit") {
              let divData = this.state.divisionOpt.find(
                (x) => x.division_id === editData.division_id
              );
              this.setState({
                areaSetDate: {
                  ...this.state.areaSetDate,
                  in_area_id: editData?.in_area_id,
                  in_area_code: editData?.in_area_code,
                  division_id: editData?.division_id,
                  division: divData,
                },
              });
            }
          }
        );
      })
      .catch((e) => console.log(e));

    this.setState({
      areaVissible: !this.state.areaVissible,
      showEditModal: type === "edit" ? true : false,
      showAddModal: type === "add" ? true : false,
    });
  };

  setBinVisible = (ele, type) => {
    getWaste({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        this.setState(
          {
            wasteRec: data,
          },
          () => {
            let wasteData = this.state.wasteRec.find(
              (x) => x.waste_id === ele?.data?.waste_id
            );
            let unitData = this.state.units.find(
              (x) => x.name === ele?.data?.cap_unit
            );
            if (type === "edit") {
              this.setState({
                binSetData: {
                  ...this.state.binSetData,
                  in_area_id: ele?.data?.in_area_id,
                  in_area_code: ele?.data?.in_area_code,
                  bin_id: ele?.data?.bin_id,
                  bin_code: ele?.data?.bin_code,
                  bin_name: ele?.data?.bin_name,
                  bin_desc: ele?.data?.bin_desc,
                  project_id: ele?.data?.project_id,
                  project_code: ele?.data?.project_code,
                  max_cap: ele?.data?.max_cap,
                  cap_unit: unitData,
                  waste_id: ele?.data?.waste_id,
                  waste_type: wasteData,
                },
              });
            }
          }
        );
      })
      .catch((e) => console.log(e));

    this.setState({
      binVisible: !this.state.binVisible,
      addBinModal: type === "create" ? true : false,
      showEditBinModal: type === "edit" ? true : false,
      binSetData: {
        ...this.state.binSetData,
        in_area_id: ele?.data?.in_area_id,
        in_area_code: ele?.data?.in_area_code,
      },
    });
  };

  editArea = (ele) => {
    const { data } = ele;
    this.addAction("edit", data);
  };

  editBin = (ele) => {
    this.setState({ showEditBinModal: true });
    this.setBinVisible(ele, "edit");
  };

  editTS = (ele) => {
    this.setState({ showEditTimeSlot: false });
    this.addTimeslot(ele, "edit");
  };

  deleteArea = (ele) => {
    const { data } = ele;
    this.setState({
      areaDelData: data,
      showDeleteModal: true,
      deleteType: "area",
    });
  };

  deleteBin = (ele) => {
    const { data } = ele;
    this.setState({
      binDelData: data,
      showDeleteModal: true,
      deleteType: "bin",
    });
  };

  deleteTS = (ele) => {
    const { data } = ele;
    this.setState({ tsDelData: data, showDeleteModal: true, deleteType: "ts" });
  };

  deleteAction = () => {
    const { deleteType, areaDelData, binDelData, tsDelData, project_id } =
      this.state;
    if (deleteType === "area") {
      let payload = {
        in_area_id: areaDelData.in_area_id,
      };
      deleteProjectDivision(payload, headers)
        .then((r) => {
          this.setState({
            showAlert: true,
            severity: r?.data?.error ? "error" : "success",
            alertMsg: r?.data?.message,
            showDeleteModal: false,
          });
          // this.fetchInitialData();
          this.props.getProjectDivisionAction(project_id);
        })
        .catch((e) => console.log(e));
    } else if (deleteType === "bin") {
      let payload = {
        bin_id: binDelData.bin_id,
      };
      deleteProjectBin(payload, headers)
        .then((r) => {
          this.setState({
            showAlert: true,
            severity: r?.data?.error ? "error" : "success",
            alertMsg: r?.data?.message,
            showDeleteModal: false,
          });
          // this.fetchInitialData();
          this.props.getProjectBinAction(project_id);
        })
        .catch((e) => console.log(e));
    } else if (deleteType === "ts") {
      let payload = {
        meal_id: tsDelData.map_meal_id,
      };
      deleteProjectTS(payload, headers)
        .then((r) => {
          this.setState({
            showAlert: true,
            severity: r?.data?.error ? "error" : "success",
            alertMsg: r?.data?.message,
            showDeleteModal: false,
          });
          // this.fetchInitialData();
          this.props.getProjectTimeAction(project_id);
        })
        .catch((e) => console.log(e));
    }
  };

  actionTemplate = (ele) => {
    if (ele?.parent === "2") {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            type="button"
            link
            // tooltip="Create QR code"
            onClick={() => {
              this.setVisible(ele);
            }}
          >
            QR
          </Button>

          <Button
            type="button"
            link
            // tooltip="Enable timeslot"
            onClick={() => {
              this.addTimeslot(ele, "add");
            }}
          >
            Time
          </Button>

          <Button
            type="button"
            link
            // tooltip="Edit Bin"
            onClick={() => {
              this.editBin(ele);
            }}
          >
            Edit
          </Button>

          <Button
            type="button"
            link
            // tooltip="Delete Bin"
            onClick={() => {
              this.deleteBin(ele);
            }}
          >
            Delete
          </Button>
        </div>
      );
    }

    if (ele?.parent === "1") {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            type="button"
            link
            // tooltip="To create bin"
            onClick={() => {
              this.setBinVisible(ele, "create");
            }}
          >
            Bin
          </Button>
          <Button
            type="button"
            link
            // tooltip="Edit Area"
            onClick={() => {
              this.editArea(ele);
            }}
          >
            Edit
          </Button>
          <Button
            type="button"
            link
            // tooltip="Delete Area"
            onClick={() => {
              this.deleteArea(ele);
            }}
          >
            Delete
          </Button>
        </div>
      );
    }

    if (ele?.parent === "3") {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          {/* <Button
            type="button"
            link
            // tooltip="Edit Timeslot"
            onClick={() => {
              this.editTS(ele);
            }}
          >
            Edit
          </Button> */}
          <Button
            type="button"
            link
            // tooltip="Delete Timeslot"
            onClick={() => {
              this.deleteTS(ele);
            }}
          >
            Delete
          </Button>
        </div>
      );
    }
  };

  setExpandedKeys = (ele) => {
    this.setState({
      expandedKeys: ele,
    });
  };

  addAreaAction = () => {
    let { division, project_id, project_code, division_id, in_area_code } =
      this.state.areaSetDate;
    let payload = {
      division: division?.["division_name"],
      project_id,
      project_code,
      division_id,
      in_area_code,
    };

    if (division && project_id && division_id && in_area_code && project_code) {
      addProjectDivision(payload, headers)
        .then((res) => {
          const data = res?.data;
          this.setState(
            {
              showAlert: true,
              alertMsg: data.message,
              severity: "success",
              areaVissible: false,
              showAddModal: false,
              innerMessage: false,
              areaSetDate: {
                project_code,
              },
              projectId: project_id,
            },
            () => {
              this.props.getProjectDivisionAction(this.state.projectId);
            }
          );
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({
        innerMessage: "All columns are required",
      });
    }
  };

  editAreaAction = () => {
    let {
      division,
      project_id,
      project_code,
      division_id,
      in_area_code,
      in_area_id,
    } = this.state.areaSetDate;
    let payload = {
      division: division?.["division_name"],
      project_id,
      project_code,
      division_id,
      in_area_code,
      in_area_id,
    };

    if (division && project_id && division_id && in_area_code && project_code) {
      editProjectDivision(payload, headers)
        .then((res) => {
          const data = res?.data;
          this.setState(
            {
              showAlert: true,
              alertMsg: data.message,
              severity: "success",
              areaVissible: false,
              showEditModal: false,
              innerMessage: false,
              areaSetDate: {
                project_code,
              },
              projectId: project_id,
            },
            () => {
              this.props.getProjectDivisionAction(this.state.projectId);
            }
          );
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({
        innerMessage: "All columns are required",
      });
    }
  };

  createBinAction = () => {
    let {
      bin_code,
      bin_desc,
      bin_name,
      cap_unit,
      in_area_code,
      in_area_id,
      max_cap,
      waste_id,
      waste_type,
    } = this.state.binSetData;

    let { project_id, project_code } = this.state;

    let payload = {
      bin_code,
      bin_desc,
      bin_name,
      cap_unit: cap_unit?.["name"],
      in_area_code,
      in_area_id,
      max_cap,
      waste_id,
      waste_type: waste_type?.["waste_name"],
      project_id,
      project_code,
    };

    if (
      bin_code &&
      bin_name &&
      cap_unit &&
      in_area_code &&
      in_area_id &&
      max_cap &&
      waste_id &&
      waste_type &&
      project_id &&
      project_code
    ) {
      addProjectBin(payload, headers)
        .then((res) => {
          const data = res?.data;
          this.setState(
            {
              showAlert: true,
              alertMsg: data.message,
              addBinModal: false,
              severity: "success",
              binVisible: false,
              innerMessage: false,
              binSetData: {},
            },
            () => {
              this.props.getProjectBinAction(project_id);
            }
          );
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({
        innerMessage: "All columns are required",
      });
    }
  };

  editBinAction = () => {
    let {
      bin_code,
      bin_desc,
      bin_name,
      cap_unit,
      in_area_code,
      in_area_id,
      max_cap,
      waste_id,
      waste_type,
      bin_id,
    } = this.state.binSetData;

    let { project_id, project_code } = this.state;

    let payload = {
      bin_id,
      bin_code,
      bin_desc,
      bin_name,
      cap_unit: cap_unit?.["name"],
      in_area_code,
      in_area_id,
      max_cap,
      waste_id,
      waste_type: waste_type?.["waste_name"],
      project_id,
      project_code,
    };

    if (
      bin_code &&
      bin_name &&
      cap_unit &&
      in_area_code &&
      in_area_id &&
      max_cap &&
      waste_id &&
      waste_type &&
      project_id &&
      project_code
    ) {
      editProjectBin(payload, headers)
        .then((res) => {
          const data = res?.data;
          this.setState(
            {
              showAlert: true,
              alertMsg: data.message,
              showEditBinModal: false,
              severity: "success",
              binVisible: false,
              innerMessage: false,
              binSetData: {},
            },
            () => {
              this.props.getProjectBinAction(project_id);
            }
          );
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({
        innerMessage: "All columns are required",
      });
    }
  };

  footerContent = (
    <div>
      <Button
        icon="pi pi-check"
        onClick={() => {
          window.print();
        }}
        autoFocus
      >
        Print
      </Button>
    </div>
  );

  addAreaFooterContent = (
    <div>
      <Button
        icon="pi pi-check"
        onClick={() => {
          this.addAreaAction();
        }}
        autoFocus
        severity="danger"
        raised
      >
        Add
      </Button>
    </div>
  );

  editAreaFooterContent = (
    <div>
      <Button
        icon="pi pi-check"
        onClick={() => {
          this.editAreaAction();
        }}
        autoFocus
        severity="danger"
        raised
      >
        Update
      </Button>
    </div>
  );

  addBinFooterContent = (
    <div>
      <Button
        icon="pi pi-check"
        onClick={() => {
          this.createBinAction();
        }}
        autoFocus
        severity="danger"
        raised
      >
        Create
      </Button>
    </div>
  );

  editBinFooterContent = (
    <div>
      <Button
        icon="pi pi-check"
        onClick={() => {
          this.editBinAction();
        }}
        autoFocus
        severity="danger"
        raised
      >
        Update
      </Button>
    </div>
  );

  setVisible = (ele) => {
    let result;
    let data = {
      bin_code: "",
    };

    if (ele && ele.data) {
      result = ele.data;
      data = {
        project_id: result.project_id,
        project_code: result.project_code,
        area_id: result.in_area_id,
        area_code: result.in_area_code,
        bin_id: result.bin_id,
        bin_code: result.bin_code,
        waste_id: result.waste_id,
        waste_type: result.waste_type,
        waste_unit: result.cap_unit,
        max_cap: result.max_cap,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      };
    }

    this.setState({
      visible: !this.state.visible,
      QRdata: JSON.stringify(data),
      binCode: data.bin_code || "",
    });
  };

  addTimeslot = (ele, type) => {
    if (ele) {
      let { data: dataSet } = ele;

      getSession({}, headers)
        .then((res) => {
          const data = res?.data?.data;
          this.setState(
            {
              sessionOpt: data,
              timeSlotData: {
                in_area_code: dataSet.in_area_code,
                in_area_id: dataSet.in_area_id,
                bin_code: dataSet.bin_code,
                bin_id: dataSet.bin_id,
                project_code: dataSet.project_code,
                project_id: dataSet.project_id,
              },
            },
            () => {
              let sessionData = this.state.sessionOpt.filter(
                (x) => x.time_slot_id === dataSet.timeslot_id
              );
              if (type === "edit") {
                this.setState({
                  timeSlotData: {
                    in_area_code: dataSet.in_area_code,
                    in_area_id: dataSet.in_area_id,
                    bin_code: dataSet.bin_code,
                    bin_id: dataSet.bin_id,
                    project_code: dataSet.project_code,
                    project_id: dataSet.project_id,
                    time_from: dataSet.time_from,
                    time_to: dataSet.time_to,
                    timeslot_id: dataSet.timeslot_id,
                    map_meal_id: dataSet.map_meal_id,
                    meal: dataSet.meal,
                    session: sessionData,
                  },
                });
              }
            }
          );
        })
        .catch((e) => console.log(e));
    }

    this.setState({
      timslotVisible: !this.state.timslotVisible,
      showEditTimeSlot: type === "edit" ? true : false,
      showAddTimeSlot: type === "add" ? true : false,
    });
  };

  selectDivision = (ele) => {
    this.setState({
      areaSetDate: {
        ...this.state.areaSetDate,
        division_id: ele.division_id,
        division: ele,
      },
    });
  };

  selectWaste = (ele) => {
    this.setState({
      binSetData: {
        ...this.state.binSetData,
        waste_id: ele.waste_id,
        waste_type: ele,
      },
    });
  };

  selectUnit = (ele) => {
    this.setState({
      binSetData: {
        ...this.state.binSetData,
        cap_unit: ele,
      },
    });
  };

  handleText = (ele) => {
    this.setState({
      areaSetDate: {
        ...this.state.areaSetDate,
        in_area_code: ele.target.value,
      },
    });
  };

  handleChangeText = (ele) => {
    this.setState({
      binSetData: {
        ...this.state.binSetData,
        [ele.target.id]: ele.target.value,
      },
    });
  };
  styles = {
    root: {
      fontFamily: "sans-serif",
    },
    h1: {
      textAlign: "center",
    },
    qrcode: {
      textAlign: "center",
    },
  };

  setTimeslot = (ele) => {
    let {
      bin_id,
      bin_code,
      in_area_id,
      in_area_code,
      project_id,
      project_code,
    } = this.state.timeSlotData;

    if (ele && ele.length) {
      let { end_time, start_time, meals, time_slot_id, is_open_time } =
        ele?.[ele.length - 1];
      let payload = {
        bin_id,
        bin_code,
        in_area_id,
        in_area_code,
        project_id,
        project_code,
        is_open_time: is_open_time,
        timeslot_id: time_slot_id,
        meal: meals,
        time_from: start_time,
        time_to: end_time,
      };

      if (
        bin_id &&
        bin_code &&
        in_area_id &&
        in_area_code &&
        project_id &&
        project_code &&
        time_slot_id &&
        meals &&
        start_time &&
        end_time
      ) {
        addProjectTS(payload, headers)
          .then((res) => {
            const data = res?.data;
            this.setState(
              {
                timeSlot: true,
                timeSlotMsg: data.message,
                severity: "success",
                timeMessage: "Timeslot updated",
                showAddTimeSlot: false,
              },
              () => {
                this.props.getProjectTimeAction(project_id);
              }
            );
          })
          .catch((e) => {
            console.log("ERRORRR >>>", e);
            this.setState({
              timeSlot: true,
              severity: "error",
              timeMessage: e.response.data.message,
            });
          });
      } else {
        this.setState({
          timeMessage: "All columns are required",
        });
      }
    }

    this.setState({
      session: ele,
    });
  };

  editSlot = (ele) => {
    let {
      bin_id,
      bin_code,
      in_area_id,
      in_area_code,
      project_id,
      project_code,
    } = this.state.timeSlotData;

    if (ele && ele.length) {
      let { end_time, start_time, meals, time_slot_id, map_meal_id } =
        ele?.[ele.length - 1];
      let payload = {
        map_meal_id,
        bin_id,
        bin_code,
        in_area_id,
        in_area_code,
        project_id,
        project_code,
        timeslot_id: time_slot_id,
        meal: meals,
        time_from: start_time,
        time_to: end_time,
      };

      if (
        bin_id &&
        bin_code &&
        in_area_id &&
        in_area_code &&
        project_id &&
        project_code &&
        time_slot_id &&
        meals &&
        start_time &&
        end_time
      ) {
        editProjectTS(payload, headers)
          .then((res) => {
            const data = res?.data;
            this.setState(
              {
                timeSlot: true,
                timeSlotMsg: data.message,
                severity: "success",
                timeMessage: "Timeslot updated",
                showEditTimeSlot: false,
              },
              () => {
                this.props.getProjectTimeAction(project_id);
              }
            );
          })
          .catch((e) => {
            console.log("ERRORRR >>>", e);
            this.setState({
              timeSlot: true,
              severity: "error",
              timeMessage: e.response.data.message,
            });
          });
      } else {
        this.setState({
          timeMessage: "All columns are required",
        });
      }
    }

    this.setState({
      session: ele,
    });
  };

  convertAPIDataToCSV() {
    // Assuming the API response is an array of objects
    const {exportData} = this.state;
    const csvData = Papa.unparse(exportData);
    return csvData;
  }

  exportCSV = () => {
    const csvData = this.convertAPIDataToCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'project-details.csv';
    link.click();
  };

  render() {
    let { project_code, in_area_code, division } = this.state.areaSetDate;
    let { showAlert, severity, alertMsg, innerMessage } = this.state;
    let { bin_code, bin_name, bin_desc, max_cap, cap_unit, waste_type } =
      this.state.binSetData;
    return (
      <div className="project-master">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
            className="alertDialog"
          >
            {alertMsg}
          </Alert>
        ) : null}

        <div className="alignright">
          <Button
            onClick={() => this.addAction("add")}
            severity="warning"
            label="Add Area"
            size="small"
          />
          <Button onClick={this.exportCSV} className="exportBtn">
            <img src={ExportIcon} className="downloadIcon" />
          </Button>
        </div>

        <TreeTable
          value={this.state.nodes}
          expandedKeys={this.state.expandedKeys}
          onToggle={(e) => this.setExpandedKeys(e.value)}
          className="mt-4 project-treetable"
        >
          <Column
            sortable
            field="in_area_code"
            header="Area code"
            expander
            className="areacode"
          ></Column>
          <Column
            sortable
            field="division"
            header="Division"
            className="master"
          ></Column>
          <Column
            sortable
            field="bin_code"
            header="Bin code"
            className="master"
          ></Column>
          <Column
            sortable
            field="bin_name"
            header="Bin name"
            className="master"
          ></Column>
          <Column
            sortable
            field="max_cap"
            header="Maximum"
            className="master"
          ></Column>
          <Column
            sortable
            field="cap_unit"
            header="Unit"
            className="master"
          ></Column>
          <Column
            sortable
            field="waste_type"
            header="Type"
            className="master"
          ></Column>
          <Column
            sortable
            field="meal"
            header="Meal"
            className="master"
          ></Column>
          <Column
            sortable
            field="time_from"
            header="Time from"
            className="master"
          ></Column>
          <Column
            sortable
            field="time_to"
            header="Time to"
            className="master"
          ></Column>
          <Column
            body={(ele) => this.actionTemplate(ele)}
            header="Action"
            headerClassName="w-10rem"
          />
        </TreeTable>

        {/* Scan QR */}
        <div >
          <Dialog
            id="printable-section" 
            className="print-content" 
            header={`${this.props.project_name} - ${this.state.binCode}`}
            visible={this.state.visible}
            style={{ width: "30vw" }}
            onHide={() => this.setVisible(false)}
            footer={this.footerContent}
          >
            <div style={this.styles.root} >
              <div style={this.styles.qrcode}>
                <QRCode
                  level="Q"
                  style={{ width: 256 }}
                  value={this.state.QRdata}
                />
              </div>
            </div>
          </Dialog>
        </div>

        {/* Add Area */}
        <div>
          <Dialog
            header="Add area"
            visible={this.state.showAddModal}
            style={{ width: "30vw" }}
            onHide={() => this.setState({ showAddModal: false })}
            footer={this.addAreaFooterContent}
          >
            <div>
              {innerMessage ? (
                <Message
                  severity="error"
                  text={innerMessage}
                  style={{
                    marginBottom: 20,
                  }}
                />
              ) : null}

              <div className="fieldParent">
                <label
                  htmlFor="project"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Project
                </label>
                <InputText id="project" value={project_code} disabled />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Area code
                </label>
                <InputText
                  id="area"
                  value={in_area_code}
                  aria-describedby="area"
                  tooltip="Provide area code to create area under the division. Please maintain pattern. Pattern: AR_<project_code>_<number>, Ex: AR_00001_01"
                  onChange={(e) => this.handleText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="division"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Division
                </label>
                <Dropdown
                  tooltip="Select division"
                  value={division}
                  onChange={(e) => this.selectDivision(e.value)}
                  options={this.state.divisionOpt}
                  optionLabel="division_name"
                  placeholder="Select a Division"
                  filter
                  // valueTemplate={selectedCountryTemplate}
                  // itemTemplate={countryOptionTemplate}
                  className="w-full md:w-14rem"
                />
              </div>
            </div>
          </Dialog>
        </div>

        {/* Edit Area */}
        <div>
          <Dialog
            header="Edit Area"
            visible={this.state.showEditModal}
            style={{ width: "30vw" }}
            onHide={() => this.setState({ showEditModal: false })}
            footer={this.editAreaFooterContent}
          >
            <div>
              {innerMessage ? (
                <Message
                  severity="error"
                  text={innerMessage}
                  style={{
                    marginBottom: 20,
                  }}
                />
              ) : null}

              <div className="fieldParent">
                <label
                  htmlFor="project"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Project
                </label>
                <InputText id="project" value={project_code} disabled />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Area code
                </label>
                <InputText
                  id="area"
                  value={in_area_code}
                  aria-describedby="area"
                  tooltip="Provide area code to create area under the division. Please maintain pattern. Pattern: AR_<project_code>_<number>, Ex: AR_00001_01"
                  onChange={(e) => this.handleText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="division"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Division
                </label>
                <Dropdown
                  tooltip="Select division"
                  value={division}
                  onChange={(e) => this.selectDivision(e.value)}
                  options={this.state.divisionOpt}
                  optionLabel="division_name"
                  placeholder="Select a Division"
                  filter
                  // valueTemplate={selectedCountryTemplate}
                  // itemTemplate={countryOptionTemplate}
                  className="w-full md:w-14rem"
                />
              </div>
            </div>
          </Dialog>
        </div>

        {/* Edit Bin */}
        <div>
          <Dialog
            header="Edit Bin"
            visible={this.state.showEditBinModal}
            style={{ width: "30vw" }}
            onHide={() => this.setState({ showEditBinModal: false })}
            footer={this.editBinFooterContent}
          >
            <div>
              {innerMessage ? (
                <Message
                  severity="error"
                  text={innerMessage}
                  style={{
                    marginBottom: 20,
                  }}
                />
              ) : null}

              <div className="fieldParent">
                <label
                  htmlFor="project"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Project
                </label>
                <InputText id="project" value={project_code} disabled />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Area
                </label>
                <InputText
                  id="area"
                  value={this.state?.binSetData?.in_area_code}
                  disabled
                  aria-describedby="area"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin Code
                </label>
                <InputText
                  id="bin_code"
                  value={bin_code}
                  aria-describedby="bin"
                  tooltip="Provide bin code to create bin under the area. Please maintain pattern. Pattern: BIN_<area_code>_<number>, Ex: AR_00001_01"
                  onChange={(e) => this.handleChangeText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin Name
                </label>
                <InputText
                  id="bin_name"
                  value={bin_name}
                  aria-describedby="bin"
                  onChange={(e) => this.handleChangeText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin Description
                </label>
                <InputTextarea
                  id="bin_desc"
                  value={bin_desc}
                  aria-describedby="bin"
                  onChange={(e) => this.handleChangeText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Maximum Capacity
                </label>
                <div className="p-inputgroup w-full md:w-30rem">
                  <InputText
                    id="max_cap"
                    value={max_cap}
                    aria-describedby="bin"
                    onChange={(e) => this.handleChangeText(e)}
                  />
                  <span className="p-inputgroup-addon">{cap_unit?.name}</span>
                </div>
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Unit
                </label>
                <Dropdown
                  tooltip="Select Unit"
                  value={cap_unit}
                  onChange={(e) => this.selectUnit(e.value)}
                  options={this.state.units}
                  optionLabel="name"
                  placeholder="Select unit"
                  filter
                  className="w-full md:w-14rem"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="division"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Waste
                </label>
                <Dropdown
                  tooltip="Select waste type"
                  value={waste_type}
                  onChange={(e) => this.selectWaste(e.value)}
                  options={this.state.wasteRec}
                  optionLabel="waste_name"
                  placeholder="Select a type of waste"
                  filter
                  className="w-full md:w-14rem"
                />
              </div>
            </div>
          </Dialog>
        </div>

        {/* Create Bin */}
        <div>
          <Dialog
            header="Create bin"
            visible={this.state.addBinModal}
            style={{ width: "30vw" }}
            onHide={() => this.setState({ addBinModal: false })}
            footer={this.addBinFooterContent}
          >
            <div>
              {innerMessage ? (
                <Message
                  severity="error"
                  text={innerMessage}
                  style={{
                    marginBottom: 20,
                  }}
                />
              ) : null}

              <div className="fieldParent">
                <label
                  htmlFor="project"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Project
                </label>
                <InputText id="project" value={project_code} disabled />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Area
                </label>
                <InputText
                  id="area"
                  value={this.state?.binSetData?.in_area_code}
                  disabled
                  aria-describedby="area"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin Code
                </label>
                <InputText
                  id="bin_code"
                  value={bin_code}
                  aria-describedby="bin"
                  tooltip="Provide bin code to create bin under the area. Please maintain pattern. Pattern: BIN_<area_code>_<number>, Ex: AR_00001_01"
                  onChange={(e) => this.handleChangeText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin Name
                </label>
                <InputText
                  id="bin_name"
                  value={bin_name}
                  aria-describedby="bin"
                  onChange={(e) => this.handleChangeText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin Description
                </label>
                <InputTextarea
                  id="bin_desc"
                  value={bin_desc}
                  aria-describedby="bin"
                  onChange={(e) => this.handleChangeText(e)}
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Maximum Capacity
                </label>
                <div className="p-inputgroup w-full md:w-30rem">
                  <InputText
                    id="max_cap"
                    value={max_cap}
                    aria-describedby="bin"
                    onChange={(e) => this.handleChangeText(e)}
                  />
                  <span className="p-inputgroup-addon">{cap_unit?.name}</span>
                </div>
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Unit
                </label>
                {/* <InputText id="bin" 
                    value={cap_unit} 
                    aria-describedby="bin" 
                    disabled
                  /> */}
                <Dropdown
                  tooltip="Select waste type"
                  value={cap_unit}
                  onChange={(e) => this.selectUnit(e.value)}
                  options={this.state.units}
                  optionLabel="name"
                  placeholder="Select unit"
                  filter
                  className="w-full md:w-14rem"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="division"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Waste
                </label>
                <Dropdown
                  tooltip="Select waste type"
                  value={waste_type}
                  onChange={(e) => this.selectWaste(e.value)}
                  options={this.state.wasteRec}
                  optionLabel="waste_name"
                  placeholder="Select a type of waste"
                  filter
                  className="w-full md:w-14rem"
                />
              </div>
            </div>
          </Dialog>
        </div>

        {/* Assign TS */}
        <div>
          <Dialog
            header="Assign timeslots"
            visible={this.state.showAddTimeSlot}
            style={{ width: "30vw" }}
            onHide={() => this.setState({ showAddTimeSlot: false })}
          >
            <div>
              {this.state.timeMessage ? (
                <Message
                  severity={this.state.severity || "error"}
                  text={this.state.timeMessage}
                  style={{
                    marginBottom: 20,
                  }}
                />
              ) : null}

              <div className="fieldParent">
                <label
                  htmlFor="project"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Project
                </label>
                <InputText
                  id="project"
                  value={this.state?.timeSlotData?.project_code}
                  disabled
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Area
                </label>
                <InputText
                  id="area"
                  value={this.state?.timeSlotData?.in_area_code}
                  disabled
                  aria-describedby="area"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin
                </label>
                <InputText
                  id="area"
                  value={this.state?.timeSlotData?.bin_code}
                  disabled
                  aria-describedby="area"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Timeslot
                </label>
                <MultiSelect
                  value={this.state.session}
                  onChange={(e) => this.setTimeslot(e.value)}
                  options={this.state.sessionOpt}
                  optionLabel="meals"
                  placeholder="Select meal times"
                  filter
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                />
              </div>
            </div>
          </Dialog>
        </div>

        {/* Edit TS */}
        <div>
          <Dialog
            header="Edit timeslots"
            visible={this.state.showEditTimeSlot}
            style={{ width: "30vw" }}
            onHide={() => this.setState({ showEditTimeSlot: false })}
          >
            <div>
              {this.state.timeMessage ? (
                <Message
                  severity={this.state.severity || "error"}
                  text={this.state.timeMessage}
                  style={{
                    marginBottom: 20,
                  }}
                />
              ) : null}

              <div className="fieldParent">
                <label
                  htmlFor="project"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Project
                </label>
                <InputText
                  id="project"
                  value={this.state?.timeSlotData?.project_code}
                  disabled
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Area
                </label>
                <InputText
                  id="area"
                  value={this.state?.timeSlotData?.in_area_code}
                  disabled
                  aria-describedby="area"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Bin
                </label>
                <InputText
                  id="area"
                  value={this.state?.timeSlotData?.bin_code}
                  disabled
                  aria-describedby="area"
                />
              </div>

              <div className="fieldParent">
                <label
                  htmlFor="area"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Timeslot
                </label>
                <MultiSelect
                  value={this.state.session}
                  onChange={(e) => this.editSlot(e.value)}
                  options={this.state.sessionOpt}
                  optionLabel="meals"
                  placeholder="Select meal times"
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                />
              </div>
            </div>
          </Dialog>
        </div>

        {/* Delete Modal */}
        <div>
          <Dialog
            header="Confirm the Action"
            visible={this.state.showDeleteModal}
            style={{ width: "30vw" }}
            onHide={() => this.setState({ showDeleteModal: false })}
            className="deleteDialog"
          >
            <div>
              <h4>Do you really want to delete this entry?</h4>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  className=""
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className=""
                  onClick={this.deleteAction}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    projDetails: store.projectDetails,
  };
};

const mapDispatchToProps = {
  getProjectDivisionAction,
  getProjectBinAction,
  getProjectTimeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMaster);
