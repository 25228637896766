import React from 'react';
import { connect } from 'react-redux';

class Profile extends React.Component{
    constructor(props){
        document.title = "User Profile";
        super(props);
        this.state = {}
    }
    render(){
        return (
            <div>
                Profile
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      loginData: state.Login
    };
  };
  
  export default connect(mapStateToProps)(Profile);