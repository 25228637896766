import { getProjectDetails, getProjectDivision, getProjectBin, getProjectTS, getGarbageData, getMainGarbageData, getSurveyData } from "../../pages/api";
import { PROJ_AREA_DETAILS, PROJ_DETAILS, PROJ_BIN_DETAILS, PROJ_TIME_DETAILS, PROJ_WASTE_DETAILS, PROJ_SURVEY_DETAILS, ALL_WASTE_RECORDS } from "../constants";

const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";

const loadProjectDetails = (params) => async (dispatch) => {
    const response = await getProjectDetails(params, {
        auth: jwt
    });
    dispatch({ type: PROJ_DETAILS, payload: response.data.data });
};

const getProjectDivisionAction = (projectId) => async (dispatch) => {
    const response = await getProjectDivision(projectId, {
        auth: jwt
    });
    dispatch({ type: PROJ_AREA_DETAILS, payload: response.data.data });
}

const getProjectBinAction = (project_id) => async (dispatch) => {
    const response = await getProjectBin(project_id, {
        auth: jwt
    });
    dispatch({ type: PROJ_BIN_DETAILS, payload: response.data.data });
}

const getProjectTimeAction = (project_id) => async (dispatch) => {
    const response = await getProjectTS(project_id, {
        auth: jwt
    });
    dispatch({ type: PROJ_TIME_DETAILS, payload: response.data.data });
}

const getWasteRecord = (project_id) => async (dispatch) => {
    const response = await getGarbageData(project_id, {
        auth: jwt
    });
    dispatch({ type: PROJ_WASTE_DETAILS, payload: response.data.data });
}

const getMainWasteRecords = () => async (dispatch) => {
    const response = await getMainGarbageData({
        auth: jwt
    });
    dispatch({ type: ALL_WASTE_RECORDS, payload: response.data.data });
}

const getSurveryRecord = (project_id) => async (dispatch) => {
    const response = await getSurveyData(project_id, {
        auth: jwt
    });
    dispatch({ type: PROJ_SURVEY_DETAILS, payload: response.data.data });
}

export {
    loadProjectDetails,
    getProjectDivisionAction,
    getProjectBinAction,
    getProjectTimeAction,
    getWasteRecord,
    getMainWasteRecords,
    getSurveryRecord
}