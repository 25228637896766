import React from "react";

const Register = props => {

  document.title = "Register";
  return (
    <React.Fragment>
        <h2>Register</h2>
    </React.Fragment>
  );
};

export default Register