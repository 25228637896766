import React from "react";
import { connect } from "react-redux";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  Alert,
  Modal,
  Radio,
} from "@mui/material";
import { Delete, Edit, Info, Close, Launch } from "@mui/icons-material";
import { InputSwitch } from "primereact/inputswitch";
import Table from "../../components/Common/Table";
import { addBanner, deleteBanner, getBanner, updateStatus, uploadImage } from "../api";
import "./banner.scss";

const rows = [{ id: 1, bannerLoc: "Home Screen" }];

class Banner extends React.Component {
  constructor(props) {
    document.title = "Banner";
    super(props);
    this.state = {
      rowData: [],
      columnData: [],
      showAddModal: false,
      showDeleteModal: false,
      bannerImage: "",
      bannerLocation: "",
      editedDetails: {},
      showAlert: false,
      alertMsg: "",
      openImageModal: false,
      modalImage: "",
      status: "",
    };
  }

  componentDidMount() {
    const columns = [
      { field: "id", headerName: "UID", width: 90, hide: true },
      {
        field: "bannerImg",
        headerName: "BANNER IMAGE",
        width: 350,
        renderCell: (params) => {
          let imageUrl = params.row.bannerImg.includes('agsisraf.com/api/') ? `https://${params.row.bannerImg}` : params.row.bannerImg.replace('20.233.41.167:8080/', 'https://agsisraf.com/api/')
          return (
          <a
            className="imageLink"
            onClick={() =>
              this.setState({
                openImageModal: true,
                modalImage: params?.row?.bannerImg
                  ? imageUrl
                  : "",
              })
            }
          >
            Open Image
            <div className="launchIcon">
              <Launch fontSize="small" />
            </div>
          </a>
        )},
      },
      {
        field: "bannerLoc",
        headerName: "BANNER LOCATION",
        width: 350,
      },
      {
        field: "status",
        headerName: "STATUS",
        width: 350,
        renderCell: (params) => {
          let checkedVal = params?.row?.status ? params.row.status : false;
          const handleSwitch = async (e, id) => {
            checkedVal = e;
            let payload = {
              banner_id: id,
              status: e,
            };
            const jwt = localStorage.getItem("authUser")
              ? JSON.parse(localStorage.getItem("authUser")).jwt
              : "";
            const headers = {
              auth: jwt,
            };
            await updateStatus(payload, headers)
              .then((res) => {
                const data = res?.data;
                this.getRowData();
                this.setState({
                  showAlert: true,
                  alertMsg: data.message,
                  severity: "success",
                });
              })
              .catch((e) => {
                console.log(e);
                this.setState({
                  showAlert: true,
                  alertMsg: "Couldn't able to update status !",
                  severity: "warning",
                });
              });
          };
          return (
            <InputSwitch
              className="primeSwitch"
              checked={checkedVal}
              onChange={(e) => handleSwitch(e.value, params.row.id)}
            />
          );
        },
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 200,

        renderCell: (params) => {
          const handleDeleteClick = (e) => {
            this.setState({ showDeleteModal: true, removeId: params.row.id });
          };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={handleDeleteClick}
                  className="delete"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];
    this.getRowData();
    this.setState({ columnData: columns });
  }

  getRowData = () => {
    let rowData = [];
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    getBanner(headers)
      .then((res) => {
        const data = res?.data?.data;
        rowData = data?.length
          ? data.map((x) => {
              return {
                id: x.banner_id,
                bannerImg: x.image,
                bannerLoc: x.section,
                status: x.status,
              };
            })
          : [];
        this.setState({ rowData });
      })
      .catch((e) => console.log(e));
  };

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ showAlert: false, showModalAlert: false });
    }, 10000);
  }

  addBanner = () => {
    this.setState({ showAddModal: true });
  };

  deleteBannerData = () => {
    this.setState({ showDeleteModal: false });
    const {removeId} = this.state;
    let data = {
      "banner_id":  removeId
    }
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    deleteBanner(data, headers)
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose("delete");
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "error",
        });
      })
      .catch((e) => console.log(e));
  };

  handleClose = (type) => {
    if (type === "add") this.setState({ showAddModal: false });
    else if (type === "delete") this.setState({ showDeleteModal: false });
    else if (type === "image") this.setState({ openImageModal: false });
  };

  handleTextChange = async (e) => {
    let image = e.target.files[0];
    let imageValue = e.target.value;
    this.setState({ bannerImage: imageValue });

    const formData = new FormData();
    formData.append('image', image);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    console.log("FORM DATA ------", image)
    await uploadImage(formData, headers)
      .then((r) => {
        let dataimage = r?.data?.file ? r.data.file : "";
        this.setState({ dataImage: dataimage });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ dataImage: "" });
      });
  };

  handleRadioChange = (e) => {
    let selectedVal = e.target.value;
    this.setState({ bannerLocation: selectedVal });
  };

  handleSubmit = () => {
    const { bannerLocation, dataImage } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    let payload;
    payload = {
      image: dataImage,
      section: bannerLocation,
    };
    addBanner(payload, headers)
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose("add");
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "success",
          bannerImage: "",
          bannerLocation: "",
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          showModalAlert: true,
          modalAlertMsg:
            "Couldn't able to save image, Please try again later !",
          severity: "error",
          bannerImage: "",
          bannerLocation: "",
        });
      });
  };

  render() {
    const {
      rowData,
      columnData,
      showAddModal,
      showDeleteModal,
      alertMsg,
      showAlert,
      severity,
      bannerImage,
      bannerLocation,
      showModalAlert,
      modalAlertMsg,
      modalImage,
      openImageModal,
    } = this.state;
    return (
      <div className="banner">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
          >
            {alertMsg}
          </Alert>
        ) : null}
        <div className="topSection">
          <h3>App Banner</h3>
          <div className="rightSide">
            <Button
              variant="contained"
              className="addBtn"
              onClick={this.addBanner}
            >
              ADD
            </Button>
          </div>
        </div>
        <Table columns={columnData} rows={rowData} />

        {/* Add Modal */}
        {showAddModal ? (
          <Modal
            open={showAddModal}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-banner"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              {showModalAlert ? (
                <Alert
                  variant="outlined"
                  severity={severity}
                  onClose={() => this.setState({ showModalAlert: false })}
                >
                  {modalAlertMsg}
                </Alert>
              ) : null}
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Banner Image
                </InputLabel>
                <input
                  accept="image/*"
                  capture="camcorder"
                  style={{
                    width: 400,
                    height: 38,
                    marginBottom: 10,
                  }}
                  id="outlined-basic"
                  value={bannerImage}
                  onChange={this.handleTextChange}
                  type="file"
                />
                <InputLabel id="demo-simple-select-label">
                  Banner Location
                </InputLabel>
                <div className="radioBtn">
                  <div className="homeRadio">
                    <Radio
                      checked={bannerLocation === "Home"}
                      onChange={this.handleRadioChange}
                      value={"Home"}
                      name="Home Screen"
                    />
                    <p>Home Screen</p>
                  </div>
                  <div className="surveyRadio">
                    <Radio
                      checked={bannerLocation === "Survey"}
                      onChange={this.handleRadioChange}
                      value={"Survey"}
                      name="Survey Screen"
                    />
                    <p>Survey Screen</p>
                  </div>
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit()}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Image Modal */}
        {openImageModal ? (
          <Modal
            open={openImageModal}
            onClose={() => this.handleClose("image")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-image"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("image")}
              />
              <img src={modalImage} height={500} width={1200} />
            </Box>
          </Modal>
        ) : null}

        {/* Delete Modal */}
        {showDeleteModal ? (
          <Modal
            open={showDeleteModal}
            onClose={() => this.handleClose("delete")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="block-modal-banner"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("delete")}
              />
              <Info className="infoIcon" />
              <h4>Are you Sure</h4>
              <p>Wants to Delete ?</p>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  className=""
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className=""
                  onClick={this.deleteBannerData}
                >
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Banner);
