import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import {
  getSurveyBanner,
  getSurveyQuestions,
  submitSurveyFeedback,
} from "../api";
import LOGO from "../../assets/images/LOGO.png";
import greenHappy from "../../assets/images/Green-Happy.png";
import yellowHappy from "../../assets/images/yellow-happy.png";
import yellowNeutral from "../../assets/images/yellow-neutral.png";
import orangeSad from "../../assets/images/Orange-Sad.png";
import redSad from "../../assets/images/Red-Sad.png";
import greenHappyTick from "../../assets/images/Green-happy-tick.png";
import yellowHappyTick from "../../assets/images/yellow-happy-tick.png";
import yellowNeutralTick from "../../assets/images/yellow-neutral-tick.png";
import orangeSadTick from "../../assets/images/orange-sad-tick.png";
import redSadTick from "../../assets/images/red-sad-tick.png";
import "./surveyFeedback.scss";

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";
const headers = {
  auth: jwt,
};
class SurveyQuestionsListing extends React.Component {
  constructor(props) {
    document.title = "Questions";
    super(props);
    this.state = {
      projectId: "",
      areaId: "",
      questionData: [],
      bannerData: [],
      submittedSurveyData: [],
      tentative_user_id: `USER_${Math.floor(
        Math.random() * (999 - 100 + 1) + 100
      )}`,
    };
  }

  componentDidMount() {
    const paramsArr = window.location.pathname
      ? window.location.pathname.split("/")
      : [];
    const projectId = paramsArr?.length ? paramsArr[2] : "";
    const areaId = paramsArr?.length ? paramsArr[3] : "";
    this.setState(
      {
        projectId,
        areaId,
      },
      () => {
        let params = { projectId, areaId };
        getSurveyQuestions(params, headers)
          .then((r) => {
            let data = r?.data?.data;
            this.setState({ questionData: data, submittedSurveyData: data });
          })
          .catch((e) => console.log("SURVEY QUESTIONS ERROR", e));
        getSurveyBanner(headers)
          .then((r) => {
            let data = r?.data?.data;
            this.setState({ bannerData: data });
          })
          .catch((e) => console.log("BANNER ERROR", e));
      }
    );
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ innerMessage: "", severity: "" });
    }, 20000);
  }

  productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          <img
            src={`https://${product.image}`}
            alt={"banner image"}
            className="w-6 shadow-2 bannerImage"
          />
        </div>
      </div>
    );
  };

  setRating = (rate, list) => {
    const { submittedSurveyData } = this.state;
    submittedSurveyData.map((x) => {
      if (x.survey_question_id === list.survey_question_id) {
        x.rating = rate;
      }
    });
    this.setState({ submittedSurveyData: submittedSurveyData });
  };

  getCurrentDate() {
    const currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
  }

  getCurrentTime() {
    const currentTime = new Date();

    const hours = currentTime.getHours().toString().padStart(2, "0");
    const minutes = currentTime.getMinutes().toString().padStart(2, "0");
    const seconds = currentTime.getSeconds().toString().padStart(2, "0");

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  }

  submitSurvey = () => {
    this.setState({ feedbackPageLoad: false });
    let { submittedSurveyData, tentative_user_id } = this.state;
    let collected_date = this.getCurrentDate();
    let collected_time = this.getCurrentTime();
    submittedSurveyData.map((list) => {
      list.tentative_user_id = tentative_user_id;
      list.collected_date = collected_date;
      list.collected_time = collected_time;
    });
    let payload = {
      surveryRecord: submittedSurveyData,
    };
    submitSurveyFeedback(payload, headers)
      .then((r) => {
        let data = r?.data?.message;
        let critical = r?.data?.error ? "error" : "success";
        this.setState(
          {
            innerMessage: data,
            severity: critical,
          },
          () => {
            if (
              submittedSurveyData?.length &&
              submittedSurveyData?.some((x) => x.rating === "worst")
            ) {
              this.setState({ feedbackPageLoad: true });
            }
          }
        );
      })
      .catch((e) => console.log("ERROR >>>>>>>>", e));
  };

  render() {
    const {
      bannerData,
      questionData,
      innerMessage,
      severity,
      feedbackPageLoad,
      tentative_user_id,
    } = this.state;
    if (feedbackPageLoad) {
      return <Navigate replace to={`/survey-feedback/${tentative_user_id}`} />;
    } else {
      return (
        <div className="surveyQuestions">
          <div className="topSection">
            <img src={LOGO} className="logoIcon" />
            <h3>Survey Questions</h3>
          </div>
          {bannerData ? (
            <div className="banner">
              <Carousel
                value={bannerData}
                numScroll={3}
                itemTemplate={this.productTemplate}
              />
            </div>
          ) : null}
          <div className="questionSection">
            {innerMessage ? (
              <Message
                severity={severity}
                text={innerMessage}
                style={{ marginBottom: 20, width: "100%" }}
              />
            ) : null}
            {questionData?.length
              ? questionData.map((list) => {
                  return (
                    <div className="questionSurvey">
                      <h5>{list.question}</h5>
                      <div className="smileyList">
                        {list.rating !== "excellent" ? (
                          <img
                            src={greenHappy}
                            onClick={() => this.setRating("excellent", list)}
                          />
                        ) : (
                          <img
                            src={greenHappyTick}
                            onClick={() => this.setRating("", list)}
                          />
                        )}
                        {list.rating !== "good" ? (
                          <img
                            src={yellowHappy}
                            onClick={() => this.setRating("good", list)}
                          />
                        ) : (
                          <img
                            src={yellowHappyTick}
                            onClick={() => this.setRating("", list)}
                          />
                        )}
                        {list.rating !== "neutral" ? (
                          <img
                            src={yellowNeutral}
                            onClick={() => this.setRating("neutral", list)}
                          />
                        ) : (
                          <img
                            src={yellowNeutralTick}
                            onClick={() => this.setRating("", list)}
                          />
                        )}
                        {list.rating !== "bad" ? (
                          <img
                            src={orangeSad}
                            onClick={() => this.setRating("bad", list)}
                          />
                        ) : (
                          <img
                            src={orangeSadTick}
                            onClick={() => this.setRating("", list)}
                          />
                        )}
                        {list.rating !== "worst" ? (
                          <img
                            src={redSad}
                            onClick={() => this.setRating("worst", list)}
                          />
                        ) : (
                          <img
                            src={redSadTick}
                            onClick={() => this.setRating("", list)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
            {questionData?.length ? (
              <div className="submitBtn">
                <Button onClick={this.submitSurvey}>Submit</Button>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(SurveyQuestionsListing);
