import UserRolesIcon from "../assets/images/user_icon.png";
import ReportsIcon from "../assets/images/reports_icon.png";
import {
  SupervisorAccount,
  Dashboard,
  Notifications,
  Task,
  Person,
  DataUsage,
  Help,
  ViewCarousel
} from "@mui/icons-material";

const menuList = () => {
  let value = [
    {
      name: "Dashboard",
      menu_id: "dashboard",
      href: "",
      key: 1,
      subMenu: [
        {
          name: "Waste Management",
          menu_id: "wasteManagement",
          href: "/dashboard/dashboardWaste",
          key: 2.2,
        },
        {
          name: "Survey Management",
          menu_id: "surveyManagement",
          href: "/dashboard/dashboardSurvey",
          key: 2.2,
        },
      ],
      icon: <Dashboard />,
    },
    {
      name: "Admin Users",
      href: "",
      key: 2,
      subMenu: [
        // {
        //   name: "Menu permission",
        //   menu_id: "menupermission",
        //   href: "/admin_user/menu_permission",
        //   key: 2.1,
        // },
        {
          name: "Roles",
          menu_id: "role",
          href: "/admin_user/user_roles",
          key: 2.2,
        },
        {
          name: "Users",
          menu_id: "adminusers",
          href: "/admin_user/users",
          key: 2.3,
        },
      ],
      icon: <img src={UserRolesIcon} />,
    },
    {
      name: "Catering Manager",
      menu_id: "supervisor",
      href: "/supervisor",
      key: 3,
      subMenu: [],
      icon: <SupervisorAccount />,
    },
    {
      name: "Projects",
      menu_id: "projects",
      href: "/projects",
      key: 4,
      subMenu: [],
      icon: <Task />,
    },
    {
      name: "Master Data",
      href: "",
      key: 5,
      subMenu: [
        {
          name: "Time Slots",
          menu_id: "timeslot",
          href: "/master_data/time_slots",
          key: 5.1,
        },
        {
          name: "Waste Type",
          menu_id: "wastetype",
          href: "/master_data/waste_type",
          key: 5.2,
        },
        {
          name: "Division",
          menu_id: "division",
          href: "/master_data/division",
          key: 5.3,
        },
        {
          name: "Location",
          menu_id: "location",
          href: "/master_data/location",
          key: 5.4,
        },
        {
          name: "Region",
          menu_id: "region",
          href: "/master_data/region",
          key: 5.5,
        },
        {
          name: "Service Area",
          menu_id: "servicearea",
          href: "/master_data/service_area",
          key: 5.5,
        },
        {
          name: "Project Type",
          menu_id: "projecttype",
          href: "/master_data/project_type",
          key: 5.6,
        },
        {
          name: "Project Code",
          menu_id: "projectcode",
          href: "/master_data/project_code",
          key: 5.7,
        },
      ],
      icon: <Person />,
    },
    {
      name: "Waste Records",
      menu_id: "wasterecord",
      href: "/waste_records",
      key: 6,
      subMenu: [],
      icon: <DataUsage />,
    },
    {
      name: "Survey",
      href: "",
      key: 7,
      subMenu: [
        {
          name: "Questions",
          menu_id: "questions",
          href: "/master_data/survey_questions",
          key: 7.1,
        },
        {
          name: "Survey Records",
          menu_id: "surveyrecord",
          href: "/master_data/survey_records",
          key: 7.2,
        },
      ],
      icon: <Help />,
    },
    {
      name: "Notifications",
      href: "/notification",
      key: 8,
      subMenu: [],
      icon: <Notifications />,
    },
    {
      name: "Banner",
      menu_id: "banner",
      href: "/banner",
      key: 10,
      subMenu: [],
      icon: <ViewCarousel />,
    },
  ];
  return value;
};

export default menuList();
