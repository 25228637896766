import { CREATE_PROJECT, CREATE_PROJECT_ALERT, EDIT_PROJECT, GET_LOCATION, GET_MANAGER, GET_PROJECTS, GET_PROJECT_CODE, GET_PROJECT_TYPE, GET_REGION, GET_SUPERVISOR } from "../actionTypes/masterData";

const initialState = {
    projectCode: [],
    supervisor: [],
    region: [],
    location: [],
    projectType: [],
    manager: [],
    allProjects: []
};

const masterData = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_CODE:
            return { ...state, projectCode: action.payload };
        case GET_SUPERVISOR:
            return { ...state, supervisor: action.payload };
        case GET_REGION:
            return { ...state, region: action.payload };
        case GET_LOCATION:
            return { ...state, location: action.payload };
        case GET_PROJECT_TYPE:
            return { ...state, projectType: action.payload };
        case GET_MANAGER:
            return { ...state, manager: action.payload };
        case GET_PROJECTS:
            return { ...state, allProjects: action.payload };
        case CREATE_PROJECT:
            return { ...state, createProject: action.payload };
        case EDIT_PROJECT:
            return { ...state, editProject: action.payload };
        case CREATE_PROJECT_ALERT:
            return { ...state, createProjectAlert: action.payload };
        default:
            return state;
    }
};

export default masterData;