import { loginApi, logoutApi } from "../../pages/api";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_LOADING,
  LOGOUT_LOADING,
} from "../constants";

export const loginUser = (data, navigate) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_LOADING, payload: true });
    loginApi(data)
      .then((res) => {
        let { data, error, message } = res?.data;
        if (!error) {
          localStorage.setItem("authUser", JSON.stringify(data)); //done after api success
          dispatch({ type: LOGIN_USER, payload: data });
          dispatch({ type: LOGIN_LOADING, payload: false });
          dispatch({ type: API_ERROR, payload: "" });
          navigate("/dashboard/dashboardWaste");
        } else {
          dispatch({ type: LOGIN_ERROR, payload: message });
        }
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const logoutUser = (data) => {
  return (dispatch) => {
    logoutApi(data)
      .then((res) => {
        dispatch({ type: LOGOUT_USER_SUCCESS });
        dispatch({ type: LOGOUT_USER });
        localStorage.removeItem("authUser");
        dispatch({ type: LOGOUT_LOADING, payload: true });
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const apiError = (error, dispatch) => {
  let data = error?.response?.data?.message;
  dispatch({
    type: API_ERROR,
    payload: data,
  });
};
