import React from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { getSurveryRecord } from "../../redux/actions/projectDetails";
import ExportIcon from "../../assets/images/export.png";

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

class SurveyRecord extends React.Component {
  constructor(props) {
    super(props);
    this.dataTable = React.createRef();
    this.state = {
      surveryRecord: [],
    };
  }

  componentDidMount = async () => {
    const {
      projDetails: { project_code, project_id },
      surveryRecord,
    } = this.props.projDetails;
    this.setState(
      {
        project_code: project_code,
        project_id: project_id,
        surveryRecord,
      },
      () => {
        this.props.getSurveryRecord(this.props.project_id);
      }
    );
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.projDetails !== this.props.projDetails) {
      const {
        projDetails: { project_code, project_id },
        surveryRecord,
      } = this.props.projDetails;
      this.setState({
        project_code: project_code,
        project_id: project_id,
        surveryRecord,
      });
    }
  };

  imageBodyTemplate = (record) => {
    let { images } = record;
    return images.map((image) => {
      return (
        <img
          src={`${image}`}
          alt={image}
          className="w-6rem shadow-2 border-round"
        />
      );
    });
  };

  dateTimeTemplate = (record, type) => {
    let { collected_date, created_date } = record;
    let cDate = created_date?.split("T")[0];
    let date = collected_date?.split("T")[0];
    return <p>{type === "created_date" ? cDate : date}</p>;
  };

  commentTemplate = (data) => {
    const {commend} = data;
    if(commend === null) return <p>NA</p>
    else return <p>{commend}</p>
  }

  exportCSV = () => {
    this.dataTable.current.exportCSV();
  };

  render() {
    return (
      <div className="project-master">
        <div className="card">
          <Button onClick={this.exportCSV} className="exportBtn">
            <img src={ExportIcon} className="downloadIcon" />
          </Button>
          <DataTable
            value={this.state.surveryRecord}
            className="mt-4 project-treetable surveyRecordTable"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            exportFilename="survey data"
            ref={this.dataTable}
          >
            <Column
              sortable
              field="project_code"
              header="Project"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="in_area_code"
              header="Area"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="tentative_user_id"
              header="User"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="question"
              header="Question"
              style={{ width: "20%" }}
            ></Column>
            <Column
              sortable
              field="rating"
              header="Rating"
              style={{ width: "10%" }}
            ></Column>
            <Column
              sortable
              field="commend"
              header="Comments"
              body={(rec) => this.commentTemplate(rec)}
              style={{ width: "15%" }}
            ></Column>
            <Column
              sortable
              field="collected_time"
              header="Collection time"
              style={{ width: "15%" }}
            ></Column>
            <Column
              sortable
              field="collected_date"
              header="Collection date"
              body={(rec) => this.dateTimeTemplate(rec, "date")}
              style={{ width: "15%" }}
            ></Column>
            <Column
              sortable
              field="created_date"
              header="Created date"
              body={(rec) => this.dateTimeTemplate(rec, "created_date")}
              style={{ width: "15%" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    projDetails: store.projectDetails,
  };
};

const mapDispatchToProps = {
  getSurveryRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyRecord);
