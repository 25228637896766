import React from "react";
import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import LOGO from '../../../assets/images/LOGO.png';
import LanguageDropdown from '../../CommonForBoth/TopbarDropdown/LanguageDropdown'
import NotificationDropdown from '../../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from "../../CommonForBoth/TopbarDropdown/ProfileMenu";

const settings = [
    {
        name: 'Profile',
        link: '/profile'
    }, {
        name: 'Logout',
        link: '/logout'
    }
]

function Header() {
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <Box>
            <AppBar position="static" className="header">
                <Toolbar className="header-toolbar">
                    <div className="navbar-brand-box">
                        <Link to="/" className="logo">
                            <img src={LOGO} alt="" height={60} width={120} />
                        </Link>
                    </div>
                    <div className='rightside'>
                        <LanguageDropdown />
                        <NotificationDropdown />
                        <ProfileMenu />
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header