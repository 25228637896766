import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";

const settings = [
  {
    name: "Profile",
    link: "/profile",
  },
  {
    name: "Logout",
    link: "/logout",
  },
];

function ProfileMenu(props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // useEffect(() => {
  //   let jwt = props.loginDetails?.loginData
  //     ? props.loginDetails.loginData.jwt
  //     : "";
  //   settings.map((x) => {
  //     if (x.name === "Logout" && jwt !== '') x.redirect = true;
  //     else if (x.name === "Logout" && jwt === '') x.redirect = false;
  //   });
  // }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar sx={{ width: 27, height: 27, marginTop: "2px" }}>
            <PersonIcon />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <Link to={setting.link}>
            <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting.name}</Typography>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </Box>
  );
}

export default ProfileMenu;