import React from "react";
import Table from "../../components/Common/Table";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  MenuItem,
  TextField,
  Modal,
  Checkbox,
  Alert,
} from "@mui/material";
import { Edit, Close, LockPerson } from "@mui/icons-material";
import "./users.scss";
import { connect } from "react-redux";
import {
  apiError,
  createUser,
  fetchPermissions,
  updatePermissions,
  updateUser,
} from "../../redux/actions/roleMenu";
import { getRoles } from "../api";

let permitRows = [
  {
    id: "1",
    permission: "Dashboard",
    menu_id: "dashboard",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "2",
    permission: "Users",
    menu_id: "adminusers",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "3",
    permission: "Roles",
    menu_id: "role",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "4",
    permission: "Supervisor",
    menu_id: "supervisor",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "5",
    permission: "Projects",
    menu_id: "projects",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "6",
    permission: "Time Slot",
    menu_id: "timeslot",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "7",
    permission: "Waste Type",
    menu_id: "wastetype",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "8",
    permission: "Division",
    menu_id: "division",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "9",
    permission: "Location",
    menu_id: "location",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "10",
    permission: "Service Area",
    menu_id: "servicearea",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "11",
    permission: "Project Code",
    menu_id: "projectcode",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "12",
    permission: "Project Type",
    menu_id: "projecttype",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "13",
    permission: "Region",
    menu_id: "region",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "14",
    permission: "Question",
    menu_id: "questions",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "15",
    permission: "Survey Record",
    menu_id: "surveyrecord",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "16",
    permission: "Waste Record",
    menu_id: "wasterecord",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "17",
    permission: "Notification",
    menu_id: "notification",
    permission_id: "",
    read: false,
    write: false,
  },
  {
    id: "18",
    permission: "Report",
    menu_id: "reports",
    permission_id: "",
    read: false,
    write: false,
  },
];

class UserRoles extends React.Component {
  constructor(props) {
    document.title = "User Roles";
    super(props);
    this.state = {
      showAddModal: false,
      showEditModal: false,
      showEditPermitModal: false,
      rowData: [],
      columnData: [],
      permitRows: permitRows,
      role: "",
      editedRole: "",
      permissions: {},
      userPermissions: [],
      showAlert: false,
      showModalAlert: false,
      alertMsg: "",
    };
  }

  componentDidMount() {
    const columns = [
      { field: "id", headerName: "UID", width: 100, hide: true },
      {
        field: "role",
        headerName: "ROLE",
        width: 400,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 600,

        renderCell: (params) => {
          const handleEditClick = (e) => {
            this.setState({ showEditModal: true, editedRole: params.row }, () =>
              this.setEditedFields()
            );
          };
          const handleEditPermitClick = (e) => {
            this.setState(
              { showEditPermitModal: true, permitData: params.row },
              async () => await this.fetchPermissions()
            );
          };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit Role">
                <IconButton
                  onClick={handleEditClick}
                  color="success"
                  className="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Edit Permission">
                <IconButton
                  onClick={handleEditPermitClick}
                  className="editpermit"
                >
                  <LockPerson />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];
    this.getRowData();
    this.setState({ columnData: columns });
  }

  fetchPermissions = async () => {
    const { permitData } = this.state;
    let params = {
      roleId: permitData.id,
    };
    const loginData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const jwt = loginData?.jwt ? loginData.jwt : "";
    const headers = {
      auth: jwt,
    };
    await this.props.fetchUserPermissions(params, headers);
  };

  componentDidUpdate(prevProps) {
    setTimeout(() => {
      this.setState({ showAlert: false, showModalAlert: false });
    }, 10000);
    if (prevProps.menuList !== this.props.menuList) {
      const permissions = this.props.menuList.userPermissions;
      this.setState({ userPermissions: permissions }, () => {
        this.updateRowData();
      });
    }
  }

  setEditedFields = () => {
    const { editedRole } = this.state;
    this.setState({
      role: editedRole.role,
    });
  };

  getRowData = () => {
    let rowData = [];
    const loginData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const jwt = loginData?.jwt ? loginData.jwt : "";
    const headers = {
      auth: jwt,
    };
    getRoles({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        rowData = data?.length
          ? data.map((x) => {
              return {
                id: x.role_id,
                role: x.role_name,
              };
            })
          : [];
        this.setState({ rowData });
      })
      .catch((e) => console.log(e));
  };

  addRoles = () => {
    this.setState({
      showAddModal: true,
    });
  };

  handleTextChange = (e) => {
    this.setState({ role: e.target.value });
  };
  handleClose = (type) => {
    if (type === "edit")
      this.setState({
        showEditModal: false,
        role: "",
        editedRole: {},
      });
    else if (type === "add")
      this.setState({
        showAddModal: false,
        role: "",
        editedRole: {},
      });
    else if (type === "editPermit")
      this.setState({
        showEditPermitModal: false,
      });
  };
  handleReadSwitch = (e, type) => {
    let data = { ...this.state.permissions };
    data[type] = {
      ...data[type],
      read: e.target.checked ? e.target.checked : false,
    };
    this.setState({ permissions: data });
  };
  handleWriteSwitch = (e, type) => {
    let data = { ...this.state.permissions };
    data[type] = {
      ...data[type],
      write: e.target.checked ? e.target.checked : false,
    };
    this.setState({ permissions: data });
  };

  handleSave = async () => {
    const { role, permissions } = this.state;
    const loginData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const jwt = loginData?.jwt ? loginData.jwt : "";
    const headers = {
      auth: jwt,
    };
    if (role) {
      let payload = {
        roleName: role,
        permission: [
          {
            menu_id: "adminusers",
            menu_name: "Users",
            read: permissions?.["Users"]?.read
              ? permissions["Users"].read
              : false,
            write: permissions?.["Users"]?.write
              ? permissions["Users"].write
              : false,
          },
          {
            menu_id: "dashboard",
            menu_name: "Dashboard",
            read: permissions?.["Dashboard"]?.read
              ? permissions["Dashboard"].read
              : false,
            write: permissions?.["Dashboard"]?.write
              ? permissions["Dashboard"].write
              : false,
          },
          {
            menu_id: "division",
            menu_name: "Division",
            read: permissions?.["Division"]?.read
              ? permissions["Division"].read
              : false,
            write: permissions?.["Division"]?.write
              ? permissions["Division"].write
              : false,
          },
          {
            menu_id: "location",
            menu_name: "Location",
            read: permissions?.["Location"]?.read
              ? permissions["Location"].read
              : false,
            write: permissions?.["Location"]?.write
              ? permissions["Location"].write
              : false,
          },
          {
            menu_id: "menupermission",
            menu_name: "Menu Permission",
            read: permissions?.["Menu Permission"]?.read
              ? permissions["Menu Permission"].read
              : false,
            write: permissions?.["Menu Permission"]?.write
              ? permissions["Menu Permission"].write
              : false,
          },
          {
            menu_id: "notification",
            menu_name: "Notification",
            read: permissions?.["Notification"]?.read
              ? permissions["Notification"].read
              : false,
            write: permissions?.["Notification"]?.write
              ? permissions["Notification"].write
              : false,
          },
          {
            menu_id: "projectcode",
            menu_name: "Project Code",
            read: permissions?.["Project Code"]?.read
              ? permissions["Project Code"].read
              : false,
            write: permissions?.["Project Code"]?.write
              ? permissions["Project Code"].write
              : false,
          },
          {
            menu_id: "projects",
            menu_name: "Projects",
            read: permissions?.["Projects"]?.read
              ? permissions["Projects"].read
              : false,
            write: permissions?.["Projects"]?.write
              ? permissions["Projects"].write
              : false,
          },
          {
            menu_id: "projecttype",
            menu_name: "Project Type",
            read: permissions?.["Project Type"]?.read
              ? permissions["Project Type"].read
              : false,
            write: permissions?.["Project Type"]?.write
              ? permissions["Project Type"].write
              : false,
          },
          {
            menu_id: "questions",
            menu_name: "Question",
            read: permissions?.["Question"]?.read
              ? permissions["Question"].read
              : false,
            write: permissions?.["Question"]?.write
              ? permissions["Question"].write
              : false,
          },
          {
            menu_id: "region",
            menu_name: "Region",
            read: permissions?.["Region"]?.read
              ? permissions["Region"].read
              : false,
            write: permissions?.["Region"]?.write
              ? permissions["Region"].write
              : false,
          },
          {
            menu_id: "reports",
            menu_name: "Report",
            read: permissions?.["Report"]?.read
              ? permissions["Report"].read
              : false,
            write: permissions?.["Report"]?.write
              ? permissions["Report"].write
              : false,
          },
          {
            menu_id: "role",
            menu_name: "Roles",
            read: permissions?.["Roles"]?.read
              ? permissions["Roles"].read
              : false,
            write: permissions?.["Roles"]?.write
              ? permissions["Roles"].write
              : false,
          },
          {
            menu_id: "servicearea",
            menu_name: "Service Area",
            read: permissions?.["Service Area"]?.read
              ? permissions["Service Area"].read
              : false,
            write: permissions?.["Service Area"]?.write
              ? permissions["Service Area"].write
              : false,
          },
          {
            menu_id: "supervisor",
            menu_name: "Supervisor",
            read: permissions?.["Supervisor"]?.read
              ? permissions["Supervisor"].read
              : false,
            write: permissions?.["Supervisor"]?.write
              ? permissions["Supervisor"].write
              : false,
          },
          {
            menu_id: "surveyrecord",
            menu_name: "Survey Record",
            read: permissions?.["Survey Record"]?.read
              ? permissions["Survey Record"].read
              : false,
            write: permissions?.["Survey Record"]?.write
              ? permissions["Survey Record"].write
              : false,
          },
          {
            menu_id: "timeslot",
            menu_name: "Time Slot",
            read: permissions?.["Time Slot"]?.read
              ? permissions["Time Slot"].read
              : false,
            write: permissions?.["Time Slot"]?.write
              ? permissions["Time Slot"].write
              : false,
          },
          {
            menu_id: "wasterecord",
            menu_name: "Waste Record",
            read: permissions?.["Waste Record"]?.read
              ? permissions["Waste Record"].read
              : false,
            write: permissions?.["Waste Record"]?.write
              ? permissions["Waste Record"].write
              : false,
          },
          {
            menu_id: "wastetype",
            menu_name: "Waste Type",
            read: permissions?.["Waste Type"]?.read
              ? permissions["Waste Type"].read
              : false,
            write: permissions?.["Waste Type"]?.write
              ? permissions["Waste Type"].write
              : false,
          },
        ],
      };
      await this.props.saveUserRole(payload, headers);
      this.setState(
        {
          showAddModal: false,
          permissions: {},
          role: "",
          showAlert: true,
          alertMsg: "Data Added",
          severity: "success",
        },
        () => this.getRowData()
      );
    } else {
      alert("Please Enter Role");
    }
  };

  handleSubmit = async () => {
    const { role, editedRole } = this.state;
    const loginData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const jwt = loginData?.jwt ? loginData.jwt : "";
    const headers = {
      auth: jwt,
    };
    if (role) {
      let payload = {
        role_id: editedRole.id,
        role_name: role,
      };
      await this.props.updateUserRole(payload, headers);
      this.setState(
        {
          showEditModal: false,
          role: "",
          showAlert: true,
          alertMsg: "Data Updated",
          severity: "success",
        },
        () => this.getRowData()
      );
    } else {
      alert("Please Enter Role");
    }
  };

  updateRowData = () => {
    const { userPermissions, permitRows } = this.state;
    let rows = [...permitRows];
    rows = rows.map((item) => {
      const matchedObject = userPermissions.find(
        (obj) => obj.menu_id === item.menu_id
      );
      return { ...item, ...matchedObject };
    });
    this.setState({ permitRows: rows });
  };

  handleCheckbox = (e, permission, type) => {
    let rows = [...this.state.permitRows];
    rows = rows.map((x) =>
      x.permission === permission && type === "Read"
        ? { ...x, read: e.target.checked }
        : x.permission === permission && type === "Write"
        ? { ...x, write: e.target.checked }
        : { ...x }
    );
    this.setState(
      {
        permitRows: rows,
      },
      async () => {
        const loginData = localStorage.getItem("authUser")
          ? JSON.parse(localStorage.getItem("authUser"))
          : {};
        const jwt = loginData?.jwt ? loginData.jwt : "";
        const headers = {
          auth: jwt,
        };
        let obj = rows.find((x) => x.permission === permission);
        let payload = {
          permission_id: obj.permission_id,
          read: obj.read,
          write: obj.write,
        };
        await this.props.updateUserPermissions(payload, headers);
        this.setState({
          showModalAlert: true,
          modalAlertMsg: "Data Updated",
          modalSeverity: "success",
        });
      }
    );
  };

  render() {
    const {
      rowData,
      showAddModal,
      role,
      showEditModal,
      showEditPermitModal,
      permitRows,
      columnData,
      editedRole,
      alertMsg,
      showAlert,
      showModalAlert,
      modalAlertMsg,
      modalSeverity,
      severity,
    } = this.state;
    return (
      <div className="projects userRoles">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
          >
            {alertMsg}
          </Alert>
        ) : null}
        <div className="topSection">
          <h3>User Roles</h3>
          <div className="rightSide">
            <Button
              variant="contained"
              className="addBtn"
              onClick={this.addRoles}
            >
              ADD
            </Button>
          </div>
        </div>
        <Table columns={columnData} rows={rowData} />

        {/* Add Modal */}
        {showAddModal ? (
          <Modal
            open={showAddModal}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-role"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Type Role here..."
                  value={role}
                  onChange={(e) => this.handleTextChange(e)}
                />
                <h5>Role Permissions</h5>
                <div className="permitTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Permission</th>
                        <th>Read</th>
                        <th>Write</th>
                      </tr>
                    </thead>
                    <tbody>
                      {permitRows.map((row) => {
                        return (
                          <tr>
                            <td>{row.permission}</td>
                            <td>
                              <Checkbox
                                onChange={(e) =>
                                  this.handleReadSwitch(e, row.permission)
                                }
                                title="Read"
                              />
                            </td>
                            <td>
                              <Checkbox
                                onChange={(e) =>
                                  this.handleWriteSwitch(e, row.permission)
                                }
                                title="Write"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  color="error"
                  className="submitBtn"
                  onClick={this.handleSave}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Edit Modal */}
        {showEditModal ? (
          <Modal
            open={showEditModal}
            onClose={() => this.handleClose("edit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="edit-modal-role"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("edit")}
              />
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Type Role here..."
                  value={role}
                  onChange={(e) => this.handleTextChange(e)}
                />
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  color="error"
                  className="submitBtn"
                  onClick={this.handleSubmit}
                >
                  UPDATE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Edit Permission Modal */}
        {showEditPermitModal ? (
          <Modal
            open={showEditPermitModal}
            onClose={() => this.handleClose("editPermit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-role"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("editPermit")}
              />
              <div className="role-add">
                {showModalAlert ? (
                  <Alert
                    className="modalAlert"
                    variant="outlined"
                    severity={modalSeverity}
                    onClose={() => this.setState({ showModalAlert: false })}
                  >
                    {modalAlertMsg}
                  </Alert>
                ) : null}
                <h5>Edit User Permissions</h5>
                <div className="editPermitTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Permission</th>
                        <th>Read</th>
                        <th>Write</th>
                      </tr>
                    </thead>
                    <tbody>
                      {permitRows.map((row) => {
                        return (
                          <tr>
                            <td>{row.permission}</td>
                            <td>
                              <Checkbox
                                checked={row.read}
                                onChange={(e) =>
                                  this.handleCheckbox(e, row.permission, "Read")
                                }
                                title="Read"
                              />
                            </td>
                            <td>
                              <Checkbox
                                checked={row.write}
                                onChange={(e) =>
                                  this.handleCheckbox(
                                    e,
                                    row.permission,
                                    "Write"
                                  )
                                }
                                title="Write"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Box>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuList: state.Roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserRole: (params, headers) => dispatch(createUser(params, headers)),
    updateUserRole: (data, headers) => dispatch(updateUser(data, headers)),
    fetchUserPermissions: (params, headers) =>
      dispatch(fetchPermissions(params, headers)),
    updateUserPermissions: (data, headers) =>
      dispatch(updatePermissions(data, headers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
