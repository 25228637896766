import {
  createRole,
  getPermission,
  saveUser,
  updateEditUser,
  updateRole,
  updateUserPermissions,
} from "../../pages/api";
import menuList from "../../constant/menus";
import { MENU_LIST, API_ERROR, ROLE_PERMISSIONS, MENU_LIST_ACTIVE } from "../constants";

export const getMenuList = (params, headers) => {
  return (dispatch) => {
    getPermission(params, headers)
      .then((res) => {
        let { data } = res.data;
        for (let x of menuList) {
          if (x.subMenu?.length) {
            if (
              x.subMenu?.length &&
              x.subMenu.every((a) => a.display === "none")
            ) {
              x.display = "none";
            } else {
              for (let sub of x.subMenu) {
                for (let y of data) {
                  if (
                    // sub.menu_id !== y.menu_id ||
                    (sub.menu_id === y.menu_id && !y.read)
                  ) {
                    sub.display = "none";
                    break;
                  } else {
                    sub.display = "flex";
                  }
                }
              }
            }
          } else {
            for (let y of data) {
              if (
                // x.menu_id !== y.menu_id ||
                (x.menu_id === y.menu_id && !y.read)
              ) {
                x.display = "none";
                break;
              } else {
                x.display = "flex";
              }
            }
          }
        }
        dispatch({ type: MENU_LIST, payload: menuList });
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const fetchPermissions = (params, headers) => {
  return (dispatch) => {
    getPermission(params, headers)
      .then((res) => {
        let data = res && res.data && res.data.data;
        dispatch({ type: ROLE_PERMISSIONS, payload: data });
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const createUser = (payload, headers) => {
  return async (dispatch) => {
    await createRole(payload, headers)
      .then((r) => {
        let data = r && r.data && r.data.message;
        return data;
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const addUser = (payload, headers) => {
  return async (dispatch) => {
    await saveUser(payload, headers)
      .then((r) => {
        let data = r && r.data && r.data.message;
        return data;
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const editUser = (payload, headers) => {
  return async (dispatch) => {
    await updateEditUser(payload, headers)
      .then((r) => {
        let data = r && r.data && r.data.message;
        return data;
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const updateUser = (payload, headers) => {
  return async (dispatch) => {
    await updateRole(payload, headers)
      .then((r) => {
        let data = r && r.data && r.data.message;
        return data;
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const updatePermissions = (payload, headers) => {
  return async (dispatch) => {
    await updateUserPermissions(payload, headers)
      .then((r) => {
        let data = r && r.data && r.data.message;
        return data;
      })
      .catch((e) => {
        apiError(e, dispatch);
      });
  };
};

export const setMenuActiveKey = (data) => async (dispatch) => {
  dispatch({ type: MENU_LIST_ACTIVE, payload: data });
};

export const apiError = (error, dispatch) => {
  let data = error?.response?.data?.message;
  dispatch({
    type: API_ERROR,
    payload: data,
  });
};
