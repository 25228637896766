import axios from "axios";
// const baseURL = 'http://20.203.123.127:8080/api';
// const baseURL = 'http://20.233.41.167:8080';
const baseURL = 'https://www.agsisraf.com/api';

//Auth API
export const loginApi = data => axios.post(`${baseURL}/al-gosaibi/auth/loginSuperAdmin`, data);
export const logoutApi = data => axios.post(`${baseURL}/al-gosaibi/auth/logoutSuperAdmin`, data);

//Image Upload
export const uploadImage = (data, headers) => axios.post(`${baseURL}/upload`, data, {headers})

//Permission API
export const getPermission = (data, headers) => axios.get(`${baseURL}/al-gosaibi/admin-users/permission/get?roleId=${data.roleId}`, {headers});
export const updateUserPermissions = (data, headers) => axios.post(`${baseURL}/al-gosaibi/admin-users/permission/edit`, data, {headers});

//Dashboard-Waste
export const getDashboardWaste = (headers, regionId, projectId, startDate, endDate ) => axios.get(`${baseURL}/al-gosaibi/dashboard/get?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}&regionId=${regionId}`, {headers});
export const getDashboardProject = (regionId, headers) => axios.get(`${baseURL}/al-gosaibi/dashboard/project/get?regionId=${regionId}`, {headers});
export const getLMDashboardWaste = (headers, projectId, regionId) => axios.get(`${baseURL}/al-gosaibi/dashboard/get/lastmonth?projectId=${projectId}&regionId=${regionId}`, {headers});
export const getOverAllData = (headers) => axios.get(`${baseURL}/al-gosaibi/dashboard/get/overall`, {headers});
export const getLMDashboardGraph = (headers) => axios.get(`${baseURL}/al-gosaibi/dashboard/get/graph`, {headers});
export const getDefaultsData = (headers) => axios.get(`${baseURL}/al-gosaibi/dashboard/get/defaults`, {headers});
export const updateDefaults = (data, headers) => axios.post(`${baseURL}/al-gosaibi/dashboard/put/defaults`, data, {headers});



//User Roles
export const createRole = (data, headers) => axios.post(`${baseURL}/al-gosaibi/admin-users/role/create`, data, {headers});
export const getRoles = (data, headers) => axios.get(`${baseURL}/al-gosaibi/admin-users/role/get`, {headers});
export const updateRole = (data, headers) => axios.post(`${baseURL}/al-gosaibi/admin-users/role/edit`, data, {headers});

//Users List
export const getUsers = (data, headers) => axios.get(`${baseURL}/al-gosaibi/admin-users/user/get`, {headers});
export const saveUser = (data, headers) => axios.post(`${baseURL}/al-gosaibi/admin-users/user/create`, data, {headers});
export const updateEditUser = (data, headers) => axios.post(`${baseURL}/al-gosaibi/admin-users/user/edit`, data, {headers});

//Master Data API
//Session
export const getSession = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/timeslot/get?page&count`, { headers });
export const saveEditSession = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/timeslot/${type}`, data, { headers });
export const deleteSession = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/timeslot/remove`, data, { headers });

//Waste-Type
export const getWaste = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/waste_master/get?page&count`, { headers });
export const saveEditWaste = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/waste_master/${type}`, data, { headers });
export const deleteWaste = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/waste_master/remove`, data, { headers });

//Division
export const getDivision = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/division/get?page&count`, { headers });
export const saveEditDivision = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/division/${type}`, data, { headers });
export const deleteDivision = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/division/remove`, data, { headers });

//Location
export const getLocation = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/location/get?page&count`, { headers });
export const saveEditLocation = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/location/${type}`, data, { headers });
export const deleteLocation = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/location/remove`, data, { headers });

//Region
export const getRegion = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/region/get?page&count`, { headers });
export const saveEditRegion = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/region/${type}`, data, { headers });
export const deleteRegion = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/region/remove`, data, { headers });

//Service Area
export const getSA = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/service_area/get?page&count`, { headers });
export const saveEditSA = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/service_area/${type}`, data, { headers });
export const deleteSA = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/service_area/remove`, data, { headers });

//Project-Type
export const getPT = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/project_type/get?page&count`, { headers });
export const saveEditPT = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/project_type/${type}`, data, { headers });
export const deletePT = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/project_type/remove`, data, { headers });

//Project-Code
export const getPC = (data, headers) => axios.get(`${baseURL}/al-gosaibi/masterData/project_code/get?page&count`, { headers });
export const saveEditPC = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/project_code/${type}`, data, { headers });
export const deletePC = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/project_code/remove`, data, { headers });

//Survey-Questions
export const getSQ = (data, headers) => axios.get(`${baseURL}/al-gosaibi/survey/get`, { headers });
export const saveEditSQ = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/survey/${type}`, data, { headers });
export const getSQProjects = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/getProjectInArea?key=project`, { headers });
export const getSQArea = (projectCode, headers) => axios.get(`${baseURL}/al-gosaibi/project/getProjectInArea?key=area&project_id=${projectCode}`, { headers });
export const getSurveyRecWithFilter = (data, headers) => axios.get(`${baseURL}/al-gosaibi/survey/getSuerveyRecord?from_date=${data?.fromDate}&to_date=${data?.toDate}&project_id=${data?.projectId}&region_id=${data?.regionId}`, { headers });

//Supervisor
export const getSupervisor = (data, headers) => axios.get(`${baseURL}/al-gosaibi/supervisor/get?page&count`, { headers });
export const saveEditSupervisor = (type, data, headers) => axios.post(`${baseURL}/al-gosaibi/supervisor/${type}`, data, { headers });
export const deleteSupervisor = (data, headers) => axios.post(`${baseURL}/al-gosaibi/supervisor/remove`, data, { headers });

//Manager
export const getManager = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/getManager?key=manager`, { headers });

//Project 
export const createProject = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/create`, data, { headers });
export const editProject = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/edit`, data, { headers });
export const deleteProject = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/remove`, data, { headers });
export const getProjects = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/get`, { headers });

//Project Details
export const getProjectDetails = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/getAggrigateDetails?project_id=${data}`, { headers });
//Project details - Division
export const getProjectDivision = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/division/get?project_id=${data}&page&count`, { headers });
export const addProjectDivision = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/division/create`, data, { headers });
export const editProjectDivision = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/division/edit`, data, { headers });
export const deleteProjectDivision = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/division/remove`, data, { headers });
//Project details - Bin
export const getProjectBin = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/bin/get?project_id=${data}&page&count`, { headers });
export const addProjectBin = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/bin/create`, data, { headers });
export const editProjectBin = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/bin/edit`, data, { headers });
export const deleteProjectBin = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/bin/remove`, data, { headers });
//Project details - Timeslot
export const getProjectTS = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/timeslot/get?project_id=${data}&page&count`, { headers });
export const addProjectTS = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/timeslot/assign`, data, { headers });
export const editProjectTS = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/timeslot/edit`, data, { headers });
export const deleteProjectTS = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/timeslot/remove`, data, { headers });
//Project master waste and survey tabs
export const getGarbageData = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/getGarbageData?project_id=${data}&page&count`, { headers });
export const addGarbageData = (data, headers) => axios.post(`${baseURL}/al-gosaibi/project/manager/collectWaste`, data, { headers });
export const getSurveyData = (data, headers) => axios.get(`${baseURL}/al-gosaibi/survey/getSuerveyRecord?project_id=${data}&page&count`, { headers });
//Project Area, Bin and TS dropdowns
export const getAreaList = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/division/get?project_id=${data}&page&count`, { headers });
export const getBinList = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/bin/get?area_id=${data}&page&count`, { headers });
export const getTSList = (data, headers) => axios.get(`${baseURL}/al-gosaibi/project/timeslot/get?bin_id=${data}&page&count`, { headers });

//Waste Records Side Nav
export const getMainGarbageData = (headers) => axios.get(`${baseURL}/al-gosaibi/project/getGarbageData?page&count`, { headers });

//Banner
export const getBanner = (headers) => axios.get(`${baseURL}/al-gosaibi/masterData/banner/get?page=&count`, { headers });
export const addBanner = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/banner/save`, data, { headers });
export const updateStatus = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/banner/status/update`, data, { headers });
export const deleteBanner = (data, headers) => axios.post(`${baseURL}/al-gosaibi/masterData/banner/delete`, data, { headers });

//Notification
export const saveNotification = (data, headers) => axios.post(`${baseURL}/al-gosaibi/email/sendEmailToUser`, data, { headers });

//Feedback Page
export const submitFeedback = (data, headers) => axios.post(`${baseURL}/al-gosaibi/auth/collectFeedbackCommend`, data, { headers });

//Survey Questions Listing
export const getSurveyQuestions = (data, headers) => axios.get(`${baseURL}/al-gosaibi/auth/survey/question/get?project_id=${data.projectId}&area_id=${data.areaId}`, { headers });
export const getSurveyBanner = (headers) => axios.get(`${baseURL}/al-gosaibi/auth/banner/get?section=survey`, { headers });
export const submitSurveyFeedback = (data, headers) => axios.post(`${baseURL}/al-gosaibi/auth/collectSurvey`, data, { headers });
