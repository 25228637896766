import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_LOADING,
  LOGOUT_LOADING,
} from "../constants";

const initialState = {
  error: "",
  loading: false,
  loginData: {},
  logoutLoading: false,
};

const Login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loginData: action.payload,
        loading: true,
      };
      break;
    case LOGIN_LOADING:
      state = {
        ...state,
        loading: action.payload,
      };
    case LOGIN_ERROR:
      state = {
        ...state,
        loginError: action.payload,
        loading: false,
      };
      break;
    case LOGOUT_LOADING:
      state = { ...state, logoutLoading: action.payload };
      break;
    case LOGOUT_USER:
      state = { ...state, loginData: {} };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Login;
