import React from 'react';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageDropdown = props => {
    return (
        <div className='languageSection'>
            <LanguageIcon color="primary" />
        </div>
    )
}

export default LanguageDropdown