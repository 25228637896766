import React from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Box, Grid } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  getDashboardProject,
  getDashboardWaste,
  getDefaultsData,
  getLMDashboardGraph,
  getLMDashboardWaste,
  getOverAllData,
  getRegion,
  updateDefaults,
} from "../api";
import moneywaste from "../../assets/images/money-waste.png";
import weightwaste from "../../assets/images/weight-waste.png";
import production from "../../assets/images/garbage-waste.png";
import foodWaste from "../../assets/images/food-waste.png";
import storewaste from "../../assets/images/store-waste.png";
import "./dashboard.scss";
import { Button } from "primereact/button";
import DoughnutChartDemo from "./pieChart";
import MultiAxisDemo from "./multiAxis";
import { Dropdown } from "primereact/dropdown";
import FilterDashboard from "./filters";
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import _ from "lodash";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

// Get the current date
var currentDate = new Date();

// Get the month before the current date
var monthBefore = new Date(currentDate);
monthBefore.setMonth(currentDate.getMonth() - 1);

// Function to format date as DD-MM-YYYY
function formatDate(date) {
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 because months are zero-based
  var year = date.getFullYear();

  // Format the result as DD-MM-YYYY
  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
}

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

class DashboardWaste extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wasteData: [],
      lmWasteData: [],
      overAllDataSet: 0,

      totalWasteSar: 0,
      totalWasteSarLM: 0,
      totalWasteWeight: 0,
      totalWasteWeightLM: 0,
      highestWaste: '',
      highestWasteKg: 0,
      highestWasteKgLM: 0,
      totalStoreWaste: 0,
      totalStoreWasteLM: 0,
      startDate: formatDate(monthBefore),
      endDate: formatDate(currentDate),
      baseLinePerc: 0,
      baseLineSar: 0,
      totalWeightBaseLine: 0,
      overAllWasteSar: 0,
      overAllWaste: 0,

      totalWasteDes: 'We are aware that your waste is not seperated yet. Great participation!',
      totalStoreDes: 'Are you recording all Stores Waste figures?'
    };
  }

  assignValue = (currentData = [], oldData = [], overAll) => {
    this.setState({
      totalWasteSar: _.toInteger((_.sumBy(currentData, 'total_waste') * this.state.baseWeightCost)?.toFixed(2)),
      totalWasteSarLM:  _.toInteger((_.sumBy(oldData, 'total_waste') * this.state.baseWeightCost)?.toFixed(2)),
      totalWasteWeight: _.sumBy(currentData, 'total_waste'),
      totalWasteWeightLM: _.sumBy(oldData, 'total_waste'),
      highestWaste: _.maxBy(currentData, 'total_waste')?.waste_type,
      highestWasteKg: _.maxBy(currentData, 'total_waste')?.total_waste,
      highestWasteKgLM: _.find(oldData, { ['waste_type']: _.maxBy(currentData, 'total_waste')?.waste_type })?.total_waste,
      totalStoreWaste: _.find(currentData, { ['waste_type']: 'Store waste'})?.total_waste || 0,
      totalStoreWasteLM: _.find(oldData, { ['waste_type']: 'Store waste'})?.total_waste || 0,
      baseLinePerc: ((( _.sumBy(currentData, 'total_waste') - this.state.baseWeight) / _.sumBy(currentData, 'total_waste')) * 100)?.toFixed(2),
      baseLineSar: _.toInteger(((((_.sumBy(currentData, 'total_waste') - this.state.baseWeight ) / _.sumBy(currentData, 'total_waste')) * 100) * this.state.baseWeightCost)?.toFixed(2)),
      totalWeightBaseLine: _.sumBy(currentData, 'total_waste') - this.state.baseWeight,
      overAllWasteSar: _.toInteger(overAll?.toFixed(2)) * this.state.baseWeightCost,
      overAllWaste: _.toInteger(overAll?.toFixed(2))
    })
  }

  collectData = (
    regionId = '',
    projectId = '',
    startDate = formatDate(monthBefore),
    endDate = formatDate(currentDate),
    regionName
  ) => {
    this.setState({
      startDate,
      endDate,
      regionName
    })

    getDashboardWaste(headers, regionId, projectId, startDate, endDate)
      .then((r) => {
        let data = r?.data?.data || [];
        this.setState({
          wasteData: data
        }, () =>{
          this.assignValue(this.state.wasteData, this.state.lmWasteData, this.state.overAllDataSet)
        })
      })
      .catch((e) => console.log("WASTE COLLECTION ERROR >>>>>>>>", e));

    getLMDashboardWaste(headers, projectId, regionId)
      .then((r) => {
        let data = r?.data?.data || [];
        this.setState({
          lmWasteData: data
        }, () =>{
          this.assignValue(this.state.wasteData, this.state.lmWasteData, this.state.overAllDataSet)
        })
      })
      .catch((e) =>
        console.log("LAST MONTH WASTE COLLECTION ERROR >>>>>>>>", e)
      );

      getOverAllData(headers)
      .then((r) => {
        let data = r?.data?.data || [];
        this.setState({
          overAllDataSet: data[0]?.sum
        }, () =>{
          this.assignValue(this.state.wasteData, this.state.lmWasteData, this.state.overAllDataSet)
        })
      })
      .catch((e) =>
        console.log("LAST MONTH WASTE COLLECTION ERROR >>>>>>>>", e)
      );
  };

  getDefaults = () => {
    getDefaultsData(headers)
    .then((r) => {
      let obj = r?.data?.["data"]?.[0]
      this.setState({
        baseWeight: obj?.baseweight,
        baseWeightCost: obj?.basecost,
        mealWeight: obj?.mealweight
      });
    })
    .catch((e) =>
      console.log("LAST MONTH WASTE COLLECTION ERROR >>>>>>>>", e)
    );
  }

  componentDidMount() {
    document.title = "Waste Data";
    this.collectData();
    this.getDefaults();
  }

  setVisible = (val) => {
    this.setState({
      visible: val
    })
  }

  setValue = (key, val) => {
    console.log("To TEST >>>", key, val)
    this.setState({
      [`${key}`]: val
    })
  }

  setDefaults = () => {
    let body = {
      "baseWeight": this.state.baseWeight,
      "baseWeightCost": this.state.baseWeightCost,
      "mealWeight": this.state.mealWeight, 
    }
    updateDefaults(body, headers)
    .then(()=>{
      this.setState({
        visible: false
      },()=>{
        this.getDefaults()
      })
    })
  }


  render() {
    const footerContent = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => this.setVisible(false)} className="p-button-text" />
            <Button label="Confirm" icon="pi pi-check" onClick={() => this.setDefaults()} autoFocus />
        </div>
    );
    
    const handlePrint = () => {
      window.print();
    };

    const {
    } = this.state;
    return (
      <div className="dashboard-waste">
        <h2> Waste Snapshot</h2>
        <div className="waste-section">

          <div className="filter-sec">
            <FilterDashboard getFilterData = {this.collectData}/>
            <div className="default-btn" style={{ marginLeft: 10 }}>
              <Button severity="secondary" style={{ margin: 10 }}aria-label="Bookmark" onClick={handlePrint}>
                <LocalPrintshopIcon/>
              </Button>
              <Button severity="secondary" onClick={() => this.setVisible(true)}>
                Set defaults
              </Button>
            </div>
          </div>

          <div className="dashboard-section" id="printable-section">
            <Box
              className="leftSec"
              sx={{
                height: 700,
                width: "70%",
                border: "1px solid black",
                marginRight: 1,
              }}
            >
              <div className="header-part">
                <h1>{this.state.regionName} WASTE SNAPSHOT</h1>
                <h3>Last Month's Waste at a Glance</h3>
              </div>
              <div className="body-part">
                <Grid className="first-waste" container spacing={2}>
                  <Grid className="value-waste" item xs={6}>
                    <h5>TOTAL WASTE BY VALUE</h5>
                    <div className="icon">
                      <img src={moneywaste} />
                      <p>{this.state.totalWasteSar?.toFixed(2)} SAR</p>
                    </div>
                    <div className="footer-text">
                      <div className="arrow-icon">
                        <ArrowUpwardIcon />
                        <p>{this.state.totalWasteSarLM?.toFixed(2)} SAR</p>
                      </div>
                      <p>VS LAST MONTH</p>
                    </div>
                    <div className="dis-text">
                      THAT IS{" "}
                      <span style={{ fontWeight: "bold" }}>SAR {parseInt(this.state.totalWasteSar) * 12?.toFixed(2)}</span>{" "}
                      IF WASTED FOR FULL YEAR
                    </div>
                  </Grid>
                  <Grid className="weight-waste" item xs={6}>
                    <h5>TOTAL WASTE BY WEIGHT</h5>
                    <div className="icon">
                      <img style={{width: 120, height:120}}src={weightwaste} />
                      <p>{this.state.totalWasteWeight?.toFixed(2)} kg(s)</p>
                    </div>
                    <div className="footer-text">
                      <div className="arrow-icon">
                        <ArrowUpwardIcon />
                        <p>{this.state.totalWasteWeightLM?.toFixed(2)} kg(s)</p>
                      </div>
                      <p>VS LAST MONTH</p>
                    </div>
                    <div className="dis-text">
                      THIS IS SAME AS{" "}
                      <span style={{ fontWeight: "bold" }}>{_.toNumber(((parseInt(this.state.totalWasteWeight) * 1000)/ this.state.mealWeight)?.toFixed(2))}</span> MEALS
                    </div>
                  </Grid>
                </Grid>
                <Grid className="second-waste" container spacing={2}>
                  <Grid style={{textWrap: "pretty"}} className="area-waste" item xs={6}>
                    <h5>TOTAL WASTE AREA - {this.state.highestWaste}</h5>
                    <div className="icon">
                      <img src={production} />
                      <p>{this.state.highestWasteKg?.toFixed(2)} kg(s)</p>
                    </div>
                    <div className="footer-text">
                      <div className="arrow-icon">
                        <ArrowUpwardIcon />
                        <p>{this.state.highestWasteKgLM?.toFixed(2)} kg(s)</p>
                      </div>
                      <p>VS LAST MONTH</p>
                    </div>
                    <div className="dis-text" style={{ fontWeight: "bold" }}>
                     {this.state.totalWasteDes}
                    </div>
                  </Grid>
                  <Grid style={{textWrap: "pretty"}} className="store-waste" item xs={6}>
                    <h5>TOTAL STORES WASTE</h5>
                    <div className="icon">
                      <img src={storewaste} />
                      <p>{this.state.totalStoreWaste?.toFixed(2)} kg(s)</p>
                    </div>
                    <div className="footer-text">
                      <div className="arrow-icon">
                        <ArrowUpwardIcon />
                        <p>{this.state.totalStoreWasteLM?.toFixed(2)} kg(s)</p>
                      </div>
                      <p>VS LAST MONTH</p>
                    </div>
                    <div className="dis-text" style={{ fontWeight: "bold" }}>
                    {this.state.totalStoreDes}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Box
              className="rightSec"
              sx={{ height: 700, width: "30%", border: "1px solid black" }}
            >
              <div className="header-part">
                <h3>{this.state.startDate} to {this.state.endDate}</h3>
              </div>
              <div className="body-part">
                <h5>OVERALL RESULTS</h5>
                <div>
                  <h2>{this.state.baseLinePerc}%</h2>
                  <p>CHANGE IN WASTE VERSUS BASELINE</p>
                </div>
                <div>
                  <h2>{this.state.baseLineSar} SAR</h2>
                  <p>TOTAL SAVINGS/INCREASE VERSUS BASELINE</p>
                </div>
                <div>
                  <h2>{this.state.totalWeightBaseLine?.toFixed(2)} kg(s)</h2>
                  <p>TOTAL WEIGHT VARIANCE VERSUS BASELINE</p>
                </div>
              </div>
              <div className="footer-part">
                <h5>TOTAL WASTE RECORDED SINCE PROGRAM START</h5>
                <div className="conversion">
                  <h2>{this.state.overAllWasteSar?.toFixed(2)} SAR</h2>
                  <h2>{this.state.overAllWaste?.toFixed(2)} kg(s)</h2>
                </div>
              </div>
            </Box>
          </div>
        </div>

        {/* Model */}

        <div className="card flex justify-content-center">
            <Dialog header="Set default values" visible={this.state.visible} style={{ width: '50vw' }} onHide={() => this.setVisible(false)} footer={footerContent}>
            <div className="card flex flex-wrap gap-3 p-fluid">
                <div className="flex-auto m-b-20">
                    <label htmlFor="baseWeight" className="font-bold block mb-2">Baseline Weight</label>
                    <div className="p-inputgroup flex-1 m-t-10">
                      <InputNumber className="" inputId="baseWeight" value={this.state.baseWeight} onValueChange={(e) => this.setValue('baseWeight', e.value)} minFractionDigits={2} maxFractionDigits={5}/>
                      <span className="p-inputgroup-addon">KG</span>
                    </div>
                </div>
                <div className="flex-auto m-b-20">
                    <label htmlFor="baseWeightCost" className="font-bold block mb-2">Baseline Weight Cost</label>
                    <div className="p-inputgroup flex-1 m-t-10">
                      <InputNumber className="" inputId="baseWeightCost" value={this.state.baseWeightCost} onValueChange={(e) => this.setValue('baseWeightCost', e.value)} useGrouping={false} />
                      <span className="p-inputgroup-addon">SAR/kg</span>
                    </div>
                </div>
                <div className="flex-auto m-b-20">
                    <label htmlFor="mealWeight" className="font-bold block mb-2">Meal Weight</label>
                    <div className="p-inputgroup flex-1 m-t-10">
                      <InputNumber className="" inputId="mealWeight" value={this.state.mealWeight} onValueChange={(e) => this.setValue('mealWeight', e.value)} minFractionDigits={2} maxFractionDigits={5} />
                      <span className="p-inputgroup-addon">Grams</span>
                    </div>
                </div>
                <div className="flex-auto m-b-20">
                    <label htmlFor="totalWasteDes" className="font-bold block mb-2">Total Waste Description</label>
                    <InputText value={this.state.totalWasteDes} onChange={(e) => this.setValue('totalWasteDes', e.target.value)}/>
                </div>
                <div className="flex-auto m-b-20">
                    <label htmlFor="totalStoreDes" className="font-bold block mb-2">Total Store Waste Description</label>
                    <InputText value={this.state.totalStoreDes} onChange={(e) => this.setValue('totalStoreDes', e.target.value)}/>
                </div>
            </div>
            </Dialog>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(DashboardWaste);
