import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import ProjectMaster from "./ProjectMaster";
import { loadProjectDetails, getProjectDivisionAction, getProjectBinAction, getProjectTimeAction } from "../../redux/actions/projectDetails";
import "./projectdetails.scss";
import SurveyRecord from "./surveyRecord.js";
import WasteRecord from "./wasteRecord.js";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: "",
      value: 0,
    };
  }

  componentDidMount() {
    document.title = "Project Details";
    const paramsArr = window.location.pathname
      ? window.location.pathname.split("/")
      : [];
    const id = paramsArr?.length ? paramsArr[3] : "";
    this.setState(
      {
        projectId: id,
      },
      () => {
        this.props.loadProjectDetails(this.state.projectId)
        this.props.getProjectDivisionAction(this.state.projectId)
        this.props.getProjectBinAction(this.state.projectId)
        this.props.getProjectTimeAction(this.state.projectId)
      }
    );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { projectId, value } = this.state;
    const { projDetails } = this.props.projectDetails;
    return (
      <div className="projectdetails">
        <h5>Project Details - {projDetails?.project_code}</h5>
        <Box
          border={{
            md: "3px solid #00895F",
          }}
          p="15px"
          borderRadius="5px"
          boxSizing="border-box"
        >
          <Grid container>
            <Grid item xs={3} spacing={1}>
              <p>
                <strong>Project Code: </strong>
                {projDetails?.project_code}
              </p>
            </Grid>
            <Grid item xs={4} spacing={1}>
              <p>
                <strong>Project Name: </strong>
                {projDetails?.project_name}
              </p>
            </Grid>
            <Grid item xs={5} spacing={1}>
              <p>
                <strong>Project Type: </strong>
                {projDetails?.project_type}
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} spacing={1}>
              <p>
                <strong>Location: </strong>
                {projDetails?.location}
              </p>
            </Grid>
            <Grid item xs={4} spacing={1}>
              <p>
                <strong>Manager Id: </strong>
                {projDetails?.manager_userid}
              </p>
            </Grid>
            <Grid item xs={5} spacing={1}>
              <p>
                <strong>Catering Manager Id: </strong>
                {projDetails?.supervisor_userid}
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} spacing={1}>
              <p>
                <strong>Region: </strong>
                {projDetails?.region}
              </p>
            </Grid>
            <Grid item xs={4} spacing={1}>
              <p>
                <strong>Manager Email: </strong>
                {projDetails?.manager_email}
              </p>
            </Grid>
            <Grid item xs={5} spacing={1}>
              <p>
                <strong>Catering Manager Email: </strong>
                {projDetails?.supervisor_email}
              </p>
            </Grid>
          </Grid>
        </Box>

        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            aria-label="basic tabs example"
            className="projectTabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#F3C300",
              },
            }}
          >
            <Tab label="Area / Bin" {...a11yProps(2)} />
            <Tab label="Waste Collection" {...a11yProps(3)} />
            <Tab label="Survey Collection" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ProjectMaster project_name={projDetails?.project_name}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <WasteRecord project_id={this.state.projectId}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <SurveyRecord project_id={this.state.projectId}/>
        </CustomTabPanel>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    projectDetails: store.projectDetails,
  };
};

const mapDispatchToProps = {
  loadProjectDetails,
  getProjectDivisionAction,
  getProjectBinAction,
  getProjectTimeAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
