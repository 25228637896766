import React from "react";
import { connect } from "react-redux";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  Modal,
  TextField,
  Alert,
} from "@mui/material";
import { Delete, Edit, Info, Close } from "@mui/icons-material";
import Table from "../../components/Common/Table";
import { deleteWaste, getWaste, saveEditWaste } from "../api";
import { apiError } from "../../redux/actions/loginActions";
import "./masters.scss";

class Waste extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      rowData: [],
      columnData: [],
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      wastetype: "",
      editedDetails: {},
      showAlert: false,
      alertMsg: "",
    };
  }

  componentDidMount() {
    document.title = "Waste";
    const columns = [
      { field: "id", headerName: "UID", width: 90, hide: true },
      {
        field: "wastetype",
        headerName: "SERVICE AREA",
        width: 300,
      },
      {
        field: "description",
        headerName: "DESCRIPTION",
        width: 300,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 150,

        renderCell: (params) => {
          const handleEditClick = (e) => {
            this.setState(
              { showEditModal: true, editedDetails: params.row },
              () => this.setEditedFields()
            );
          };
          const handleDeleteClick = (e) => {
            this.setState({ showDeleteModal: true, removeId: params.row.id });
          };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={handleEditClick}
                  color="success"
                  className="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={handleDeleteClick}
                  className="delete"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];
    this.getRowData();
    this.setState({ columnData: columns });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 10000);
  }

  setEditedFields = () => {
    const { editedDetails } = this.state;
    this.setState({
      wastetype: editedDetails.wastetype,
      description: editedDetails.description,
    });
  };

  getRowData = () => {
    let rowData = [];
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    getWaste({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        rowData = data?.length
          ? data.map((x) => {
              return {
                id: x.waste_id,
                wastetype: x.waste_name,
                description: x.description,
              };
            })
          : [];
        this.setState({ rowData });
      })
      .catch((e) => apiError(e));
  };

  addWaste = () => {
    this.setState({
      showAddModal: true,
    });
  };
  handleTextChange = (e, type) => {
    if (type === "type") this.setState({ wastetype: e.target.value });
    else if (type === "desc") this.setState({ description: e.target.value });
  };
  handleClose = (type) => {
    if (type === "delete") this.setState({ showDeleteModal: false });
    else if (type === "edit")
      this.setState({
        showEditModal: false,
        wastetype: "",
        description: "",
        editedDetails: {},
      });
    else if (type === "add")
      this.setState({
        showAddModal: false,
        wastetype: "",
        description: "",
        editedDetails: {},
      });
  };
  handleSubmit = (type) => {
    const { wastetype, editedDetails, description } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    let payload, apiType;
    if (type === "add") {
      apiType = "save";
      payload = {
        waste_name: wastetype,
        description: description,
      };
    } else if (type === "edit") {
      apiType = "edit";
      payload = {
        waste_id: editedDetails.id,
        waste_name: wastetype,
        description: description,
        status: "active",
      };
    }
    saveEditWaste(apiType, payload, headers)
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose(type);
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "success",
        });
      })
      .catch((e) => apiError(e));
  };

  deleteWaste = () => {
    const { removeId } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    deleteWaste(
      {
        waste_id: removeId,
      },
      headers
    )
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose("delete");
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "error",
        });
      })
      .catch((e) => apiError(e));
  };
  render() {
    const {
      rowData,
      columnData,
      wastetype,
      description,
      showAddModal,
      showDeleteModal,
      showEditModal,
      editedDetails,
      alertMsg,
      showAlert,
      severity,
    } = this.state;
    return (
      <div className="projects waste">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
          >
            {alertMsg}
          </Alert>
        ) : null}
        <div className="topSection">
          <h3>Waste</h3>
          <div className="rightSide">
            <Button
              variant="contained"
              className="addBtn"
              onClick={this.addWaste}
            >
              ADD
            </Button>
          </div>
        </div>
        <Table columns={columnData} rows={rowData} />

        {/* Add Modal */}
        {showAddModal ? (
          <Modal
            open={showAddModal}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-master"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              <div className="role-add">
                <div className="wt">
                  <InputLabel id="demo-simple-select-label">
                    Waste Type
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type Waste Type here..."
                    value={wastetype}
                    onChange={(e) => this.handleTextChange(e, "type")}
                  />
                </div>
                <div className="desc">
                  <InputLabel id="demo-simple-select-label">
                    Description
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type Description here..."
                    value={description}
                    onChange={(e) => this.handleTextChange(e, "desc")}
                  />
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("add")}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Edit Modal */}
        {showEditModal ? (
          <Modal
            open={showEditModal}
            onClose={() => this.handleClose("edit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-master"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("edit")}
              />
              <div className="role-add">
                <div className="wt">
                  <InputLabel id="demo-simple-select-label">
                    Waste Type
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type Waste Type here..."
                    value={wastetype}
                    onChange={(e) => this.handleTextChange(e, "type")}
                  />
                </div>
                <div className="desc">
                  <InputLabel id="demo-simple-select-label">
                    Description
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type Description here..."
                    value={description}
                    onChange={(e) => this.handleTextChange(e, "desc")}
                  />
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("edit")}
                >
                  UPDATE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Delete Modal */}
        {showDeleteModal ? (
          <Modal
            open={showDeleteModal}
            onClose={() => this.handleClose("delete")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="block-modal-master"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("delete")}
              />
              <Info className="infoIcon" />
              <h4>Are you Sure</h4>
              <p>Wants to Delete ?</p>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  className=""
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className=""
                  onClick={this.deleteWaste}
                >
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Waste);
