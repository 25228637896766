import React from "react";
import Table from "../../components/Common/Table";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Modal,
  Grid,
  Alert,
} from "@mui/material";
import { Delete, Edit, Block, Info, Close } from "@mui/icons-material";
import "./users.scss";
import { getRoles, getUsers } from "../api";
import { connect } from "react-redux";
import { addUser, editUser } from "../../redux/actions/roleMenu";

class UserList extends React.Component {
  constructor(props) {
    document.title = "Users List";
    super(props);
    this.state = {
      showVal: 10,
      searchVal: "",
      rowData: [],
      columnData: [],
      // statusVal: "approved",
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      blockModalOpen: false,
      userName: "",
      selectedRole: "manager",
      mail: "",
      phno: "",
      editModalData: {},
      showAlert: false,
      showModalAlert: false,
      alertMsg: "",
    };
  }

  componentDidMount() {
    let columns = [
      { field: "id", headerName: "Admin Id", width: 90, hide: true },
      {
        field: "adminName",
        headerName: "DISPLAY NAME",
        width: 150,
      },
      {
        field: "userName",
        headerName: "USERNAME",
        width: 150,
      },
      {
        field: "role",
        headerName: "ROLE",
        width: 150,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 250,
      },
      {
        field: "phno",
        headerName: "PHONE NUMBER",
        width: 200,
      },
      // {
      //   field: "status",
      //   headerName: "STATUS",
      //   width: 100,
      //
      // },
      {
        field: "created",
        headerName: "CREATED",
        width: 250,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 200,

        renderCell: (params) => {
          const handleEditClick = (e) => {
            this.setState(
              { editModalOpen: true, editModalData: params.row },
              () => this.setEditedFields()
            );
          };
          // const handleDeleteClick = (e) => {
          //   console.log("e", params.row);
          //   this.setState({ deleteModalOpen: true });
          // };
          // const handleBlockClick = (e) => {
          //   console.log("e", params.row);
          //   this.setState({ blockModalOpen: true });
          // };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={handleEditClick}
                  color="success"
                  className="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={handleDeleteClick}
                  className="delete"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Block">
                <IconButton onClick={handleBlockClick} className="block">
                  <Block />
                </IconButton>
              </Tooltip> */}
            </Box>
          );
        },
      },
    ];
    this.getRowData();
    this.setState({ columnData: columns });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 10000);
  }

  setEditedFields = () => {
    const { editModalData } = this.state;
    this.setState({
      userName: editModalData.userName,
      adminName: editModalData.adminName,
      role: editModalData.role,
      mail: editModalData.email,
      password: editModalData.password,
      phno: editModalData.phno,
    });
  };

  getRowData = () => {
    let rowData = [];
    const loginData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const jwt = loginData?.jwt ? loginData.jwt : "";
    const headers = {
      auth: jwt,
    };
    getUsers({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        rowData = data?.length
          ? data.map((x) => {
              return {
                id: x.admin_id,
                adminName: x.admin_name,
                userName: x.user_name,
                role: x.role,
                email: x.email,
                password: x.password,
                phno: x.phone,
                created: x.created_date,
              };
            })
          : [];
        this.setState({ rowData });
      })
      .catch((e) => console.log(e));

    getRoles({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        let rolesData = data?.length
          ? data
              .filter((x) => x.role_name !== "super_admin")
              ?.map((x) => {
                return { id: x.role_id, role: x.role_name };
              })
          : [];
        this.setState({ rolesData });
      })
      .catch((e) => console.log(e));
  };

  handleTextChange = (e, type) => {
    if (type === "mail") this.setState({ mail: e.target.value });
    else if (type === "user") {
      const newValue = e.target.value.replace(/\s/g, '').toLowerCase();
      this.setState({ userName: newValue });
    } else if (type === "admin") this.setState({ adminName: e.target.value });
    else if (type === "password") {
      const newValue = e.target.value.replace(/\s/g, '')
      this.setState({ password: newValue });
    } else if (type === "phno") this.setState({ phno: e.target.value });
  };
  handleDropdownChange = (e, type) => {
    // if (type === "status") this.setState({ statusVal: e.target.value });
    if (type === "rows") this.setState({ showVal: e.target.value });
    else if (type === "roles") this.setState({ selectedRole: e.target.value });
  };
  handleClose = (type) => {
    this.setState({
      addModalOpen: false,
      userName: "",
      selectedRole: "",
      mail: "",
      password: "",
      phno: "",
      adminName: "",
    });
    if (type === "block") this.setState({ blockModalOpen: false });
    else if (type === "delete") this.setState({ deleteModalOpen: false });
    else if (type === "edit") this.setState({ editModalOpen: false });
    else if (type === "add") this.setState({ addModalOpen: false });
  };
  filterSearchData = (searchVal) => {
    if (searchVal) {
      const searchRegex = new RegExp(`.*${searchVal}.*`, "ig");
      const filteredRows = this.state.rowData.filter((o) => {
        return Object.keys(o).some((k) => {
          return searchRegex.test(o[k]?.toString());
        });
      });
      this.setState({
        filteredRows: filteredRows,
      });
    } else {
      this.setState({
        filteredRows: this.state.rowData,
      });
    }
  };
  handleSearch = (e) => {
    this.setState(
      {
        searchVal: e.target.value,
      },
      () => this.filterSearchData(this.state.searchVal)
    );
  };

  addProject = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleSubmit = async (type) => {
    const {
      userName,
      selectedRole,
      mail,
      password,
      phno,
      adminName,
      rolesData,
      editModalData,
    } = this.state;
    const loginData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const jwt = loginData?.jwt ? loginData.jwt : "";
    const headers = {
      auth: jwt,
    };
    let obj =
      rolesData?.length && rolesData.find((x) => x.role === selectedRole);
    if (userName && selectedRole && mail && password && adminName) {
      if (type === "add") {
        let payload = {
          admin_name: adminName,
          user_name: userName,
          role_id: obj?.id ? obj.id : "",
          role: selectedRole,
          password: password,
          email: mail,
          phone: phno,
          image: "image-1698724420234-534107426.jpeg",
        };
        await this.props.createUser(payload, headers);
        this.setState(
          {
            addModalOpen: false,
            userName: "",
            selectedRole: "",
            mail: "",
            password: "",
            phno: "",
            adminName: "",
            showAlert: true,
            alertMsg: "Data Saved",
            severity: "success",
          },
          () => this.getRowData()
        );
      } else if (type === "edit") {
        let payload = {
          admin_id: editModalData.id,
          admin_name: adminName,
          user_name: userName,
          role_id: obj?.id ? obj.id : "",
          role: selectedRole,
          password: password,
          email: mail,
          phone: phno,
          image: "image-1698724420234-534107426.jpeg",
        };
        await this.props.updateUser(payload, headers);
        this.setState(
          {
            editModalOpen: false,
            userName: "",
            selectedRole: "",
            mail: "",
            password: "",
            phno: "",
            adminName: "",
            editModalData: {},
            showAlert: true,
            alertMsg: "Data Modified",
            severity: "success",
          },
          () => this.getRowData()
        );
      }
    }
  };

  render() {
    const {
      showVal,
      searchVal,
      rowData,
      columnData,
      // statusVal,
      // blockModalOpen,
      // deleteModalOpen,
      addModalOpen,
      editModalOpen,
      userName,
      selectedRole,
      mail,
      phno,
      adminName,
      password,
      editModalData,
      rolesData,
      showAlert,
      alertMsg,
      severity,
      filteredRows,
    } = this.state;
    return (
      <div className="projects userList">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
          >
            {alertMsg}
          </Alert>
        ) : null}
        <h3>Users List</h3>
        <div className="topSection">
          <div className="leftside">
            <div className="dropdowns showby">
              <InputLabel id="demo-simple-select-label">Show by</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showVal}
                onChange={(e) => this.handleDropdownChange(e, "rows")}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </div>
            {/* <div className="dropdowns status">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusVal}
                onChange={(e) => this.handleDropdownChange(e, "status")}
              >
                <MenuItem value={"approved"}>Approved</MenuItem>
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"inprogress"}>In Progress</MenuItem>
              </Select>
            </div> */}
            <div className="search">
              <InputLabel id="demo-simple-select-label">Search</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Project Code"
                value={searchVal}
                onChange={this.handleSearch}
              />
            </div>
          </div>
          <div className="rightSide">
            <Button
              variant="contained"
              className="addBtn"
              onClick={this.addProject}
            >
              ADD
            </Button>
          </div>
        </div>
        <Table
          columns={columnData}
          rows={searchVal ? filteredRows : rowData}
          pageSize={showVal}
        />

        {/* Block Modal */}
        {/* {blockModalOpen ? (
          <Modal
            open={blockModalOpen}
            onClose={() => this.handleClose("block")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="block-modal"
          >
            <Box className="modal-Box">
              <Info />
              <h4>Are you Sure</h4>
              <p>Wants to block this user ?</p>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="error"
                  className=""
                  onClick={() => this.setState({ blockModalOpen: false })}
                >
                  No
                </Button>
                <Button variant="contained" color="error" className="">
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null} */}

        {/* Delete Modal */}
        {/* {deleteModalOpen ? (
          <Modal
            open={deleteModalOpen}
            onClose={() => this.handleClose("delete")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="block-modal"
          >
            <Box className="modal-Box">
              <Info />
              <h4>Are you Sure</h4>
              <p>Wants to Delete ?</p>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="error"
                  className=""
                  onClick={() => this.setState({ deleteModalOpen: false })}
                >
                  No
                </Button>
                <Button variant="contained" color="error" className="">
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null} */}

        {/* Edit Modal */}
        {editModalOpen ? (
          <Modal
            open={editModalOpen}
            onClose={() => this.handleClose("edit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("edit")}
              />
              <Grid container>
                <Grid item xs={5} className="leftGrid">
                  <h4>Add User Profile Photo</h4>
                </Grid>
                <Grid item xs={7} className="rightGrid">
                  <div className="adminName">
                    <InputLabel id="demo-simple-select-label">
                      Display Name
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Type Name here..."
                      value={adminName}
                      onChange={(e) => this.handleTextChange(e, "admin")}
                    />
                  </div>
                  <div className="userName">
                    <InputLabel id="demo-simple-select-label">
                      User Name
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Type User Name here..."
                      value={userName}
                      onChange={(e) => this.handleTextChange(e, "user")}
                    />
                  </div>
                  <div className="selectRole">
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRole}
                      onChange={(e) => this.handleDropdownChange(e, "roles")}
                    >
                      {rolesData.map(function (x) {
                        return <MenuItem value={x.role}>{x.role}</MenuItem>;
                      })}
                    </Select>
                  </div>
                  <div className="mail-no">
                    <div className="mail">
                      <InputLabel id="demo-simple-select-label">
                        Email
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Type Email here..."
                        value={mail}
                        onChange={(e) => this.handleTextChange(e, "mail")}
                      />
                    </div>
                    <div className="num">
                      <InputLabel id="demo-simple-select-label">
                        Password
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Type Password"
                        type="password"
                        value={password}
                        onChange={(e) => this.handleTextChange(e, "password")}
                      />
                    </div>
                  </div>
                  <div className="phno">
                    <InputLabel id="demo-simple-select-label">
                      Phone number
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      className="no-spinners"
                      variant="outlined"
                      placeholder="Type Phone number here..."
                      type="number"
                      inputMode="numeric"
                      value={phno}
                      onChange={(e) => this.handleTextChange(e, "phno")}
                    />
                  </div>
                  <div className="submit">
                    <Button
                      variant="contained"
                      color="error"
                      className="submitBtn"
                      onClick={() => this.handleSubmit("edit")}
                    >
                      UPDATE
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        ) : null}

        {/* Add Modal */}
        {addModalOpen ? (
          <Modal
            open={addModalOpen}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              <Grid container>
                <Grid item xs={5} className="leftGrid">
                  <h4>Add User Profile Photo</h4>
                </Grid>
                <Grid item xs={7} className="rightGrid">
                  <div className="adminName">
                    <InputLabel id="demo-simple-select-label">
                      Admin Name
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Type Admin Name here..."
                      value={adminName}
                      onChange={(e) => this.handleTextChange(e, "admin")}
                    />
                  </div>
                  <div className="userName">
                    <InputLabel id="demo-simple-select-label">
                      User Name
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Type User Name here..."
                      value={userName}
                      onChange={(e) => this.handleTextChange(e, "user")}
                    />
                  </div>
                  <div className="selectRole">
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRole}
                      onChange={(e) => this.handleDropdownChange(e, "roles")}
                    >
                      {rolesData.map(function (x) {
                        return <MenuItem value={x.role}>{x.role}</MenuItem>;
                      })}
                    </Select>
                  </div>
                  <div className="mail-no">
                    <div className="mail">
                      <InputLabel id="demo-simple-select-label">
                        Email
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Type Email here..."
                        value={mail}
                        onChange={(e) => this.handleTextChange(e, "mail")}
                      />
                    </div>
                    <div className="num">
                      <InputLabel id="demo-simple-select-label">
                        Password
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Type Password"
                        type="password"
                        value={password}
                        onChange={(e) => this.handleTextChange(e, "password")}
                      />
                    </div>
                  </div>
                  <div className="phno">
                    <InputLabel id="demo-simple-select-label">
                      Phone number
                    </InputLabel>
                    <TextField
                      id="outlined-basic" 
                      className="no-spinners"
                      variant="outlined"
                      placeholder="Type Phone number here..."
                      type="number"
                      inputMode="numeric"
                      value={phno}
                      onChange={(e) => this.handleTextChange(e, "phno")}
                    />
                  </div>
                  <div className="submit">
                    <Button
                      variant="contained"
                      color="error"
                      className="submitBtn"
                      onClick={() => this.handleSubmit("add")}
                    >
                      SAVE
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuList: state.Roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (data, headers) => dispatch(addUser(data, headers)),
    updateUser: (data, headers) => dispatch(editUser(data, headers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
