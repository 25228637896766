import React from "react";
import { connect } from "react-redux";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  Modal,
  TextField,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import { Delete, Edit, Info, Close, Launch } from "@mui/icons-material";
import Table from "../../components/Common/Table";
import {
  deleteSupervisor,
  getManager,
  getSupervisor,
  saveEditSupervisor,
  uploadImage,
} from "../api";
import { apiError } from "../../redux/actions/loginActions";
import "./supervisor.scss";

const baseURL = "http://20.203.123.127:8080/api";
// const baseURL = 'http://20.233.41.167:8080';
// const baseURL = 'https://www.agsisraf.com';
class Supervisor extends React.Component {
  constructor(props) {
    document.title = "Supervisor";
    super(props);
    this.state = {
      showVal: 10,
      searchVal: "",
      showAddModal: false,
      rowData: [],
      columnData: [],
      editedDetails: {},
      showAlert: false,
      alertMsg: "",
      campbossData: {},
      openImageModal: false,
    };
  }

  componentDidMount() {
    const columns = [
      { field: "id", headerName: "ID", width: 90, hide: true },
      {
        field: "userId",
        headerName: "USER NAME",
        width: 150,
      },
      {
        field: "name",
        headerName: "NAME",
        width: 150,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 250,
      },
      {
        field: "contact",
        headerName: "CONTACT",
        width: 150,
      },
      {
        field: "image",
        headerName: "IMAGE",
        width: 150,
        renderCell: (params) => {
          let imageUrl = params.row.image.includes("agsisraf.com/api/")
            ? `https://${params.row.image}`
            : params.row.image.replace(
                "20.203.123.127:8080/",
                "https://agsisraf.com/api/"
              );
          return params.row.image === "undefined" || params.row.image === "" ? (
            <p>-</p>
          ) : (
            <a
              className="imageLink"
              onClick={() =>
                this.setState({
                  openImageModal: true,
                  modalImage: params?.row?.image ? imageUrl : "",
                })
              }
            >
              Open Image
              <div className="launchIcon">
                <Launch fontSize="small" />
              </div>
            </a>
          );
        },
      },
      {
        field: "address",
        headerName: "ADDRESS",
        width: 150,
      },
      {
        field: "mgrId",
        headerName: "MANAGER USERID",
        width: 150,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 150,

        renderCell: (params) => {
          const handleEditClick = (e) => {
            this.setState(
              { showEditModal: true, editedDetails: params.row },
              () => this.setEditedFields()
            );
          };
          const handleDeleteClick = (e) => {
            this.setState({ showDeleteModal: true, removeId: params.row.id });
          };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={handleEditClick}
                  color="success"
                  className="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={handleDeleteClick}
                  className="delete"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];
    this.getRowData();
    this.getManager();
    this.setState({ columnData: columns });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 10000);
  }

  setEditedFields = () => {
    const { editedDetails } = this.state;
    let data = {
      userId: editedDetails.userId,
      contact: editedDetails.contact,
      mgrId: editedDetails.mgrId,
      mgrUserId: this.state.managerList.find(
        (r) => r.manager_id === editedDetails.mgrId
      )?.user_name,
      name: editedDetails.name,
      address: editedDetails.address,
      password: editedDetails.password,
      image: editedDetails.image,
      email: editedDetails.email,
    };
    this.setState({
      campbossData: data,
    });
  };

  getManager = () => {
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    getManager({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        this.setState({
          managerList: data,
        });
      })
      .catch((e) => apiError(e));
  };

  getRowData = () => {
    let rowData = [];
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    getSupervisor({}, headers)
      .then((res) => {
        const data = res?.data?.data;
        rowData = data?.length
          ? data?.map((x) => {
              return {
                id: x.supervisor_id,
                userId: x.supervisor_userid,
                name: x.supervisor_name,
                contact:
                  x.contact === "undefined" || x.contact === ""
                    ? "NA"
                    : x.contact,
                image: x.image ? x.image : "",
                password: x.password,
                address:
                  x.address === "undefined" || x.address === ""
                    ? "NA"
                    : x.address,
                mgrId: x?.manager_id,
                email: x.email,
              };
            })
          : [];
        this.setState({ rowData });
      })
      .catch((e) => apiError(e));
  };

  addSupervisor = () => {
    this.setState({
      showAddModal: true,
    });
  };

  handleClose = (type) => {
    if (type === "delete") this.setState({ showDeleteModal: false });
    else if (type === "edit")
      this.setState({
        showEditModal: false,
        campbossData: {},
        editedDetails: {},
      });
    else if (type === "add")
      this.setState({
        showAddModal: false,
        campbossData: {},
        editedDetails: {},
      });
    else if (type === "image") this.setState({ openImageModal: false });
  };

  handleTextChange = async (e, type) => {
    let data = { ...this.state.campbossData };
    if (type === "userId") {
      const newValue = e.target.value.replace(/\s/g, "").toLowerCase();
      data["userId"] = newValue;
    } else if (type === "name") data["name"] = e.target.value;
    else if (type === "contact") data["contact"] = e.target.value;
    else if (type === "address") data["address"] = e.target.value;
    else if (type === "email") data["email"] = e.target.value;
    else if (type === "password") {
      const newValue = e.target.value.replace(/\s/g, "");
      data["password"] = newValue;
    } else if (type === "image") {
      data["image"] = e.target.value ? e.target.value : "";
      let headers = {
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();
      formData.append("image", e.target.files[0]);

      await uploadImage(formData, headers)
        .then((r) => {
          let dataimage = r?.data?.file ? r.data.file : "";
          data["imageUrl"] = dataimage;
        })
        .catch((e) => console.log(e));
    } else if (type === "mgrId") {
      data["mgrId"] = e.target.value;
      let mgrUserId = this.state.managerList.find(
        (r) => r.manager_id === e.target.value
      )?.user_name;
      data["mgrUserId"] = mgrUserId;
    }
    this.setState({ campbossData: data });
  };

  handleDropdownChange = (e) => {
    this.setState({
      showVal: e.target.value,
    });
  };

  filterSearchData = (searchVal) => {
    if (searchVal) {
      const searchRegex = new RegExp(`.*${searchVal}.*`, "ig");
      const filteredRows = this.state.rowData.filter((o) => {
        return Object.keys(o).some((k) => {
          return searchRegex.test(o[k]?.toString());
        });
      });
      this.setState({
        filteredRows: filteredRows,
      });
    } else {
      this.setState({
        filteredRows: this.state.rowData,
      });
    }
  };

  handleSearch = (e) => {
    this.setState(
      {
        searchVal: e.target.value,
      },
      () => this.filterSearchData(this.state.searchVal)
    );
  };

  handleSubmit = (type) => {
    const { campbossData, editedDetails } = this.state;
    console.log("data >>>>>>>>>", campbossData);
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    let payload, apiType;
    if (type === "add") {
      apiType = "save";
      payload = {
        supervisor_userid: campbossData.userId,
        supervisor_name: campbossData.name,
        email: campbossData.email,
        contact: campbossData.contact,
        image: campbossData.imageUrl,
        password: campbossData.password,
        address: campbossData.address,
        manager_id: campbossData.mgrId,
        manager_userid: campbossData.mgrUserId,
      };
    } else if (type === "edit") {
      apiType = "edit";
      payload = {
        supervisor_id: editedDetails.id,
        supervisor_userid: campbossData.userId,
        supervisor_name: campbossData.name,
        email: campbossData.email,
        contact: campbossData.contact,
        image: campbossData.imageUrl,
        password: campbossData.password,
        address: campbossData.address,
        manager_id: campbossData.mgrId,
        manager_userid: campbossData.mgrUserId,
      };
    }
    saveEditSupervisor(apiType, payload, headers)
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose(type);
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "success",
        });
      })
      .catch((e) => apiError(e));
  };

  deleteSupervisor = () => {
    const { removeId } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    deleteSupervisor(
      {
        supervisor_id: removeId,
      },
      headers
    )
      .then((res) => {
        const data = res?.data;
        this.getRowData();
        this.handleClose("delete");
        this.setState({
          showAlert: true,
          alertMsg: data.message,
          severity: "error",
        });
      })
      .catch((e) => apiError(e));
  };

  render() {
    const {
      rowData,
      columnData,
      campbossData,
      showAddModal,
      showDeleteModal,
      showEditModal,
      editedDetails,
      alertMsg,
      showAlert,
      severity,
      showVal,
      searchVal,
      filteredRows,
      openImageModal,
      modalImage,
    } = this.state;
    return (
      <div className="supervisor">
        {showAlert ? (
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => this.setState({ showAlert: false })}
          >
            {alertMsg}
          </Alert>
        ) : null}
        <div className="sections">
          <h3>Catering Managers Data</h3>
          <div className="topSection">
            <div className="leftside">
              <div className="dropdowns">
                <InputLabel id="demo-simple-select-label">Show by</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={showVal}
                  onChange={this.handleDropdownChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </div>
              <div className="search">
                <InputLabel id="demo-simple-select-label">Search</InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Project Code"
                  value={searchVal}
                  onChange={this.handleSearch}
                />
              </div>
              <div className="rightSide">
                <Button
                  variant="contained"
                  className="addBtn"
                  onClick={this.addSupervisor}
                >
                  ADD
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table columns={columnData} rows={searchVal ? filteredRows : rowData} />

        {/* Add Modal */}
        {showAddModal ? (
          <Modal
            open={showAddModal}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-supervisor"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              <div className="proj-text-1">
                <div className="userid">
                  <InputLabel id="demo-simple-select-label">
                    User Name
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["userId"]}
                    onChange={(e) => this.handleTextChange(e, "userId")}
                  />
                </div>
                <div className="name">
                  <InputLabel id="demo-simple-select-label">
                    Display Name
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["name"]}
                    onChange={(e) => this.handleTextChange(e, "name")}
                  />
                </div>
                <div className="email">
                  <InputLabel id="demo-simple-select-label">Email</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["email"]}
                    onChange={(e) => this.handleTextChange(e, "email")}
                  />
                </div>
                <div className="password">
                  <InputLabel id="demo-simple-select-label">
                    Password
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="password"
                    placeholder="Type here ....."
                    value={campbossData["password"]}
                    onChange={(e) => this.handleTextChange(e, "password")}
                  />
                </div>
              </div>
              <div className="proj-text-2">
                <div className="image">
                  <InputLabel id="demo-simple-select-label">Image</InputLabel>
                  <input
                    accept="image/*"
                    capture="camcorder"
                    style={{
                      width: 170,
                      height: 38,
                    }}
                    id="outlined-basic"
                    // value={campbossData["image"]}
                    onChange={(e) => this.handleTextChange(e, "image")}
                    type="file"
                  />
                </div>
                <div className="address">
                  <InputLabel id="demo-simple-select-label">Address</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["address"]}
                    onChange={(e) => this.handleTextChange(e, "address")}
                  />
                </div>
                <div className="contact">
                  <InputLabel id="demo-simple-select-label">Contact</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["contact"]}
                    onChange={(e) => this.handleTextChange(e, "contact")}
                  />
                </div>
                <div className="mgrid">
                  <InputLabel id="demo-simple-select-label">Manager</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-region"
                    style={{
                      width: 170,
                      height: 38,
                    }}
                    onChange={(e) => this.handleTextChange(e, "mgrId")}
                  >
                    {this.state &&
                      this.state.managerList &&
                      this.state.managerList.map((res) => {
                        return (
                          <MenuItem value={res.manager_id}>
                            {res.manager_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("add")}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Edit Modal */}
        {showEditModal ? (
          <Modal
            open={showEditModal}
            onClose={() => this.handleClose("edit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-supervisor"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("edit")}
              />
              <div className="proj-text-1">
                <div className="userid">
                  <InputLabel id="demo-simple-select-label">
                    User Name
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["userId"]}
                    onChange={(e) => this.handleTextChange(e, "userId")}
                  />
                </div>
                <div className="name">
                  <InputLabel id="demo-simple-select-label">
                    Display Name
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["name"]}
                    onChange={(e) => this.handleTextChange(e, "name")}
                  />
                </div>
                <div className="email">
                  <InputLabel id="demo-simple-select-label">Email</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["email"]}
                    onChange={(e) => this.handleTextChange(e, "email")}
                  />
                </div>
                <div className="password">
                  <InputLabel id="demo-simple-select-label">
                    Password
                  </InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="password"
                    placeholder="Type here ....."
                    value={campbossData["password"]}
                    onChange={(e) => this.handleTextChange(e, "password")}
                  />
                </div>
              </div>
              <div className="proj-text-2">
                <div className="image">
                  <InputLabel id="demo-simple-select-label">Image</InputLabel>
                  <input
                    accept="image/*"
                    capture="camcorder"
                    style={{
                      width: 170,
                      height: 38,
                    }}
                    // value={campbossData["image"] === 'undefined' ? '': campbossData["image"]}
                    id="outlined-basic"
                    onChange={(e) => this.handleTextChange(e, "image")}
                    type="file"
                  />
                </div>
                <div className="address">
                  <InputLabel id="demo-simple-select-label">Address</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["address"]}
                    onChange={(e) => this.handleTextChange(e, "address")}
                  />
                </div>
                <div className="contact">
                  <InputLabel id="demo-simple-select-label">Contact</InputLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Type here ....."
                    value={campbossData["contact"]}
                    onChange={(e) => this.handleTextChange(e, "contact")}
                  />
                </div>
                <div className="mgrid">
                  <InputLabel id="demo-simple-select-label">Manager</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-region"
                    style={{
                      width: 170,
                      height: 38,
                    }}
                    onChange={(e) => this.handleTextChange(e, "mgrId")}
                    value={campbossData["mgrId"]}
                  >
                    {this.state &&
                      this.state.managerList &&
                      this.state.managerList.map((res) => {
                        return (
                          <MenuItem value={res.manager_id}>
                            {res.manager_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => this.handleSubmit("edit")}
                >
                  UPDATE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Delete Modal */}
        {showDeleteModal ? (
          <Modal
            open={showDeleteModal}
            onClose={() => this.handleClose("delete")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="block-modal-supervisor"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("delete")}
              />
              <Info className="infoIcon" />
              <h4>Are you Sure</h4>
              <p>Wants to Delete ?</p>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  className=""
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className=""
                  onClick={this.deleteSupervisor}
                >
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Image Modal */}
        {openImageModal ? (
          <Modal
            open={openImageModal}
            onClose={() => this.handleClose("image")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-image"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("image")}
              />
              <img src={modalImage} height={500} width={1200} />
            </Box>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Supervisor);
