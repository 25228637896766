import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const documentStyle = getComputedStyle(document.documentElement);
const textColor = documentStyle.getPropertyValue("--text-color");
const textColorSecondary = documentStyle.getPropertyValue(
  "--text-color-secondary"
);
const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

class MultiAxisDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: props.labels,
        datasets: props.datasets,
      },
      options: {
        stacked: false,
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              drawOnChartArea: false,
              color: surfaceBorder,
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div className="card">
        <Chart
          type="line"
          data={this.state.data}
          options={this.state.options}
        />
      </div>
    );
  }
}

export default MultiAxisDemo;
