import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle
} from "@mui/material";
import withRouter from "../../components/Common/withRouter";
// Layout Related Components
import Header from "./Sections/Header";
import SideNav from "./Sections/SideNav";
import { getMenuList } from "../../redux/actions/roleMenu";
import "./Layout.scss";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const loginData = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {};
    this.fetchPermissions(loginData);
  }

  fetchPermissions = (loginData) => {
    let params = {
      roleId: loginData?.admin_id,
    };
    const jwt = loginData?.jwt;
    const headers = {
      auth: jwt,
    };
    this.props.fetchMenuList(params, headers);
  };

  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header />
          <SideNav />
          <div className="main-content">{this.props.children}</div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuList: state.Roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMenuList: (params, headers) => dispatch(getMenuList(params, headers)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
