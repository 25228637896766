import React from "react";

const ForgetPasswordPage = props => {

  document.title = "Forget Password";
  return (
    <React.Fragment>
        <h2>Forgot Password</h2>
    </React.Fragment>
  );
};

export default ForgetPasswordPage