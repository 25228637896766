import React from "react";

const privacy = () => {
  return (
    <div style={{ margin: "0% 10%" }}>
      <h2>PRIVACY POLICY FOR AGS </h2>
      <p style={{ lineHeight: "2pc" }}>
        <b>1. Introduction:</b>
        <br />
        AGS is committed to protecting the privacy and security of personal
        information entrusted to us. This Privacy Policy outlines how we
        collect, use, disclose, and protect personal information in accordance
        with applicable privacy laws and regulations.
        <br />
        <b>2. Information We Collect:</b>
        <br />
        We may collect personal information from customers, employees,
        contractors, and other individuals. This information may include, but is
        not limited to:
        <br />
        <ul>
          <li>
            Contact information (name, address, phone number, email address){" "}
          </li>{" "}
          <li>Location of the mobile app user </li>{" "}
          <li>
            Data collected from camps for waste with the supervisor details{" "}
          </li>{" "}
          <li>Survey data collected from each location</li>{" "}
          <li>
            Images from camera or gallery used while taking the picture of the
            waste accumulated during each meal session.{" "}
          </li>{" "}
          <li>Waste management preferences and requirements </li>
        </ul>
        <b>3. How We Use Personal Information:</b>
        <br />
        We may use personal information for the following purposes:
        <br />
        <ul>
          <li>Providing waste management services </li>{" "}
          <li>Communicating with customers and stakeholders </li>{" "}
          <li>Managing employee relationships </li>{" "}
          <li>Ensuring compliance with regulatory requirements </li>{" "}
          <li>Improving our services and operations</li>
        </ul>
        <b>4. Disclosure of Personal:</b>
        <br />
        Information We may disclose personal information to third parties in the
        following circumstances:
        <br />
        <ul>
          <li>With consent from the individual </li>{" "}
          <li>To comply with legal obligations</li>{" "}
          <li>
            To facilitate waste management operations (e.g., subcontractors) •
            In connection with a corporate transaction (e.g., merger or
            acquisition)
          </li>
        </ul>
        <b>5. Security Measures:</b>
        <br />
        We have implemented security measures to protect personal information
        from unauthorized access, use, or disclosure. These measures include:
        <br />
        <ul>
          <li>Physical security measures </li>{" "}
          <li>Technological security measures</li>{" "}
          <li>Organizational security measures</li>
        </ul>
        <b>6. Retention of Personal Information:</b>
        <br />
        We retain personal information only for as long as necessary to fulfill
        the purposes outlined in this Privacy Policy or as required by law.
        <br />
        <b>7. Access and Correction:</b>
        <br />
        Individuals have the right to access and correct their personal
        information held by Al Gosaibi Services . Requests for access or
        correction should be submitted in writing to
        <a href="mailto:agscontracting@algosaibiservices.com"> agscontracting@algosaibiservices.com</a>.
      </p>
    </div>
  );
};

export default privacy;
