import './App.scss';
import React from "react";
import { Routes, Route } from 'react-router-dom';
import { userRoutes, authRoutes, mobileAppRoutes } from "./routes/allRoutes";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout.js";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware.js";
import { connect } from 'react-redux';

import "primereact/resources/themes/lara-light-indigo/theme.css";

function App(props) {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {mobileAppRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  {route.component}
                </Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
  };
};

export default connect(mapStateToProps)(App);
