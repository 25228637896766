import React from "react";
import SidebarContent from "./SidebarContent";


const SideNav = props => {

  return (
    <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
          </div>
        </div>
    </React.Fragment>
  );
};

export default SideNav