import { combineReducers } from 'redux';
import Login from './Login'
import rolesPermit from './rolespermit';
import masterData from './masterData';
import projectDetails from './projectDetails';

const rootReducer = combineReducers({
    Login: Login,
    Roles: rolesPermit,
    masterData,
    projectDetails
})

export default rootReducer