import React from "react";
import { Button, InputLabel, Grid } from "@mui/material";
import "./notification.scss";
import { Dropdown } from "primereact/dropdown";
import { getSupervisor, getUsers, saveNotification } from "../api";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { MultiSelect } from "primereact/multiselect";

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};
class Notification extends React.Component {
  constructor(props) {
    document.title = "Notification";
    super(props);
    this.state = {
      title: "",
      desc: "",
      userType: {},
      usersList: [],
      selectedUser: [],
    };
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ innerMessage: "", severity: "" });
    }, 20000);
  }

  handleTextChange = (e) => {
    this.setState({ title: e.target.value });
  };

  handleDescChange = (e) => {
    this.setState({ desc: e.target.value });
  };

  handleDropdownChange = (e) => {
    this.setState(
      {
        userType: e,
      },
      () => {
        if (this.state.userType.name === "campboss") {
          getSupervisor({}, headers).then((r) => {
            let data = r?.data?.data;
            this.setState({ usersList: data });
          });
        } else {
          getUsers({}, headers).then((r) => {
            let data = r?.data?.data;
            this.setState({ usersList: data });
          });
        }
      }
    );
  };

  selectUser = (e) => {
    this.setState({ selectedUser: e });
  };

  handleOption = (val) => {
    this.setState({ option: val }, () => {
      if (this.state.option === "Email") {
        let userTypes = [
          { name: "campboss", code: "campboss" },
          { name: "admin", code: "admin" },
        ];
        this.setState({ userTypesList: userTypes });
      } else if (this.state.option === "Push") {
        let userTypes = [{ name: "campboss", code: "campboss" }];
        this.setState({ userTypesList: userTypes });
      }
    });
  };

  handleSubmit = () => {
    const { title, desc, option, userType, selectedUser } = this.state;
    if (title && desc && option && userType && selectedUser) {
      let selectedId;
      if (userType?.name === "campboss") {
        selectedId = selectedUser.map((x) => {
          return x.supervisor_id;
        });
      } else if (userType?.name === "admin") {
        selectedId = selectedUser.map((x) => {
          return x.admin_id;
        });
      }
      let payload = {
        receiver_type: userType?.name,
        receiver_id: selectedId,
        subject: title,
        content: desc,
        option: option,
      };
      saveNotification(payload, headers)
        .then((r) => {
          let data = r?.data?.message;
          let critical = r?.data?.error ? "error" : "success";
          this.setState({
            innerMessage: data,
            severity: critical,
            title: "",
            desc: "",
            userType: {},
            selectedUser: [],
            option: "",
          });
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({ innerMessage: "Enter all Details!", severity: "error" });
    }
  };

  render() {
    const {
      title,
      desc,
      userType,
      userTypesList,
      usersList,
      selectedUser,
      option,
      innerMessage,
      severity,
    } = this.state;
    return (
      <div className="notification">
        {innerMessage ? (
          <Message
            severity={severity}
            text={innerMessage}
            style={{ marginBottom: 20, width: "100%" }}
          />
        ) : null}
        <Grid container>
          <Grid item xs={4} spacing={1}>
            <div className="leftGrid">
              <h4>In App Notification</h4>
              <div className="appText">
                Select broadcast message or single message if needs to be sent
                to users. Broadcast message sends the message to everyone
                including the admin and catering manager.
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="rightGrid">
              <div className="options">
                <InputLabel id="option">Options</InputLabel>
                <div className="radioBtns">
                  <div className="emailOption">
                    <RadioButton
                      inputId="option1"
                      name="email"
                      value="Email"
                      onChange={(e) => this.handleOption(e.value)}
                      checked={option === "Email"}
                    />
                    <label htmlFor="option1" className="ml-2">
                      Email
                    </label>
                  </div>
                  <div className="pushOption">
                    <RadioButton
                      inputId="option2"
                      name="push"
                      value="Push"
                      onChange={(e) => this.handleOption(e.value)}
                      checked={option === "Push"}
                    />
                    <label htmlFor="option2" className="ml-2">
                      Push Notification
                    </label>
                  </div>
                </div>
              </div>
              <div className="selectUser">
                <InputLabel id="demo-simple-select-label">
                  Select User Type
                </InputLabel>
                <Dropdown
                  value={userType}
                  onChange={(e) => this.handleDropdownChange(e.value)}
                  options={userTypesList}
                  optionLabel="name"
                  placeholder="Select User Type"
                  filter
                  className="w-full md:w-14rem"
                />
              </div>
              <div className="userSelection">
                <InputLabel id="demo-simple-select-label">
                  Select User
                </InputLabel>
                <MultiSelect
                  value={selectedUser}
                  onChange={(e) => this.selectUser(e.value)}
                  options={usersList}
                  optionLabel={
                    userType.name === "campboss"
                      ? "supervisor_name"
                      : "admin_name"
                  }
                  filter
                  placeholder="Select User"
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                />
              </div>
              <div className="title">
                <InputLabel id="demo-simple-select-label">Title</InputLabel>
                <InputText
                  id="title"
                  placeholder="Type here..."
                  value={title}
                  onChange={this.handleTextChange}
                />
              </div>
              <div className="desc">
                <InputLabel id="demo-simple-select-label">
                  Description
                </InputLabel>
                <InputTextarea
                  id="description"
                  placeholder="Type here..."
                  value={desc}
                  onChange={this.handleDescChange}
                  rows={5}
                  cols={30}
                />
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  className="addBtn"
                  onClick={this.handleSubmit}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Notification;
