import React from "react";

const terms = () => {
  return (
    <div style={{ margin: "0% 10%" }}>
      <h2>Terms and Conditions for Using the Waste Management App – ISRAF</h2>
      <p style={{ lineHeight: "2pc" }}>
        <b>1. Introduction:</b><br/>
        These Terms and Conditions govern the use of the Waste Management
        Catering App (“ISRAF”) provided by Al Gosaibi Service ("the
        Company/AGS"). By downloading, accessing, or using the App, you agree to
        be bound by these Terms and Conditions.
        <br/>
        <b>2. Use of the App:</b> <br/>
        The App is intended for use by customers of the
        Company to access catering services, for measuring the waste each day
        and also for survey by the staff and the customers. Users must comply
        with all applicable laws and regulations while using the App.
        <br />
        <b>3. Account Registration:</b> <br/>
        AGS Users may be required to create an account
        from the backend system to access certain features of the App. For
        External users there is no login required and will have access only to
        the survey page opened by the AGS admin. Account information must be
        accurate, current, and complete. Users are responsible for maintaining
        the confidentiality of their account credentials and for all activities
        that occur under their account.
        <br />
        <b>4. Catering Services:</b><br/>The App provides access to users to log in to
        the app and collect the data of the waste. Also, customers/users without
        login in will be allowed to take survey for the catering service
        provided. <br />
        <b>5. No Payment:</b><br/>
        No payments are registered in the app, also no payments are collected
        thru the application. <br />
        <b>6. Data Privacy: </b><br/>
        The Company may collect and use personal information in accordance with
        its Privacy Policy as attached. By using the App, you consent to the
        collection, use, and disclosure of your personal information as
        described in the Privacy Policy. AGS staff/Users while access the app,
        needs to allow camera/images access, Location access, notification
        access. These are needed to get accurate data for the project.
        Camera/image access is needed to pick or take photos showcasing the
        weight of the wastage. Location access is needed to understand from
        where or which project the waste data has been collected and added.
        Notification is needed for the senior/Ops Managers / Admin to provide
        notification on time to time basis of the work assigned. <br />
        <b>7. Intellectual Property:</b><br/>
        The App and its content, including but not limited to text, graphics,
        logos, and images, are the property of the Company and are protected by
        intellectual property laws. Users may not reproduce, modify, distribute,
        or publicly display any content from the App without the Company's prior
        written consent. <br />
        <b>8. Limitation of Liability:</b><br/>
        The Company shall not be liable for any direct, indirect, incidental,
        special, or consequential damages arising out of or in connection with
        the use of the App or the catering services provided by the Company.{" "}
        <br />
        <b>9. Termination:</b><br/>
        The Company may terminate or suspend access to the App at any time, with
        or without cause, and without prior notice. Upon termination, all rights
        and licenses granted to you will terminate, and you must cease all use
        of the App without any liability on AGS in this regard. <br />
        <b>10. Governing Law:</b><br/>
        These Terms and Conditions shall be governed by and construed in
        accordance with the laws of Saudi Arabia. Any disputes arising out of or
        relating to these Terms and Conditions shall be subject to the exclusive
        jurisdiction of the courts of Saudi Arabia. <br />
        <b>11. Amendments:</b><br/>
        The Company reserves the right to amend these Terms and Conditions at
        any time. Any amendments will be effective immediately upon posting on
        the App. Continued use of the App after any such changes constitutes
        acceptance of the amended Terms and Conditions. <br />
        <b>12. Contact Us:</b><br/>
        If you have any questions or concerns about these Terms and Conditions,
        please contact us at Office: +966 13 847 2444,
        <a href="mailto:balmarzouqi@algosaibiservices.com"> balmarzouqi@algosaibiservices.com</a>.
        <br />
      </p>
    </div>
  );
};

export default terms;
