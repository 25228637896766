export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const LOGIN_LOADING = "LOGIN_LOADING"

export const LOGOUT_LOADING = "LOGOUT_LOADING"
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const MENU_LIST = "MENU_LIST"
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS"
export const MENU_LIST_ACTIVE = "MENU_LIST_ACTIVE"

export const PROJ_DETAILS = "PROJ_DETAILS"
export const PROJ_AREA_DETAILS = "PROJ_AREA_DETAILS"
export const PROJ_BIN_DETAILS = "PROJ_BIN_DETAILS"
export const PROJ_TIME_DETAILS = "PROJ_TIME_DETAILS"

export const PROJ_WASTE_DETAILS = "PROJ_WASTE_DETAILS"
export const PROJ_SURVEY_DETAILS = "PROJ_SURVEY_DETAILS"

export const ALL_WASTE_RECORDS = "ALL_WASTE_RECORDS"