import { PROJ_DETAILS, PROJ_AREA_DETAILS, PROJ_BIN_DETAILS, PROJ_TIME_DETAILS, PROJ_WASTE_DETAILS, PROJ_SURVEY_DETAILS, ALL_WASTE_RECORDS } from "../constants";

const initialState = {
    projDetails: []
};


const projectDetails = (state = initialState, action) => {
    switch (action.type) {
        case PROJ_DETAILS:
            return { ...state, projDetails: action.payload };
        case PROJ_AREA_DETAILS: 
            return { ...state, areaDetails: action.payload };
        case PROJ_BIN_DETAILS: 
            return { ...state, binDetails: action.payload };
        case PROJ_TIME_DETAILS:
            return { ...state, timeslotDetails: action.payload}
        case PROJ_WASTE_DETAILS:
            return { ...state, wasteRecord: action.payload }
        case ALL_WASTE_RECORDS:
            return { ...state, allWasteRecords: action.payload }
        case PROJ_SURVEY_DETAILS:
            return { ...state, surveryRecord: action.payload }
        default:
            return state;
    }
};

export default projectDetails;