import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/loginActions";
import { Link } from "react-router-dom";
import "./auth.scss";

const Logout = (props) => {
  document.title = "Logout";
  const { logoutLoading } = props.loginDetails;

  useEffect(() => {
    const userData = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : "";
    if (userData.jwt) {
      let payload = {
        userName: userData.user_name,
        token: userData.jwt,
      };
      props.logoutUser(payload);
    } else {
      console.log("JWT is not there");
    }
  }, []);
  return (
    <React.Fragment>
      {logoutLoading ? (
        <div className="logOut">
          <h5>
            You have successfully logged out !!
            <Link to="/login">Click here to login again</Link>
          </h5>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (data) => dispatch(logoutUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
