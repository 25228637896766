import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import LOGO from "../../assets/images/LOGO.png";
import eyeIcon from "../../assets/images/icon-view.png";
import { loginUser } from "../../redux/actions/loginActions";
import withRouter from "../../components/Common/withRouter";
import "./auth.scss";

const Login = (props) => {
  document.title = "Login";
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isValidEmail, setValidEmail] = useState(false);
  const [pwd, setPwd] = useState("");
  const [type, setType] = useState(false);
  const [dirtyPwd, setDirtyPwd] = useState(false);
  const [dirty, setDirty] = useState(false);
  const loginError = props.loginDetails?.loginError;

  const handleEmailChange = (event) => {
    let value = event.target.value;
    setEmail(value);
    !value && setValidEmail(true);
  };

  const handlePwdChange = (event) => {
    let value = event.target.value;
    setPwd(value);
  };

  const handleSubmitForm = () => {
    let data = {
      userName: email,
      password: pwd,
    };
    props.addLoginData(data, navigate);
  };

  return (
    <div className="loginPage">
      <div className="topSection">
        <img src={LOGO} className="logoIcon" />
        <h2>Log in with your work email</h2>
        <p>Use your work email to log in to your team workspace.</p>
      </div>
      <div className="formSection">
        <>
          <p>Username</p>
          <TextField
            error={dirty && isValidEmail}
            onBlur={() => setDirty(true)}
            id="outlined-basic email"
            variant="outlined"
            type="text"
            placeholder="yourname"
            onChange={handleEmailChange}
            value={email}
          />
        </>
        <div className="pwdSection">
          <p>
            Password
            <a href="#" className="fgtPwd">
              Forgot your Password ?
            </a>
          </p>
          <TextField
            error={dirtyPwd && !pwd}
            onBlur={() => setDirtyPwd(true)}
            id="outlined-basic password"
            variant="outlined"
            type={type ? "text" : "password"}
            placeholder="Enter your password"
            onChange={handlePwdChange}
            value={pwd}
          />
          <img
            src={eyeIcon}
            className={type ? "eyeIconRed" : "eyeIcon"}
            style={pwd ? { cursor: "pointer" } : {}}
            disabled={!pwd}
            onClick={() => {
              setType(!type);
            }}
          />
        </div>

        {dirtyPwd && !pwd ? (
          <span className="pwdError">
            Password should contain atleast 8 characters
          </span>
        ) : null}
        <div className="btn">
          {props.loading ? (
            <LoadingButton
              loading={props.loading}
              loadingPosition="center"
              variant="contained"
              className="loginBtn"
              style={{ backgroundColor: "#F3C300" }}
            >
              Logging In
            </LoadingButton>
          ) : email && pwd ? (
            <Button
              variant="contained"
              className="loginBtn"
              onClick={handleSubmitForm}
              style={{ backgroundColor: "#F3C300" }}
            >
              Log in
            </Button>
          ) : (
            <Button variant="contained" className="loginBtn" disabled>
              Log in
            </Button>
          )}
        </div>
        {loginError ? <div className="errorMsg">{loginError}</div> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLoginData: (data, navigate) => dispatch(loginUser(data, navigate)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
