import React from "react";
import { connect } from "react-redux";
import Table from "../../components/Common/Table";
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Modal,
  Alert,
} from "@mui/material";
import { Close, Delete, Edit, Info } from "@mui/icons-material";
import "./Projects.scss";
import {
  loadProjectCode,
  loadRegion,
  loadLocation,
  loadProjectType,
  loadSupervisor,
  loadManager,
  getAllProjects,
  createNewProject,
  editNewProject,
} from "../../redux/actions/masterData";
import { Link } from "react-router-dom";
import { deleteProject } from "../api";

class Projects extends React.Component {
  constructor(props) {
    document.title = "Projects";
    super(props);
    this.state = {
      showVal: 10,
      searchVal: "",
      showAddModal: false,
      rowData: [],
      columnData: [],
      showAddModal: false,
      showEditModal: false,
      projCode: "",
      editedProjDetails: {},
      projType: "",
      region: "",
      location: "",
      division: "",
      serviceArea: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.masterData?.allProjects !== this.props.masterData?.allProjects
    ) {
      if (!this.props.masterData?.allProjects?.error) {
        const rowData = this.props.masterData?.allProjects.map((proj) => {
          return {
            id: proj.project_id,
            ...proj,
          };
        });
        this.setState({
          rowData,
        });
      }
    }
    setTimeout(() => {
      this.setState({ showModalAlert: false });
    }, 100000);
  }

  componentDidMount() {
    this.props.getAllProjects();
    this.props.loadProjectCode();
    this.props.loadSupervisor();
    this.props.loadRegion();
    this.props.loadLocation();
    this.props.loadProjectType();
    this.props.loadManager();

    const columns = [
      { field: "project_id", headerName: "UID", width: 90, hide: true },
      {
        field: "project_code",
        headerName: "PROJECT CODE",
        width: 150,
        renderCell: (params) => {
          const link = `projectDetails/${params.row.id}`;
          return (
            <Link className="projCodeLink" to={link} target="_blank">
              {params.row.project_code}
            </Link>
          );
        },
      },
      {
        field: "project_name",
        headerName: "PROJECT NAME",
        width: 150,
        editable: true,
      },
      {
        field: "project_type",
        headerName: "PROJECT TYPE",
        width: 150,
        editable: true,
      },
      {
        field: "manager_userid",
        headerName: "MANAGER USERID",
        width: 150,
        editable: true,
      },
      {
        field: "manager_email",
        headerName: "MANAGER EMAIL",
        width: 200,
        editable: true,
      },
      {
        field: "supervisor_userid",
        headerName: "CATERING MANAGER USERID",
        width: 250,
        editable: true,
      },
      {
        field: "supervisor_email",
        headerName: "CATERING MANAGER EMAIL",
        width: 250,
        editable: true,
      },
      {
        field: "region",
        headerName: "REGION",
        width: 100,
        editable: true,
      },
      {
        field: "location",
        headerName: "LOCATION",
        width: 130,
        editable: true,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 150,
        editable: true,
        renderCell: (params) => {
          const handleEditClick = (e) => {
            let {
              project_id,
              project_name,
              project_type,
              region,
              supervisor_userid,
              manager_userid,
              location,
              location_id,
              manager_email,
              manager_id,
              project_code,
              region_id,
              supervisor_email,
              supervisor_id,
              geolocation,
            } = params.row;

            this.setState({
              showEditModal: true,
              editedProjDetails: params.row,
              projCode: project_code,
              projType: project_type,
              region: parseInt(region_id),
              location: parseInt(location_id),
              manager: parseInt(manager_id),
              supervisor: parseInt(supervisor_id),
              projName: project_name,
              project_id,
            });
          };
          const handleDeleteClick = (e) => {
            this.setState({
              showDeleteModal: true,
              removeId: params.row.project_id,
            });
          };
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton
                  onClick={handleEditClick}
                  color="success"
                  className="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={handleDeleteClick}
                  className="delete"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ];
    this.setState({ columnData: columns });
  }

  handleDropdownChange = (e) => {
    this.setState({
      showVal: e.target.value,
    });
  };
  filterSearchData = (searchVal) => {
    if (searchVal) {
      const searchRegex = new RegExp(`.*${searchVal}.*`, "ig");
      const filteredRows = this.state.rowData.filter((o) => {
        return Object.keys(o).some((k) => {
          return searchRegex.test(o[k]?.toString());
        });
      });
      this.setState({
        filteredRows: filteredRows,
      });
    } else {
      this.setState({ filteredRows: this.state.rowData });
    }
  };
  handleSearch = (e) => {
    this.setState(
      {
        searchVal: e.target.value,
      },
      () => this.filterSearchData(this.state.searchVal)
    );
  };
  addProject = () => {
    this.setState({
      showAddModal: true,
    });
  };
  handleClose = (type) => {
    if (type === "edit") this.setState({ showEditModal: false });
    else if (type === "add") this.setState({ showAddModal: false });
    else if (type === "delete") this.setState({ showDeleteModal: false });
  };
  // handleTextChange = (e) => {
  //   this.setState({ projName: e.target.value });
  // };
  handleModalDropdownChange = (e, type) => {
    if (type === "code") {
      let projObj = this.props.masterData?.projectCode?.find(
        (project) => project.project_code === e.target.value
      );
      this.setState({
        projCode: e.target.value,
        projName: projObj.project_name,
      });
    } else if (type === "type") this.setState({ projType: e.target.value });
    else if (type === "region") this.setState({ region: e.target.value });
    else if (type === "location") this.setState({ location: e.target.value });
    else if (type === "manager") this.setState({ manager: e.target.value });
    else if (type === "supervisor")
      this.setState({ supervisor: e.target.value });
  };

  handleSave = async (type) => {
    const manager = this.props.masterData?.manager?.find(
      (manager) => manager?.manager_id === this.state.manager
    );
    const supervisor = this.props.masterData?.supervisor?.find(
      (supervisor) => supervisor?.supervisor_id === this.state.supervisor
    );
    const region = this.props.masterData?.region?.find(
      (region) => region?.region_id === this.state.region
    );
    const location = this.props.masterData?.location?.find(
      (location) => location?.location_id === this.state.location
    );

    if (type === "save") {
      const payload = {
        project_code: this.state.projCode,
        project_name: this.state.projName,
        manager_id: manager?.manager_id,
        manager_userId: manager?.manager_id,
        manager_email: manager.email,
        supervisor_id: supervisor?.supervisor_id,
        supervisor_userId: supervisor?.supervisor_userid,
        supervisor_name: supervisor.supervisor_name,
        supervisor_email: supervisor.email,
        region_id: region?.region_id,
        region: region.region,
        location_id: location?.location_id,
        location: location.location_name,
        lat: location.lat,
        lang: location.lang,
        project_type: this.state.projType,
      };

      await this.props.createNewProject(payload);
      if (this.props.masterData?.createProjectAlert?.error) {
        this.setState({
          showModalAlert: true,
          alertMsg: this.props.masterData.createProjectAlert.message,
          severity: "warning",
        });
      } else {
        this.setState(
          {
            showAddModal: false,
          },
          () => this.props.getAllProjects()
        );
      }
    } else if (type === "edit") {
      const payload = {
        project_code: this.state.projCode,
        project_name: this.state.projName,
        manager_id: manager?.manager_id,
        manager_userid: manager?.manager_id,
        manager_email: manager?.email,
        supervisor_id: supervisor?.supervisor_id,
        supervisor_userid: supervisor?.supervisor_userid,
        supervisor_name: supervisor?.supervisor_name,
        supervisor_email: supervisor?.email,
        region_id: region?.region_id,
        region: region?.region,
        location_id: location?.location_id,
        location: location?.location_name,
        lat: location?.lat,
        lang: location?.lang,
        project_type: this.state.projType,
        project_id: this.state.project_id,
      };

      await this.props.editNewProject(payload);
      if (this.props.masterData?.createProjectAlert?.error) {
        this.setState({
          showModalAlert: true,
          alertMsg: this.props.masterData.createProjectAlert.message,
          severity: "warning",
        });
      } else {
        this.setState(
          {
            showEditModal: false,
          },
          () => this.props.getAllProjects()
        );
      }
    }
  };

  deleteProjectData = () => {
    const { removeId } = this.state;
    const jwt = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).jwt
      : "";
    const headers = {
      auth: jwt,
    };
    deleteProject(
      {
        project_id: removeId,
      },
      headers
    )
      .then((res) => {
        const data = res?.data;
        this.handleClose("delete");
        this.setState(
          {
            showAlert: true,
            alertMsg: data.message,
            severity: "error",
          },
          () => this.props.getAllProjects()
        );
      })
      .catch((e) => console.log(e));
  };

  render() {
    const {
      showVal,
      searchVal,
      rowData,
      columnData,
      showAddModal,
      showEditModal,
      projCode,
      projType,
      region,
      location,
      manager,
      supervisor,
      projName,
      showModalAlert,
      alertMsg,
      severity,
      filteredRows,
      showDeleteModal,
    } = this.state;
    return (
      <div className="projects">
        <h3>Projects List</h3>
        <div className="topSection">
          <div className="leftside">
            <div className="dropdowns">
              <InputLabel id="demo-simple-select-label">Show by</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showVal}
                onChange={this.handleDropdownChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </div>
            <div className="search">
              <InputLabel id="demo-simple-select-label">Search</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Project Code"
                value={searchVal}
                onChange={this.handleSearch}
              />
            </div>
            <div className="rightSide">
              <Button
                variant="contained"
                className="addBtn"
                onClick={this.addProject}
              >
                ADD
              </Button>
            </div>
          </div>
          {/* <div className="rightSide">
            <Button
              variant="contained"
              className="addBtn"
              onClick={this.addProject}
            >
              ADD
            </Button>
          </div> */}
        </div>
        <Table
          columns={columnData}
          rows={searchVal ? filteredRows : rowData}
          pageSize={showVal}
        />

        {/* Add Modal */}
        {showAddModal ? (
          <Modal
            open={showAddModal}
            onClose={() => this.handleClose("add")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-project"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("add")}
              />
              {showModalAlert ? (
                <Alert
                  variant="outlined"
                  severity={severity}
                  onClose={() => this.setState({ showModalAlert: false })}
                >
                  {alertMsg}
                </Alert>
              ) : null}
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project code
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="modal-select-project-code"
                  onChange={(e) => this.handleModalDropdownChange(e, "code")}
                >
                  {this.props.masterData?.projectCode?.map((project) => (
                    <MenuItem value={project.project_code}>
                      {project.project_code}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project name
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={projName}
                  // placeholder="Type project code here ....."
                  // onChange={(e) => this.handleTextChange(e)}
                  disabled
                />
              </div>
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="modal-select-project-type"
                  onChange={(e) => this.handleModalDropdownChange(e, "type")}
                >
                  {this.props.masterData?.projectType?.map((project) => (
                    <MenuItem value={project.project_type}>
                      {project.project_type}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="role-add-two-column-flex">
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">Manager</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-manager"
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "manager")
                    }
                  >
                    {this.props.masterData?.manager?.map((manager) => (
                      <MenuItem value={manager.manager_id}>
                        {manager.manager_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">
                    Supervisor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-supervisor"
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "supervisor")
                    }
                  >
                    {this.props.masterData?.supervisor
                      ?.filter(
                        (supervisor) =>
                          supervisor.manager_id?.toString() ===
                          this.state.manager?.toString()
                      )
                      .map((project) => (
                        <MenuItem value={project.supervisor_id}>
                          {project.supervisor_name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="role-add-two-column-flex">
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">Region</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-region"
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "region")
                    }
                  >
                    {this.props.masterData?.region?.map((region) => (
                      <MenuItem value={region.region_id}>
                        {region.region}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">
                    Location
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-location"
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "location")
                    }
                  >
                    {this.props.masterData?.location?.map((location) => (
                      <MenuItem value={location.location_id}>
                        {location.location_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  color="error"
                  className="submitBtn"
                  onClick={() => this.handleSave("save")}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Edit Modal */}
        {showEditModal ? (
          <Modal
            open={showEditModal}
            onClose={() => this.handleClose("edit")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-modal-project"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("edit")}
              />
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project code
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="modal-select-project-code"
                  value={projCode}
                  onChange={(e) => this.handleModalDropdownChange(e, "code")}
                >
                  {this.props.masterData?.projectCode?.map((project) => (
                    <MenuItem value={project.project_code}>
                      {project.project_code}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project name
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={projName}
                  // placeholder="Type project code here ....."
                  // onChange={(e) => this.handleTextChange(e)}
                  disabled
                />
              </div>
              <div className="role-add">
                <InputLabel id="demo-simple-select-label">
                  Project type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="modal-select-project-type"
                  value={projType}
                  onChange={(e) => this.handleModalDropdownChange(e, "type")}
                >
                  {this.props.masterData?.projectType?.map((project) => (
                    <MenuItem value={projType}>{project.project_type}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="role-add-two-column-flex">
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">Manager</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-manager"
                    value={manager}
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "manager")
                    }
                  >
                    {this.props.masterData?.manager?.map((manager_) => (
                      <MenuItem value={manager_.manager_id}>
                        {manager_.manager_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">
                    Supervisor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-supervisor"
                    value={supervisor}
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "supervisor")
                    }
                  >
                    {this.props.masterData?.supervisor
                      ?.filter(
                        (supervisor) =>
                          supervisor.manager_id?.toString() ===
                          this.state.manager?.toString()
                      )
                      .map((project) => (
                        <MenuItem value={project.supervisor_id}>
                          {project.supervisor_name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="role-add-two-column-flex">
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">Region</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-region"
                    value={region}
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "region")
                    }
                  >
                    {this.props.masterData?.region?.map((region_) => (
                      <MenuItem value={region_.region_id}>
                        {region_.region}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="dropdowns">
                  <InputLabel id="demo-simple-select-label">
                    Location
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="modal-select-location"
                    value={location}
                    onChange={(e) =>
                      this.handleModalDropdownChange(e, "location")
                    }
                  >
                    {this.props.masterData?.location?.map((location_) => (
                      <MenuItem value={location_.location_id}>
                        {location_.location_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="submit">
                <Button
                  variant="contained"
                  color="error"
                  className="submitBtn"
                  onClick={() => this.handleSave("edit")}
                >
                  UPDATE
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}

        {/* Delete Modal */}
        {showDeleteModal ? (
          <Modal
            open={showDeleteModal}
            onClose={() => this.handleClose("delete")}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="block-modal-master"
          >
            <Box className="modal-Box">
              <Close
                className="closeIcon"
                onClick={() => this.handleClose("delete")}
              />
              <Info className="infoIcon" />
              <h4>Are you Sure</h4>
              <p>Wants to Delete ?</p>
              <div className="action-buttons">
                <Button
                  variant="contained"
                  className=""
                  onClick={() => this.setState({ showDeleteModal: false })}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className=""
                  onClick={this.deleteProjectData}
                >
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    masterData: store.masterData,
  };
};

const mapDispatchToProps = {
  loadProjectCode,
  loadRegion,
  loadLocation,
  loadProjectType,
  loadSupervisor,
  loadManager,
  getAllProjects,
  createNewProject,
  editNewProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
