import React from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { getLMDashboardGraph, getSurveyRecWithFilter } from "../api";
import _ from "lodash";
import FilterDashboard from "./filters";
import greenHappy from "../../assets/images/Green-Happy.png";
import yellowHappy from "../../assets/images/yellow-happy.png";
import yellowNeutral from "../../assets/images/yellow-neutral.png";
import orangeSad from "../../assets/images/Orange-Sad.png";
import redSad from "../../assets/images/Red-Sad.png";

// Get the current date
var currentDate = new Date();

// Get the month before the current date
var monthBefore = new Date(currentDate);
monthBefore.setMonth(currentDate.getMonth() - 1);

// Function to format date as DD-MM-YYYY
function formatDate(date) {
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 because months are zero-based
  var year = date.getFullYear();

  // Format the result as DD-MM-YYYY
  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
}

const jwt = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser")).jwt
  : "";

const headers = {
  auth: jwt,
};

class DashboardSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: {},
      startDate: formatDate(monthBefore),
      endDate: formatDate(currentDate),
    };
  }

  collectData = (
    regionId = "",
    projectId = "",
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    getSurveyRecWithFilter(
      {
        fromDate: startDate,
        toDate: endDate,
        projectId,
        regionId,
      },
      headers
    )
      .then((r) => {
        let value = r?.data?.data || [];
        // Group by category
        const groupedData = _.groupBy(value, "rating");

        // Map values to the count of each group
        const countByCategory = _.mapValues(groupedData, (group) =>
          _.size(group)
        );

        this.setState(
          {
            dataSet: countByCategory,
          },
          () => {
            console.log(this.state.dataSet);
          }
        );
      })
      .catch((e) =>
        console.log("LAST MONTH WASTE COLLECTION ERROR >>>>>>>>", e)
      );
  };

  componentDidMount = () => {
    this.collectData();
  };
  render() {
    return (
      <div className="dashboard-waste">
        <h2> Survey Snapshot</h2>
        <div className="filter-sec">
          <FilterDashboard getFilterData={this.collectData} />
        </div>
        <div style={{ display: "flex" }}>
          <div className="card card-ex">
            <Card title="Excellent">
              <p className="displayP">
                <img src={greenHappy} />
                <h2>{this.state.dataSet?.excellent || 0}</h2>
              </p>
            </Card>
          </div>
          <div className="card card-ex">
            <Card title="Good">
              <p className="displayP">
                <img src={yellowHappy} />
                <h2>{this.state.dataSet?.good || 0}</h2>
              </p>
            </Card>
          </div>
          <div className="card card-ex">
            <Card title="Fair">
              <p className="displayP">
                <img src={yellowNeutral} />
                <h2>{this.state.dataSet?.neutral || 0}</h2>
              </p>
            </Card>
          </div>
          <div className="card card-ex">
            <Card title="Not Satisfactory">
              <p className="displayP">
                <img src={orangeSad} />
                <h2>{this.state.dataSet?.bad || 0}</h2>
              </p>
            </Card>
          </div>
          <div className="card card-ex">
            <Card title="Unacceptable">
              <p className="displayP">
                <img src={redSad} />
                <h2>{this.state.dataSet?.worst || 0}</h2>
              </p>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(DashboardSurvey);
