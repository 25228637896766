import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { setMenuActiveKey } from "../../../redux/actions/roleMenu";
import { Link } from "react-router-dom";

const SidebarContent = (props) => {
  const { menuList, menuListActive } = props.roles;
  const [open, setOpen] = useState(true);
  const [id, setId] = useState(0);
  // const [selectedMenu, setMenu] = useState(menuListActive);
  // console.log("MenuListactive >>>>>>>", menuListActive);

  const handleClick = (key) => {
    if (id === key) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
    setId(key);
  };

  const getSelectedMenu = (key) => {
    // setMenu(key);
    props.setMenuActiveKey(key);
  };

  return (
    <React.Fragment>
      <List>
        {menuList?.length
          ? menuList.map((Item, index) => (
              <>
                {Item && Item.subMenu && Item.subMenu.length ? (
                  <ListItem
                    key={Item.key}
                    disablePadding
                    style={{ display: Item.display }}
                  >
                    <ListItemButton onClick={() => handleClick(Item.key)}>
                      <ListItemIcon>{Item.icon}</ListItemIcon>
                      <ListItemText primary={Item.name} />
                      {open && id === Item.key ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem
                    key={Item.key}
                    disablePadding
                    style={{ display: Item.display }}
                  >
                    <ListItemButton
                      // component={Link}
                      href={Item.href}
                      // selected={menuListActive === Item.key}
                      onClick={() => getSelectedMenu(Item.key)}
                    >
                      <ListItemIcon> {Item.icon} </ListItemIcon>
                      <ListItemText primary={Item.name} />
                    </ListItemButton>
                  </ListItem>
                )}
                <Collapse
                  in={open && id === Item.key}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {Item.subMenu.length &&
                      Item.subMenu.map((sub, index) => (
                        <ListItemButton
                          // component={Link}
                          href={sub.href}
                          style={{ display: sub.display }}
                          // selected={menuListActive === sub.key}
                          onClick={() => getSelectedMenu(sub.key)}
                        >
                          <ListItemText primary={sub.name} />
                          <KeyboardArrowRight />
                        </ListItemButton>
                      ))}
                  </List>
                </Collapse>
              </>
            ))
          : null}
      </List>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    roles: state.Roles,
  };
};

const mapDispatchToProps = {
  setMenuActiveKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
